import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22222 1V3.4M3.77778 1V3.4M1 5.8H11M2.11111 2.2H9.88889C10.5025 2.2 11 2.73726 11 3.4V11.8C11 12.4627 10.5025 13 9.88889 13H2.11111C1.49746 13 1 12.4627 1 11.8V3.4C1 2.73726 1.49746 2.2 2.11111 2.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66667 1V3.8M4.33333 1V3.8M1 6.6H13M2.33333 2.4H11.6667C12.403 2.4 13 3.0268 13 3.8V13.6C13 14.3732 12.403 15 11.6667 15H2.33333C1.59695 15 1 14.3732 1 13.6V3.8C1 3.0268 1.59695 2.4 2.33333 2.4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1111 1V4.2M4.88889 1V4.2M1 7.4H15M2.55556 2.6H13.4444C14.3036 2.6 15 3.31634 15 4.2V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V4.2C1 3.31634 1.69645 2.6 2.55556 2.6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4444 1V5.4M6.55556 1V5.4M1 9.8H21M3.22222 3.2H18.7778C20.0051 3.2 21 4.18497 21 5.4V20.8C21 22.015 20.0051 23 18.7778 23H3.22222C1.99492 23 1 22.015 1 20.8V5.4C1 4.18497 1.99492 3.2 3.22222 3.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 1V5.8M7.33333 1V5.8M1.5 10.6H22.5M3.83333 3.4H20.1667C21.4553 3.4 22.5 4.47452 22.5 5.8V22.6C22.5 23.9255 21.4553 25 20.1667 25H3.83333C2.54467 25 1.5 23.9255 1.5 22.6V5.8C1.5 4.47452 2.54467 3.4 3.83333 3.4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 1V7M8.5 1V7M1 13H28M4 4H25C26.6569 4 28 5.34315 28 7V28C28 29.6569 26.6569 31 25 31H4C2.34315 31 1 29.6569 1 28V7C1 5.34315 2.34315 4 4 4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
