import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943M13 2.20343L7 8.20943L5.2 6.40943"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.36V8.004C14.9991 9.5135 14.5103 10.9823 13.6065 12.1913C12.7027 13.4003 11.4323 14.2847 9.98475 14.7127C8.5372 15.1407 6.99008 15.0893 5.57413 14.5662C4.15818 14.0431 2.94926 13.0763 2.12767 11.8099C1.30609 10.5436 0.915854 9.04565 1.01517 7.53942C1.11449 6.0332 1.69804 4.59943 2.6788 3.45196C3.65955 2.30448 4.98495 1.50477 6.45733 1.17211C7.92971 0.839444 9.47018 0.991643 10.849 1.60601M15 2.40401L8 9.411L5.9 7.311"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.26858V9.00458C16.999 10.7297 16.4404 12.4083 15.4075 13.79C14.3745 15.1718 12.9226 16.1826 11.2683 16.6717C9.61394 17.1608 7.84581 17.1021 6.22757 16.5042C4.60934 15.9064 3.22772 14.8015 2.28877 13.3542C1.34981 11.907 0.903833 10.195 1.01734 8.47363C1.13085 6.75223 1.79777 5.11364 2.91862 3.80224C4.03948 2.49083 5.55423 1.57688 7.23695 1.1967C8.91967 0.816507 10.6802 0.990449 12.256 1.69258M17 2.60458L9 10.6126L6.6 8.21258"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 10.9943V12.0063C22.9986 14.3784 22.2305 16.6864 20.8103 18.5863C19.39 20.4862 17.3936 21.876 15.1189 22.5486C12.8442 23.2211 10.413 23.1404 8.18791 22.3183C5.96285 21.4963 4.06312 19.977 2.77206 17.9871C1.48099 15.9971 0.86777 13.6432 1.02384 11.2762C1.17992 8.90931 2.09693 6.65625 3.63811 4.85307C5.17929 3.0499 7.26206 1.79322 9.5758 1.27046C11.8895 0.747697 14.3103 0.986867 16.477 1.9523M23 3.2063L12 14.2173L8.7 10.9173"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 11.9029V13.0069C24.9985 15.5946 24.1606 18.1125 22.6112 20.1851C21.0618 22.2576 18.8839 23.7738 16.4024 24.5075C13.9209 25.2412 11.2687 25.1531 8.84136 24.2564C6.41402 23.3596 4.34158 21.7022 2.93315 19.5313C1.52472 17.3605 0.855749 14.7925 1.02601 12.2104C1.19627 9.62834 2.19665 7.17046 3.87794 5.20335C5.55922 3.23625 7.83134 1.86533 10.3554 1.29504C12.8795 0.72476 15.5203 0.985673 17.884 2.03887M25 3.40687L13 15.4189L9.4 11.8189"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 14.6286V16.0086C30.9981 19.2432 29.9507 22.3906 28.014 24.9813C26.0772 27.572 23.3549 29.4673 20.253 30.3844C17.1511 31.3016 13.8359 31.1914 10.8017 30.0705C7.76752 28.9495 5.17698 26.8777 3.41644 24.1642C1.6559 21.4506 0.819687 18.2407 1.03252 15.0131C1.24534 11.7854 2.49581 8.71307 4.59742 6.25419C6.69903 3.79531 9.53918 2.08166 12.6943 1.3688C15.8494 0.65595 19.1504 0.982091 22.105 2.29859M31 4.00859L16 19.0236L11.5 14.5236"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
