import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666667 0C0.298477 0 0 0.291988 0 0.652174C0 1.01236 0.298477 1.30435 0.666667 1.30435H11.3333C11.7015 1.30435 12 1.01236 12 0.652174C12 0.291988 11.7015 0 11.3333 0H0.666667Z"
        fill={color}
      />
      <path
        d="M0.666667 8.69565C0.298477 8.69565 0 8.98764 0 9.34783C0 9.70801 0.298477 10 0.666667 10H11.3333C11.7015 10 12 9.70801 12 9.34783C12 8.98764 11.7015 8.69565 11.3333 8.69565H0.666667Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.777778 0C0.348223 0 0 0.350386 0 0.782609C0 1.21483 0.348223 1.56522 0.777778 1.56522H13.2222C13.6518 1.56522 14 1.21483 14 0.782609C14 0.350386 13.6518 0 13.2222 0H0.777778Z"
        fill={color}
      />
      <path
        d="M0.777778 10.4348C0.348223 10.4348 0 10.7852 0 11.2174C0 11.6496 0.348223 12 0.777778 12H13.2222C13.6518 12 14 11.6496 14 11.2174C14 10.7852 13.6518 10.4348 13.2222 10.4348H0.777778Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.615385 0C0.275517 0 0 0.255503 0 0.570681C0 0.88586 0.275517 1.14136 0.615385 1.14136H15.3846C15.7245 1.14136 16 0.88586 16 0.570681C16 0.255503 15.7245 0 15.3846 0H0.615385Z"
        fill={color}
      />
      <path
        d="M0.615385 12.8586C0.275517 12.8586 0 13.1141 0 13.4293C0 13.7445 0.275517 14 0.615385 14H15.3846C15.7245 14 16 13.7445 16 13.4293C16 13.1141 15.7245 12.8586 15.3846 12.8586H0.615385Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.846154 0C0.378836 0 0 0.383254 0 0.856022C0 1.32879 0.378836 1.71204 0.846154 1.71204H21.1538C21.6212 1.71204 22 1.32879 22 0.856022C22 0.383254 21.6212 0 21.1538 0H0.846154Z"
        fill={color}
      />
      <path
        d="M0.846154 19.288C0.378836 19.288 0 19.6712 0 20.144C0 20.6167 0.378836 21 0.846154 21H21.1538C21.6212 21 22 20.6167 22 20.144C22 19.6712 21.6212 19.288 21.1538 19.288H0.846154Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0.998169C0.447715 0.998169 0 1.44588 0 1.99817C0 2.55045 0.447715 2.99817 1 2.99817H23.0027C23.555 2.99817 24.0027 2.55045 24.0027 1.99817C24.0027 1.44588 23.555 0.998169 23.0027 0.998169H1ZM1 19.0019C0.447715 19.0019 0 19.4496 0 20.0019C0 20.5542 0.447715 21.0019 1 21.0019H23.0027C23.555 21.0019 24.0027 20.5542 24.0027 20.0019C24.0027 19.4496 23.555 19.0019 23.0027 19.0019H1Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H28.9986C29.5509 2 29.9986 1.55228 29.9986 1C29.9986 0.447715 29.5509 0 28.9986 0H1ZM1 23.0005C0.447715 23.0005 0 23.4482 0 24.0005C0 24.5528 0.447715 25.0005 1 25.0005H28.9986C29.5509 25.0005 29.9986 24.5528 29.9986 24.0005C29.9986 23.4482 29.5509 23.0005 28.9986 23.0005H1Z"
        fill={color}
      />
    </svg>
  )
};
