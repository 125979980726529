import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.63636 11V7.11111M2.63636 4.88889V1M7 11V6M7 3.77778V1M11.3636 11V8.22222M11.3636 6V1M1 7.11111H4.27273M5.36364 3.77778H8.63636M9.72727 8.22222H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.90909 13V8.33333M2.90909 5.66667V1M8 13V7M8 4.33333V1M13.0909 13V9.66667M13.0909 7V1M1 8.33333H4.81818M6.09091 4.33333H9.90909M11.1818 9.66667H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.18182 14.5V9.44444M3.18182 6.55556V1.5M9 14.5V8M9 5.11111V1.5M14.8182 14.5V10.8889M14.8182 8V1.5M1 9.44444H5.36364M6.81818 5.11111H11.1818M12.6364 10.8889H17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 19V12M4 8V1M12 19V10M12 6V1M20 19V14M20 10V1M1 12H7M9 6H15M17 14H23"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.27273 21V13.2222M4.27273 8.77778V1M13 21V11M13 6.55556V1M21.7273 21V15.4444M21.7273 11V1M1 13.2222H7.54545M9.72727 6.55556H16.2727M18.4545 15.4444H25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.09091 25V15.6667M5.09091 10.3333V1M16 25V13M16 7.66667V1M26.9091 25V18.3333M26.9091 13V1M1 15.6667H9.18182M11.9091 7.66667H20.0909M22.8182 18.3333H31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
