import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6C1 6 3.18182 1.5 7 1.5C10.8182 1.5 13 6 13 6C13 6 10.8182 10.5 7 10.5C3.18182 10.5 1 6 1 6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.6875C7.90374 7.6875 8.63636 6.93198 8.63636 6C8.63636 5.06802 7.90374 4.3125 7 4.3125C6.09626 4.3125 5.36364 5.06802 5.36364 6C5.36364 6.93198 6.09626 7.6875 7 7.6875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6C1 6 3.54545 1 8 1C12.4545 1 15 6 15 6C15 6 12.4545 11 8 11C3.54545 11 1 6 1 6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.875C9.05436 7.875 9.90909 7.03553 9.90909 6C9.90909 4.96447 9.05436 4.125 8 4.125C6.94564 4.125 6.09091 4.96447 6.09091 6C6.09091 7.03553 6.94564 7.875 8 7.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 7 3.90909 1 9 1C14.0909 1 17 7 17 7C17 7 14.0909 13 9 13C3.90909 13 1 7 1 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.25C10.205 9.25 11.1818 8.24264 11.1818 7C11.1818 5.75736 10.205 4.75 9 4.75C7.79502 4.75 6.81818 5.75736 6.81818 7C6.81818 8.24264 7.79502 9.25 9 9.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10C1 10 5.36364 1 13 1C20.6364 1 25 10 25 10C25 10 20.6364 19 13 19C5.36364 19 1 10 1 10Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.375C14.8075 13.375 16.2727 11.864 16.2727 10C16.2727 8.13604 14.8075 6.625 13 6.625C11.1925 6.625 9.72727 8.13604 9.72727 10C9.72727 11.864 11.1925 13.375 13 13.375Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12C1 12 6.45455 1 16 1C25.5455 1 31 12 31 12C31 12 25.5455 23 16 23C6.45455 23 1 12 1 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16.125C18.2593 16.125 20.0909 14.2782 20.0909 12C20.0909 9.72183 18.2593 7.875 16 7.875C13.7407 7.875 11.9091 9.72183 11.9091 12C11.9091 14.2782 13.7407 16.125 16 16.125Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
