import React from 'react';
import { concatClassNames } from '../../concatClassNames';
import { ActiveEffectButton } from '../../NiagaraButton/ActiveEffectButton';
import './SortableHeader.scss';

export type SortOption = 'asc' | 'desc';

interface ISortableHeader {
  headerCellName: string;
  isActive: boolean;
  order: SortOption;
  onSort: () => void;
}

/**
 * Returns a consistently styled cell for sortable header cells in NiagaraDataTable
 *
 * @remarks
 * Does not actually trigger sorting. It is expected that this is handled externally.
 * e.g. by a useEffect triggering on the change of the activeSortBy or order fields
 */
export const SortableHeader: React.FC<ISortableHeader> = ({
  headerCellName,
  isActive,
  order,
  onSort
}) => {
  const icon = !isActive || order === 'asc' ? 'ArrowDown' : 'ArrowUp';
  return (
    <div className="sortable-header">
      {headerCellName}
      <ActiveEffectButton
        className={isActive ? 'active' : ''}
        icon={icon}
        iconColor="#606060"
        onClick={onSort}
      />
    </div>
  );
};
