import React from 'react';

export default {
  16: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="3"
        height="10"
        rx="1"
        transform="matrix(-1 0 0 1 3 0)"
        fill={color}
      />
      <rect
        width="3"
        height="10"
        rx="1"
        transform="matrix(-1 0 0 1 9 0)"
        fill={color}
      />
      <rect
        x="-0.25"
        y="0.25"
        width="2.5"
        height="9.5"
        rx="0.75"
        transform="matrix(-1 0 0 1 14.5 0)"
        stroke="#707070"
        strokeWidth="0.5"
      />
    </svg>
  ),
};
