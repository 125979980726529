import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6548 11.3572C11.5082 11.2106 9.02327 8.72567 9.02327 8.72567L5.26399 4.96639L3.38435 3.08675L0.640017 0.109959C0.493405 -0.036653 0.256571 -0.036653 0.109959 0.109959C-0.036653 0.256571 -0.036653 0.493405 0.109959 0.640017L2.21146 2.97773H1.23781C0.617524 2.97773 0.110021 3.48524 0.110021 4.10552V6.36108C0.110021 6.98136 0.617524 7.48887 1.23781 7.48887H4.87679L8.53832 10.0527C8.60599 10.0978 8.68118 10.1204 8.75636 10.1204C8.81651 10.1204 8.87666 10.1053 8.92929 10.079C9.01199 10.0339 9.07214 9.96247 9.10598 9.87601L11.1209 11.891C11.1209 11.891 11.2901 12 11.3879 12C11.4856 12 11.5796 11.9624 11.6548 11.891C11.8014 11.7444 11.8014 11.5038 11.6548 11.3572ZM0.861877 6.36108V4.10552C0.861877 3.89876 1.03104 3.72959 1.23781 3.72959H2.96331L4.62116 5.38743V6.73701H1.23781C1.03104 6.73701 0.861877 6.56784 0.861877 6.36108ZM5.37301 6.91746V6.13929L7.9594 8.72567L5.37301 6.91746Z"
        fill={color}
      />
      <path
        d="M8.48951 7.1318C8.56094 7.20323 8.65868 7.24082 8.75642 7.24082C8.80529 7.24082 8.85416 7.2333 8.89927 7.21075C9.03837 7.15436 9.13235 7.01527 9.13235 6.86489V0.722231C9.13235 0.583138 9.0534 0.455322 8.92935 0.387655C8.80529 0.323747 8.65492 0.331266 8.53838 0.41397L4.92571 2.94397C4.83549 3.00787 4.77534 3.10937 4.76782 3.21839C4.75654 3.32741 4.7979 3.43643 4.87684 3.51538L8.48951 7.1318ZM8.38049 1.44401V5.95891L5.72644 3.30486L8.38049 1.44401Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5972 13.25C13.4262 13.079 10.5272 10.1799 10.5272 10.1799L6.14132 5.79412L3.94841 3.60121L0.746687 0.128285C0.57564 -0.0427618 0.299333 -0.0427618 0.128285 0.128285C-0.0427618 0.299333 -0.0427618 0.57564 0.128285 0.746687L2.58003 3.47402H1.44411C0.720444 3.47402 0.128357 4.06611 0.128357 4.78977V7.42126C0.128357 8.14493 0.720444 8.73701 1.44411 8.73701H5.68958L9.96138 11.7281C10.0403 11.7808 10.128 11.8071 10.2158 11.8071C10.2859 11.8071 10.3561 11.7895 10.4175 11.7588C10.514 11.7062 10.5842 11.6229 10.6236 11.522L12.9744 13.8728C12.9744 13.8728 13.1718 14 13.2858 14C13.3999 14 13.5095 13.9561 13.5972 13.8728C13.7683 13.7018 13.7683 13.4211 13.5972 13.25ZM1.00552 7.42126V4.78977C1.00552 4.54855 1.20289 4.35119 1.44411 4.35119H3.4572L5.39135 6.28534V7.85985H1.44411C1.20289 7.85985 1.00552 7.66248 1.00552 7.42126ZM6.26851 8.07037V7.1625L9.28596 10.1799L6.26851 8.07037Z"
        fill={color}
      />
      <path
        d="M9.90441 8.32044C9.98774 8.40377 10.1018 8.44763 10.2158 8.44763C10.2728 8.44763 10.3298 8.43886 10.3825 8.41254C10.5447 8.34675 10.6544 8.18448 10.6544 8.00905V0.842604C10.6544 0.680329 10.5623 0.53121 10.4175 0.452265C10.2728 0.377706 10.0974 0.386478 9.96142 0.482967L5.74664 3.43463C5.64138 3.50919 5.57121 3.6276 5.56244 3.75479C5.54928 3.88198 5.59753 4.00917 5.68963 4.10127L9.90441 8.32044ZM9.77722 1.68468V6.95206L6.68083 3.85567L9.77722 1.68468Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5397 15.1429C15.3442 14.9474 12.031 11.6342 12.031 11.6342L7.01866 6.62186L4.51247 4.11567L0.853357 0.146612C0.657874 -0.0488706 0.342094 -0.0488706 0.146612 0.146612C-0.0488706 0.342095 -0.0488706 0.657874 0.146612 0.853357L2.94861 3.97031H1.65041C0.823365 3.97031 0.146694 4.64698 0.146694 5.47402V8.48145C0.146694 9.30849 0.823365 9.98516 1.65041 9.98516H6.50238L11.3844 13.4036C11.4747 13.4637 11.5749 13.4938 11.6752 13.4938C11.7553 13.4938 11.8355 13.4738 11.9057 13.4387C12.016 13.3785 12.0962 13.2833 12.1413 13.168L14.8279 15.8546C14.8279 15.8546 15.0535 16 15.1838 16C15.3141 16 15.4394 15.9499 15.5397 15.8546C15.7352 15.6592 15.7352 15.3384 15.5397 15.1429ZM1.14917 8.48145V5.47402C1.14917 5.19834 1.37473 4.97279 1.65041 4.97279H3.95109L6.16154 7.18324V8.98268H1.65041C1.37473 8.98268 1.14917 8.75713 1.14917 8.48145ZM7.16402 9.22328V8.18571L10.6125 11.6342L7.16402 9.22328Z"
        fill={color}
      />
      <path
        d="M11.3193 9.50907C11.4146 9.60431 11.5449 9.65443 11.6752 9.65443C11.7404 9.65443 11.8055 9.64441 11.8657 9.61434C12.0511 9.53915 12.1765 9.35369 12.1765 9.1532V0.962977C12.1765 0.777519 12.0712 0.607099 11.9058 0.516876C11.7404 0.431666 11.5399 0.441691 11.3845 0.551963L6.56761 3.92529C6.44731 4.0105 6.36711 4.14583 6.35709 4.29119C6.34205 4.43655 6.39719 4.58191 6.50245 4.68717L11.3193 9.50907ZM11.174 1.92535V7.94521L7.63524 4.40648L11.174 1.92535Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3671 20.8215C21.0983 20.5527 16.5427 15.9971 16.5427 15.9971L9.65065 9.10505L6.20465 5.65905L1.17337 0.201591C0.904577 -0.0671971 0.47038 -0.0671971 0.201591 0.201591C-0.0671971 0.47038 -0.0671971 0.904577 0.201591 1.17337L4.05434 5.45918H2.26931C1.13213 5.45918 0.201704 6.3896 0.201704 7.52678V11.662C0.201704 12.7992 1.13213 13.7296 2.26931 13.7296H8.94078L15.6536 18.4299C15.7777 18.5126 15.9155 18.554 16.0533 18.554C16.1636 18.554 16.2739 18.5264 16.3704 18.4782C16.522 18.3955 16.6323 18.2645 16.6943 18.106L20.3884 21.8001C20.3884 21.8001 20.6985 22 20.8777 22C21.0569 22 21.2292 21.9311 21.3671 21.8001C21.6359 21.5313 21.6359 21.0903 21.3671 20.8215ZM1.58011 11.662V7.52678C1.58011 7.14772 1.89025 6.83758 2.26931 6.83758H5.43274L8.47212 9.87696V12.3512H2.26931C1.89025 12.3512 1.58011 12.041 1.58011 11.662ZM9.85052 12.682V11.2554L14.5922 15.9971L9.85052 12.682Z"
        fill={color}
      />
      <path
        d="M15.5641 13.075C15.695 13.2059 15.8742 13.2748 16.0534 13.2748C16.143 13.2748 16.2326 13.2611 16.3153 13.2197C16.5703 13.1163 16.7426 12.8613 16.7426 12.5856V1.32409C16.7426 1.06908 16.5979 0.834756 16.3705 0.7107C16.143 0.593535 15.8673 0.60732 15.6537 0.758944L9.03046 5.39727C8.86505 5.51443 8.75478 5.70052 8.741 5.90039C8.72032 6.10025 8.79613 6.30012 8.94087 6.44486L15.5641 13.075ZM15.3642 2.64736V10.9247L10.4985 6.0589L15.3642 2.64736Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3095 22.7143C23.0163 22.4211 18.0465 17.4513 18.0465 17.4513L10.528 9.93278L6.76871 6.17351L1.28003 0.219918C0.986811 -0.073306 0.513142 -0.073306 0.219918 0.219918C-0.073306 0.513142 -0.073306 0.986811 0.219918 1.28003L4.42292 5.95547H2.47561C1.23505 5.95547 0.220041 6.97047 0.220041 8.21103V12.7222C0.220041 13.9627 1.23505 14.9777 2.47561 14.9777H9.75357L17.0766 20.1054C17.212 20.1956 17.3624 20.2407 17.5127 20.2407C17.633 20.2407 17.7533 20.2107 17.8586 20.158C18.024 20.0678 18.1443 19.9249 18.212 19.752L22.2419 23.782C22.2419 23.782 22.5802 24 22.7757 24C22.9712 24 23.1592 23.9248 23.3095 23.782C23.6028 23.4887 23.6028 23.0075 23.3095 22.7143ZM1.72375 12.7222V8.21103C1.72375 7.79751 2.06209 7.45918 2.47561 7.45918H5.92663L9.24231 10.7749V13.474H2.47561C2.06209 13.474 1.72375 13.1357 1.72375 12.7222ZM10.746 13.8349V12.2786L15.9188 17.4513L10.746 13.8349Z"
        fill={color}
      />
      <path
        d="M16.979 14.2636C17.1219 14.4065 17.3174 14.4816 17.5128 14.4816C17.6106 14.4816 17.7083 14.4666 17.7985 14.4215C18.0767 14.3087 18.2647 14.0305 18.2647 13.7298V1.44447C18.2647 1.16628 18.1068 0.910648 17.8587 0.775314C17.6106 0.647498 17.3098 0.662536 17.0768 0.827944L9.85142 5.88794C9.67098 6.01575 9.55068 6.21875 9.53565 6.43679C9.51309 6.65483 9.59579 6.87287 9.75368 7.03076L16.979 14.2636ZM16.761 2.88803V11.9178L11.4529 6.60972L16.761 2.88803Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0794 30.2858C30.6884 29.8948 24.0621 23.2685 24.0621 23.2685L14.0373 13.2437L9.02494 8.23134L1.70671 0.293224C1.31575 -0.0977413 0.684189 -0.0977413 0.293224 0.293224C-0.0977413 0.684189 -0.0977413 1.31575 0.293224 1.70671L5.89722 7.94062H3.30081C1.64673 7.94062 0.293388 9.29396 0.293388 10.948V16.9629C0.293388 18.617 1.64673 19.9703 3.30081 19.9703H13.0048L22.7689 26.8072C22.9493 26.9275 23.1498 26.9876 23.3503 26.9876C23.5107 26.9876 23.6711 26.9475 23.8114 26.8774C24.032 26.7571 24.1924 26.5666 24.2826 26.336L29.6559 31.7093C29.6559 31.7093 30.107 32 30.3676 32C30.6283 32 30.8789 31.8998 31.0794 31.7093C31.4703 31.3183 31.4703 30.6767 31.0794 30.2858ZM2.29834 16.9629V10.948C2.29834 10.3967 2.74945 9.94557 3.30081 9.94557H7.90217L12.3231 14.3665V17.9654H3.30081C2.74945 17.9654 2.29834 17.5143 2.29834 16.9629ZM14.328 18.4466V16.3714L21.2251 23.2685L14.328 18.4466Z"
        fill={color}
      />
      <path
        d="M22.6387 19.0181C22.8291 19.2086 23.0898 19.3089 23.3504 19.3089C23.4808 19.3089 23.6111 19.2888 23.7314 19.2287C24.1023 19.0783 24.3529 18.7074 24.3529 18.3064V1.92595C24.3529 1.55504 24.1424 1.2142 23.8116 1.03375C23.4808 0.863331 23.0798 0.883381 22.769 1.10393L13.1352 7.85058C12.8946 8.021 12.7342 8.29167 12.7142 8.58239C12.6841 8.8731 12.7944 9.16382 13.0049 9.37434L22.6387 19.0181ZM22.348 3.85071V15.8904L15.2705 8.81296L22.348 3.85071Z"
        fill={color}
      />
    </svg>
  )
};
