import React from 'react';

export default {
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.41434 3.35708L5.25585 3.35708C5.48555 1.64646 6.76551 0.333007 8.30509 0.333007C9.87109 0.333007 11.1679 1.69656 11.3609 3.47642C12.6997 3.88077 13.6667 5.29625 13.6667 6.93899C13.6667 8.91097 12.2846 10.5153 10.5762 10.5153C10.3132 10.5153 10.1049 10.268 10.1049 9.9625C10.1049 9.65699 10.3228 9.40973 10.5857 9.40973C11.7594 9.40973 12.7143 8.30147 12.7143 6.93902C12.7143 5.69579 11.9129 4.64157 10.8501 4.4861C10.612 4.45155 10.4334 4.18861 10.4334 3.91014C10.4334 2.54757 9.47872 1.43884 8.30504 1.43884C7.1285 1.43884 6.17144 2.5476 6.17144 3.91014C6.17144 4.21565 5.95843 4.46292 5.69525 4.46292L3.41434 4.46292C2.24055 4.46279 1.28583 5.57377 1.28583 6.93905C1.28583 8.30162 2.24055 9.40976 3.41423 9.40976L3.42401 9.40976C3.68719 9.40976 3.89508 9.65702 3.89508 9.96253C3.89508 10.268 3.6775 10.5153 3.41421 10.5153C1.71556 10.5153 0.333413 8.9109 0.333414 6.93902C0.333414 4.96373 1.71565 3.35708 3.41434 3.35708Z"
        fill={color}
      />
      <path
        d="M7.00025 7.36438C7.26322 7.36438 7.47644 7.61165 7.47644 7.91715L7.47644 11.7509L8.51279 10.5148C8.69667 10.2961 8.99779 10.2907 9.18615 10.504C9.37471 10.7173 9.37885 11.067 9.19539 11.2857L7.34155 13.4968C7.34145 13.4969 7.34134 13.4969 7.34123 13.497C7.25471 13.6007 7.13449 13.6662 7.00026 13.6662C6.86602 13.6662 6.74579 13.6007 6.65917 13.4969C6.65906 13.4968 6.65896 13.4968 6.65885 13.4967L4.80523 11.2856C4.62198 11.0669 4.62623 10.7171 4.81447 10.5039C5.00325 10.2901 5.30459 10.2966 5.48783 10.5147L6.52407 11.7507L6.52407 7.91703C6.52407 7.61152 6.73729 7.36426 7.00026 7.36426L7.00025 7.36438Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.62139 4.53612L7.38366 4.53612C7.7282 1.97018 9.64814 -9.04989e-07 11.9575 -7.03097e-07C14.3065 -4.9774e-07 16.2518 2.04532 16.5412 4.71512C18.5494 5.32164 20 7.44486 20 9.90897C20 12.8669 17.9268 15.2734 15.3641 15.2734C14.9697 15.2734 14.6572 14.9025 14.6572 14.4442C14.6572 13.986 14.984 13.6151 15.3785 13.6151C17.139 13.6151 18.5714 11.9527 18.5714 9.90902C18.5714 8.04417 17.3692 6.46285 15.775 6.22964C15.4179 6.17781 15.15 5.78341 15.15 5.3657C15.15 3.32185 13.718 1.65874 11.9574 1.65874C10.1926 1.65874 8.75704 3.3219 8.75704 5.3657C8.75704 5.82396 8.43753 6.19486 8.04276 6.19486L4.62139 6.19486C2.8607 6.19468 1.42863 7.86114 1.42863 9.90907C1.42863 11.9529 2.8607 13.6151 4.62122 13.6151L4.63589 13.6151C5.03066 13.6151 5.3425 13.986 5.3425 14.4443C5.3425 14.9025 5.01613 15.2734 4.6212 15.2734C2.07321 15.2734 -1.12486e-06 12.8668 -8.66274e-07 9.90902C-6.07246e-07 6.94608 2.07336 4.53612 4.62139 4.53612Z"
        fill={color}
      />
      <path
        d="M10 10.5471C10.3945 10.5471 10.7143 10.918 10.7143 11.3762L10.7143 17.1268L12.2688 15.2727C12.5446 14.9447 12.9963 14.9365 13.2789 15.2566C13.5617 15.5764 13.5679 16.1011 13.2927 16.429L10.512 19.7457C10.5118 19.7459 10.5116 19.7459 10.5115 19.746C10.3817 19.9015 10.2014 19.9998 10 19.9998C9.79866 19.9998 9.61832 19.9015 9.48839 19.7459C9.48823 19.7457 9.48807 19.7457 9.48791 19.7455L6.70748 16.4288C6.4326 16.1009 6.43898 15.5762 6.72135 15.2564C7.00451 14.9356 7.45653 14.9454 7.73138 15.2725L9.28574 17.1266L9.28574 11.376C9.28574 10.9178 9.60557 10.5469 10 10.5469L10 10.5471Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82844 6.71515L10.5115 6.71515C10.9709 3.29389 13.5308 0.666991 16.6099 0.666991C19.7419 0.666992 22.3356 3.39409 22.7215 6.95382C25.3992 7.76251 27.3333 10.5935 27.3333 13.879C27.3333 17.8229 24.569 21.0315 21.1521 21.0315C20.6261 21.0315 20.2095 20.537 20.2095 19.926C20.2095 19.315 20.6453 18.8204 21.1712 18.8204C23.5186 18.8204 25.4284 16.6039 25.4284 13.879C25.4284 11.3926 23.8256 9.28412 21.6999 8.97318C21.2237 8.90408 20.8666 8.3782 20.8666 7.82126C20.8666 5.09612 18.9572 2.87865 16.6098 2.87865C14.2568 2.87865 12.3426 5.09619 12.3426 7.82126C12.3426 8.43227 11.9166 8.92681 11.3903 8.92681L6.82844 8.92681C4.48086 8.92656 2.57142 11.1485 2.57142 13.8791C2.57142 16.6042 4.48085 18.8205 6.82822 18.8205L6.84777 18.8205C7.37413 18.8205 7.78992 19.315 7.78992 19.926C7.78992 20.5371 7.35476 21.0316 6.82818 21.0316C3.43087 21.0316 0.666583 17.8228 0.666583 13.879C0.666583 9.92843 3.43106 6.71515 6.82844 6.71515Z"
        fill={color}
      />
      <path
        d="M14 14.7297C14.526 14.7297 14.9524 15.2243 14.9524 15.8353L14.9524 23.5027L17.0251 21.0305C17.3929 20.5932 17.9951 20.5824 18.3718 21.0091C18.7489 21.4355 18.7572 22.1351 18.3903 22.5724L14.6826 26.9945C14.6824 26.9948 14.6822 26.9948 14.682 26.995C14.5089 27.2023 14.2685 27.3334 14 27.3334C13.7315 27.3334 13.4911 27.2023 13.3178 26.9948C13.3176 26.9945 13.3174 26.9945 13.3172 26.9943L9.60997 22.5721C9.24347 22.1348 9.25198 21.4352 9.62846 21.0088C10.006 20.5812 10.6087 20.5942 10.9752 21.0303L13.0477 23.5025L13.0477 15.835C13.0477 15.224 13.4741 14.7295 14 14.7295L14 14.7297Z"
        fill={color}
      />
    </svg>
  )
};
