import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 6.16669C10.75 9.08335 7 11.5834 7 11.5834C7 11.5834 3.25 9.08335 3.25 6.16669C3.25 5.17213 3.64509 4.2183 4.34835 3.51504C5.05161 2.81178 6.00544 2.41669 7 2.41669C7.99456 2.41669 8.94839 2.81178 9.65165 3.51504C10.3549 4.2183 10.75 5.17213 10.75 6.16669Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.41669C7.69036 7.41669 8.25 6.85704 8.25 6.16669C8.25 5.47633 7.69036 4.91669 7 4.91669C6.30964 4.91669 5.75 5.47633 5.75 6.16669C5.75 6.85704 6.30964 7.41669 7 7.41669Z"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7C12.5 10.5 8 13.5 8 13.5C8 13.5 3.5 10.5 3.5 7C3.5 5.80653 3.97411 4.66193 4.81802 3.81802C5.66193 2.97411 6.80653 2.5 8 2.5C9.19347 2.5 10.3381 2.97411 11.182 3.81802C12.0259 4.66193 12.5 5.80653 12.5 7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.5C8.82843 8.5 9.5 7.82843 9.5 7C9.5 6.17157 8.82843 5.5 8 5.5C7.17157 5.5 6.5 6.17157 6.5 7C6.5 7.82843 7.17157 8.5 8 8.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 7.83337C14.25 11.9167 9 15.4167 9 15.4167C9 15.4167 3.75 11.9167 3.75 7.83337C3.75 6.44099 4.30312 5.10563 5.28769 4.12106C6.27226 3.1365 7.60761 2.58337 9 2.58337C10.3924 2.58337 11.7277 3.1365 12.7123 4.12106C13.6969 5.10563 14.25 6.44099 14.25 7.83337Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.58337C9.9665 9.58337 10.75 8.79987 10.75 7.83337C10.75 6.86688 9.9665 6.08337 9 6.08337C8.0335 6.08337 7.25 6.86688 7.25 7.83337C7.25 8.79987 8.0335 9.58337 9 9.58337Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  20: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.66663C16 13.3333 10 17.3333 10 17.3333C10 17.3333 4 13.3333 4 8.66663C4 7.07533 4.63214 5.5492 5.75736 4.42399C6.88258 3.29877 8.4087 2.66663 10 2.66663C11.5913 2.66663 13.1174 3.29877 14.2426 4.42399C15.3679 5.5492 16 7.07533 16 8.66663Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.6666C11.1046 10.6666 12 9.7712 12 8.66663C12 7.56206 11.1046 6.66663 10 6.66663C8.89543 6.66663 8 7.56206 8 8.66663C8 9.7712 8.89543 10.6666 10 10.6666Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 10.3334C19.5 16.1667 12 21.1667 12 21.1667C12 21.1667 4.5 16.1667 4.5 10.3334C4.5 8.34425 5.29018 6.4366 6.6967 5.03007C8.10322 3.62355 10.0109 2.83337 12 2.83337C13.9891 2.83337 15.8968 3.62355 17.3033 5.03007C18.7098 6.4366 19.5 8.34425 19.5 10.3334Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.8334C13.3807 12.8334 14.5 11.7141 14.5 10.3334C14.5 8.95266 13.3807 7.83337 12 7.83337C10.6193 7.83337 9.5 8.95266 9.5 10.3334C9.5 11.7141 10.6193 12.8334 12 12.8334Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.25 11.1666C21.25 17.5833 13 23.0833 13 23.0833C13 23.0833 4.75 17.5833 4.75 11.1666C4.75 8.97859 5.61919 6.88017 7.16637 5.333C8.71354 3.78582 10.812 2.91663 13 2.91663C15.188 2.91663 17.2865 3.78582 18.8336 5.333C20.3808 6.88017 21.25 8.97859 21.25 11.1666Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.9166C14.5188 13.9166 15.75 12.6854 15.75 11.1666C15.75 9.64784 14.5188 8.41663 13 8.41663C11.4812 8.41663 10.25 9.64784 10.25 11.1666C10.25 12.6854 11.4812 13.9166 13 13.9166Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  28: ({ color }: { color: string }) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 12C23 19 14 25 14 25C14 25 5 19 5 12C5 9.61305 5.94821 7.32387 7.63604 5.63604C9.32387 3.94821 11.6131 3 14 3C16.3869 3 18.6761 3.94821 20.364 5.63604C22.0518 7.32387 23 9.61305 23 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15C15.6569 15 17 13.6569 17 12C17 10.3431 15.6569 9 14 9C12.3431 9 11 10.3431 11 12C11 13.6569 12.3431 15 14 15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 13.6666C26.5 21.8333 16 28.8333 16 28.8333C16 28.8333 5.5 21.8333 5.5 13.6666C5.5 10.8819 6.60625 8.21114 8.57538 6.242C10.5445 4.27287 13.2152 3.16663 16 3.16663C18.7848 3.16663 21.4555 4.27287 23.4246 6.242C25.3938 8.21114 26.5 10.8819 26.5 13.6666Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17.1666C17.933 17.1666 19.5 15.5996 19.5 13.6666C19.5 11.7336 17.933 10.1666 16 10.1666C14.067 10.1666 12.5 11.7336 12.5 13.6666C12.5 15.5996 14.067 17.1666 16 17.1666Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
