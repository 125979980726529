import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 5.2L5.2 8.8M5.2 5.2L8.8 8.8M4.516 1H9.484L13 4.516V9.484L9.484 13H4.516L1 9.484V4.516L4.516 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1 5.9L5.9 10.1M5.9 5.9L10.1 10.1M5.102 1H10.898L15 5.102V10.898L10.898 15H5.102L1 10.898V5.102L5.102 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4 6.6L6.6 11.4M6.6 6.6L11.4 11.4M5.688 1H12.312L17 5.688V12.312L12.312 17H5.688L1 12.312V5.688L5.688 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 8.7L8.7 15.3M8.7 8.7L15.3 15.3M7.446 1H16.554L23 7.446V16.554L16.554 23H7.446L1 16.554V7.446L7.446 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6 9.4L9.4 16.6M9.4 9.4L16.6 16.6M8.032 1H17.968L25 8.032V17.968L17.968 25H8.032L1 17.968V8.032L8.032 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 11.5L11.5 20.5M11.5 11.5L20.5 20.5M9.79 1H22.21L31 9.79V22.21L22.21 31H9.79L1 22.21V9.79L9.79 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
