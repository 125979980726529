import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3.40429C1.78229 2.60265 2.33946 1.92668 3.07284 1.49611C3.80622 1.06553 4.66848 0.908138 5.5069 1.0518C6.34531 1.19547 7.10578 1.63092 7.65361 2.28103C8.20143 2.93114 8.50126 3.75396 8.5 4.60376C8.5 7.00268 4.89794 8.20215 4.89794 8.20215M4.895 8.20215V10.0013M4.895 13H4.90667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.80501C1.32261 2.86976 1.95939 2.08113 2.79754 1.57879C3.63569 1.07645 4.62112 0.892828 5.57931 1.06044C6.5375 1.22804 7.40661 1.73607 8.03269 2.49454C8.65878 3.253 9.00144 4.21296 9 5.20438C9 8.00313 4.88336 9.4025 4.88336 9.4025M4.88 9.4025V11.5016M4.88 15H4.89333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.20573C1.40327 3.13687 2.19923 2.23558 3.24692 1.66148C4.29461 1.08738 5.5264 0.877517 6.72414 1.06907C7.92188 1.26062 9.00826 1.84122 9.79086 2.70804C10.5735 3.57486 11.0018 4.67195 11 5.80501C11 9.00358 5.8542 10.6029 5.8542 10.6029M5.85 10.6029V13.0018M5.85 17H5.86667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.40787C1.52425 3.9382 2.559 2.69892 3.921 1.90953C5.28299 1.12014 6.88433 0.831586 8.44138 1.09497C9.99844 1.35835 11.4107 2.15668 12.4281 3.34856C13.4455 4.54043 14.0023 6.04894 14 7.60689C14 12.0049 7.31046 14.2039 7.31046 14.2039M7.305 14.2039V17.5025M7.305 23H7.32667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="26"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5.80859C2.1049 4.20531 3.29885 2.85337 4.87038 1.99222C6.44191 1.13106 8.28961 0.816276 10.0862 1.1036C11.8828 1.39093 13.5124 2.26184 14.6863 3.56206C15.8602 4.86229 16.5027 6.50793 16.5 8.20752C16.5 13.0054 8.7813 15.4043 8.7813 15.4043M8.775 15.4043V19.0027M8.775 25H8.8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="32"
      viewBox="0 0 20 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.01074C1.72588 5.00664 3.15862 3.31671 5.04445 2.24027C6.93029 1.16383 9.14753 0.770345 11.3035 1.12951C13.4594 1.48867 15.4149 2.57729 16.8236 4.20258C18.2323 5.82786 19.0032 7.88491 19 10.0094C19 16.0067 9.73756 19.0054 9.73756 19.0054M9.73 19.0054V23.5034M9.73 31H9.76"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
