import React, { ReactNode } from 'react';
import { concatClassNames } from '../concatClassNames';
import { ITooltipElement } from '../NiagaraTooltip/NiagaraTooltip';
import { NiagaraIcon, NiagaraIconName } from '../SvgIcon';
import './NiagaraButton2.scss';

export const NiagaraButtonTypes = [
  'primary',
  'secondary',
  'tertiary',
  'primary-link',
  'secondary-link'
] as const;
export type NiagaraButtonType = typeof NiagaraButtonTypes[number];

type IconPosition = 'left' | 'right';

export interface INiagaraButtonProps extends ITooltipElement {
  className?: string;
  content?: ReactNode;
  disabled?: boolean;
  id?: string;
  type?: NiagaraButtonType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: NiagaraIconName;
  iconSize?: number;
  iconPosition?: IconPosition;
  iconColor?: string;
  buttonColor?: string;
}

export function NiagaraButton2({
  className,
  content,
  disabled = false,
  id,
  type = 'primary',
  onClick,
  icon,
  iconSize = 14,
  iconPosition = 'left',
  iconColor,
  buttonColor,
  ...props
}: INiagaraButtonProps) {
  const getColor = (type: NiagaraButtonType, isDisabled: boolean) => {
    switch (type) {
      case 'secondary':
        return !isDisabled ? '#005eac' : 'white';
      case 'tertiary':
        return !isDisabled ? '#303030' : '#b0b0b0';
      case 'primary-link':
        return !isDisabled ? '#005eac' : '#b0b0b0';
      case 'secondary-link':
        return !isDisabled ? '#303030' : '#b0b0b0';
      case 'primary':
      default:
        return !isDisabled ? 'white' : 'white';
    }
  };
  const niagaraIcon = icon && (
    <NiagaraIcon
      name={icon}
      size={iconSize}
      color={iconColor ?? getColor(type, disabled)}
    />
  );

  const buttonColorStyle = buttonColor
    ? { backgroundColor: buttonColor }
    : undefined;
  const buttonStyle = { ...buttonColorStyle };

  return (
    <button
      {...props}
      id={id}
      style={buttonStyle}
      className={concatClassNames(
        'niagara-button-2',
        type,
        disabled ? 'disabled' : '',
        !content ? 'no-content' : '',
        className
      )}
      onClick={!disabled ? onClick : undefined}
    >
      <>
        {iconPosition === 'left' && niagaraIcon}
        {content}
        {iconPosition === 'right' && niagaraIcon}
      </>
    </button>
  );
}
