import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.66668C13.0023 7.54659 12.7967 8.4146 12.4 9.20001C11.9296 10.1412 11.2065 10.9328 10.3116 11.4862C9.41675 12.0396 8.38548 12.3329 7.33332 12.3333C6.45341 12.3356 5.5854 12.13 4.79999 11.7333L1 13L2.26666 9.20001C1.86995 8.4146 1.66437 7.54659 1.66667 6.66668C1.66707 5.61452 1.96041 4.58325 2.51381 3.68838C3.06721 2.79352 3.85883 2.0704 4.79999 1.60002C5.5854 1.20331 6.45341 0.997725 7.33332 1.00002H7.66666C9.05622 1.07668 10.3687 1.66319 11.3527 2.64726C12.3368 3.63132 12.9233 4.94378 13 6.33334V6.66668Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.61112C15.0027 8.63769 14.7628 9.65036 14.3 10.5667C13.7512 11.6647 12.9076 12.5882 11.8636 13.2339C10.8195 13.8795 9.6164 14.2217 8.38888 14.2222C7.36231 14.2249 6.34964 13.9851 5.43333 13.5222L1 15L2.47778 10.5667C2.01494 9.65036 1.7751 8.63769 1.77778 7.61112C1.77825 6.3836 2.12047 5.18046 2.76611 4.13644C3.41175 3.09243 4.3353 2.24879 5.43333 1.70002C6.34964 1.23719 7.36231 0.997346 8.38888 1.00002H8.77777C10.3989 1.08946 11.9301 1.77372 13.0782 2.9218C14.2263 4.06987 14.9105 5.60108 15 7.22223V7.61112Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.55557C17.003 9.72878 16.7289 10.8861 16.2 11.9333C15.5728 13.1882 14.6086 14.2437 13.4155 14.9816C12.2223 15.7195 10.8473 16.1106 9.44443 16.1111C8.27122 16.1142 7.11387 15.8401 6.06666 15.3111L1 17L2.68889 11.9333C2.15994 10.8861 1.88583 9.72878 1.88889 8.55557C1.88943 7.15269 2.28054 5.77766 3.01841 4.58451C3.75629 3.39135 4.81178 2.42719 6.06666 1.80002C7.11387 1.27107 8.27122 0.996966 9.44443 1.00003H9.88887C11.7416 1.10224 13.4916 1.88426 14.8037 3.19634C16.1157 4.50843 16.8978 6.25837 17 8.11113V8.55557Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 11.3889C23.0042 13.0021 22.6273 14.5934 21.9 16.0333C21.0376 17.7588 19.7119 19.2101 18.0713 20.2247C16.4307 21.2393 14.5401 21.777 12.6111 21.7778C10.9979 21.782 9.40657 21.4051 7.96666 20.6778L1 23L3.32222 16.0333C2.59491 14.5934 2.21801 13.0021 2.22222 11.3889C2.22297 9.45995 2.76075 7.56929 3.77532 5.9287C4.78989 4.28811 6.24119 2.96239 7.96666 2.10003C9.40657 1.37273 10.9979 0.995829 12.6111 1.00003H13.2222C15.7697 1.14058 18.1759 2.21585 19.98 4.01997C21.7841 5.82409 22.8594 8.23027 23 10.7778V11.3889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 12.3334C25.0046 14.0932 24.5934 15.8292 23.8 17.4C22.8592 19.2823 21.413 20.8656 19.6232 21.9724C17.8335 23.0792 15.771 23.6659 13.6666 23.6667C11.9068 23.6713 10.1708 23.2601 8.59999 22.4667L1 25L3.53333 17.4C2.73991 15.8292 2.32874 14.0932 2.33333 12.3334C2.33415 10.229 2.92081 8.16649 4.02762 6.37676C5.13443 4.58703 6.71766 3.14079 8.59999 2.20004C10.1708 1.40661 11.9068 0.99545 13.6666 1.00004H14.3333C17.1124 1.15336 19.7374 2.32638 21.7055 4.29451C23.6736 6.26264 24.8466 8.88756 25 11.6667V12.3334Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 15.1667C31.0057 17.3665 30.4917 19.5365 29.5 21.5C28.324 23.8529 26.5162 25.832 24.279 27.2155C22.0419 28.599 19.4637 29.3323 16.8333 29.3333C14.6335 29.3391 12.4635 28.8251 10.5 27.8333L1 31L4.16666 21.5C3.17488 19.5365 2.66093 17.3665 2.66666 15.1667C2.66768 12.5363 3.40102 9.95812 4.78453 7.72095C6.16804 5.48379 8.14708 3.67599 10.5 2.50005C12.4635 1.50827 14.6335 0.994312 16.8333 1.00005H17.6666C21.1405 1.1917 24.4217 2.65798 26.8819 5.11814C29.342 7.5783 30.8083 10.8595 31 14.3334V15.1667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
