import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666667 0C0.298477 0 0 0.248731 0 0.555556C0 0.86238 0.298477 1.11111 0.666667 1.11111H11.3333C11.7015 1.11111 12 0.86238 12 0.555556C12 0.248731 11.7015 0 11.3333 0H0.666667Z"
        fill={color}
      />
      <path
        d="M0.666667 8.88889C0.298477 8.88889 0 9.13762 0 9.44444C0 9.75127 0.298477 10 0.666667 10H11.3333C11.7015 10 12 9.75127 12 9.44444C12 9.13762 11.7015 8.88889 11.3333 8.88889H0.666667Z"
        fill={color}
      />
      <path
        d="M0 3.51852C0 3.21169 0.298477 2.96296 0.666667 2.96296H11.3333C11.7015 2.96296 12 3.21169 12 3.51852C12 3.82534 11.7015 4.07407 11.3333 4.07407H0.666667C0.298477 4.07407 0 3.82534 0 3.51852Z"
        fill={color}
      />
      <path
        d="M0.666667 5.92593C0.298477 5.92593 0 6.17466 0 6.48148C0 6.78831 0.298477 7.03704 0.666667 7.03704H11.3333C11.7015 7.03704 12 6.78831 12 6.48148C12 6.17466 11.7015 5.92593 11.3333 5.92593H0.666667Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.777778 0C0.348223 0 0 0.298477 0 0.666667C0 1.03486 0.348223 1.33333 0.777778 1.33333H13.2222C13.6518 1.33333 14 1.03486 14 0.666667C14 0.298477 13.6518 0 13.2222 0H0.777778Z"
        fill={color}
      />
      <path
        d="M0.777778 10.6667C0.348223 10.6667 0 10.9651 0 11.3333C0 11.7015 0.348223 12 0.777778 12H13.2222C13.6518 12 14 11.7015 14 11.3333C14 10.9651 13.6518 10.6667 13.2222 10.6667H0.777778Z"
        fill={color}
      />
      <path
        d="M0 4.22222C0 3.85403 0.348223 3.55556 0.777778 3.55556H13.2222C13.6518 3.55556 14 3.85403 14 4.22222C14 4.59041 13.6518 4.88889 13.2222 4.88889H0.777778C0.348223 4.88889 0 4.59041 0 4.22222Z"
        fill={color}
      />
      <path
        d="M0.777778 7.11111C0.348223 7.11111 0 7.40959 0 7.77778C0 8.14597 0.348223 8.44444 0.777778 8.44444H13.2222C13.6518 8.44444 14 8.14597 14 7.77778C14 7.40959 13.6518 7.11111 13.2222 7.11111H0.777778Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.615385 0C0.275517 0 2.44532e-07 0.243272 2.44532e-07 0.543362C2.44532e-07 0.843453 0.275517 1.08672 0.615385 1.08672H15.3846C15.7245 1.08672 16 0.843453 16 0.543362C16 0.243272 15.7245 0 15.3846 0H0.615385Z"
        fill={color}
      />
      <path
        d="M0.615385 12.9133C0.275517 12.9133 2.44532e-07 13.1565 2.44532e-07 13.4566C2.44532e-07 13.7567 0.275517 14 0.615385 14H15.3846C15.7245 14 16 13.7567 16 13.4566C16 13.1565 15.7245 12.9133 15.3846 12.9133H0.615385Z"
        fill={color}
      />
      <path
        d="M0 4.76284C0 4.46275 0.275517 4.21948 0.615385 4.21948H15.3846C15.7245 4.21948 16 4.46275 16 4.76284C16 5.06293 15.7245 5.30621 15.3846 5.30621H0.615385C0.275517 5.30621 0 5.06293 0 4.76284Z"
        fill={color}
      />
      <path
        d="M0.615385 8.56638C0.275517 8.56638 0 8.80965 0 9.10974C0 9.40983 0.275517 9.6531 0.615385 9.6531H15.3846C15.7245 9.6531 16 9.40983 16 9.10974C16 8.80965 15.7245 8.56638 15.3846 8.56638H0.615385Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.846154 0C0.378836 0 3.36231e-07 0.347531 3.36231e-07 0.776232C3.36231e-07 1.20493 0.378836 1.55246 0.846154 1.55246H21.1538C21.6212 1.55246 22 1.20493 22 0.776232C22 0.347531 21.6212 0 21.1538 0H0.846154Z"
        fill={color}
      />
      <path
        d="M0.846154 18.4475C0.378836 18.4475 3.36231e-07 18.7951 3.36231e-07 19.2238C3.36231e-07 19.6525 0.378836 20 0.846154 20H21.1538C21.6212 20 22 19.6525 22 19.2238C22 18.7951 21.6212 18.4475 21.1538 18.4475H0.846154Z"
        fill={color}
      />
      <path
        d="M0 6.80406C0 6.37536 0.378836 6.02783 0.846154 6.02783H21.1538C21.6212 6.02783 22 6.37536 22 6.80406C22 7.23276 21.6212 7.58029 21.1538 7.58029H0.846154C0.378836 7.58029 0 7.23276 0 6.80406Z"
        fill={color}
      />
      <path
        d="M0.846154 12.2377C0.378836 12.2377 0 12.5852 0 13.0139C0 13.4426 0.378836 13.7901 0.846154 13.7901H21.1538C21.6212 13.7901 22 13.4426 22 13.0139C22 12.5852 21.6212 12.2377 21.1538 12.2377H0.846154Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.923083 0.5C0.413282 0.5 6.38228e-06 0.908784 6.38228e-06 1.41304C6.38228e-06 1.9173 0.413282 2.32609 0.923083 2.32609H23.0769C23.5867 2.32609 24 1.9173 24 1.41304C24 0.908784 23.5867 0.5 23.0769 0.5H0.923083Z"
        fill={color}
      />
      <path
        d="M0.923076 6.89131C0.413275 6.89131 0 7.30009 0 7.80435C0 8.30861 0.413275 8.71739 0.923076 8.71739H23.0769C23.5867 8.71739 24 8.30861 24 7.80435C24 7.30009 23.5867 6.89131 23.0769 6.89131H0.923076Z"
        fill={color}
      />
      <path
        d="M0 14.1957C0 13.6914 0.413275 13.2826 0.923076 13.2826H23.0769C23.5867 13.2826 24 13.6914 24 14.1957C24 14.6999 23.5867 15.1087 23.0769 15.1087H0.923076C0.413275 15.1087 0 14.6999 0 14.1957Z"
        fill={color}
      />
      <path
        d="M0.92309 19.6739C0.413289 19.6739 1.4085e-05 20.0827 1.4085e-05 20.587C1.4085e-05 21.0912 0.413289 21.5 0.92309 21.5H23.0769C23.5867 21.5 24 21.0912 24 20.587C24 20.0827 23.5867 19.6739 23.0769 19.6739H0.92309Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15385 0C0.516602 0 7.97785e-06 0.506113 7.97785e-06 1.13043C7.97785e-06 1.75476 0.516602 2.26087 1.15385 2.26087H28.8461C29.4834 2.26087 30 1.75476 30 1.13043C30 0.506113 29.4834 0 28.8461 0H1.15385Z"
        fill={color}
      />
      <path
        d="M1.15385 7.91304C0.516594 7.91304 0 8.41916 0 9.04348C0 9.6678 0.516594 10.1739 1.15385 10.1739H28.8461C29.4834 10.1739 30 9.6678 30 9.04348C30 8.41916 29.4834 7.91304 28.8461 7.91304H1.15385Z"
        fill={color}
      />
      <path
        d="M0 16.9565C0 16.3322 0.516594 15.8261 1.15385 15.8261H28.8461C29.4834 15.8261 30 16.3322 30 16.9565C30 17.5808 29.4834 18.087 28.8461 18.087H1.15385C0.516594 18.087 0 17.5808 0 16.9565Z"
        fill={color}
      />
      <path
        d="M1.15386 23.7391C0.516612 23.7391 1.76063e-05 24.2452 1.76063e-05 24.8696C1.76063e-05 25.4939 0.516612 26 1.15386 26H28.8462C29.4834 26 30 25.4939 30 24.8696C30 24.2452 29.4834 23.7391 28.8462 23.7391H1.15386Z"
        fill={color}
      />
    </svg>
  )
};
