import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9.88889C13 10.1836 12.8851 10.4662 12.6805 10.6746C12.4759 10.8829 12.1984 11 11.9091 11H2.09091C1.80158 11 1.52411 10.8829 1.31952 10.6746C1.11493 10.4662 1 10.1836 1 9.88889V3.77778C1 3.48309 1.11493 3.20048 1.31952 2.9921C1.52411 2.78373 1.80158 2.66667 2.09091 2.66667H4.27273L5.36364 1H8.63636L9.72727 2.66667H11.9091C12.1984 2.66667 12.4759 2.78373 12.6805 2.9921C12.8851 3.20048 13 3.48309 13 3.77778V9.88889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.77778C8.20499 8.77778 9.18182 7.78286 9.18182 6.55556C9.18182 5.32826 8.20499 4.33333 7 4.33333C5.79502 4.33333 4.81818 5.32826 4.81818 6.55556C4.81818 7.78286 5.79502 8.77778 7 8.77778Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 11.6667C15 12.0203 14.8659 12.3594 14.6272 12.6095C14.3885 12.8595 14.0648 13 13.7273 13H2.27273C1.93518 13 1.61146 12.8595 1.37277 12.6095C1.13409 12.3594 1 12.0203 1 11.6667V4.33333C1 3.97971 1.13409 3.64057 1.37277 3.39052C1.61146 3.14048 1.93518 3 2.27273 3H4.81818L6.09091 1H9.90909L11.1818 3H13.7273C14.0648 3 14.3885 3.14048 14.6272 3.39052C14.8659 3.64057 15 3.97971 15 4.33333V11.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.3333C9.40582 10.3333 10.5455 9.13943 10.5455 7.66667C10.5455 6.19391 9.40582 5 8 5C6.59418 5 5.45455 6.19391 5.45455 7.66667C5.45455 9.13943 6.59418 10.3333 8 10.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 13.0556C17 13.4386 16.8468 13.806 16.574 14.0769C16.3012 14.3478 15.9312 14.5 15.5455 14.5H2.45455C2.06878 14.5 1.69881 14.3478 1.42603 14.0769C1.15325 13.806 1 13.4386 1 13.0556V5.11111C1 4.72802 1.15325 4.36062 1.42603 4.08973C1.69881 3.81885 2.06878 3.66667 2.45455 3.66667H5.36364L6.81818 1.5H11.1818L12.6364 3.66667H15.5455C15.9312 3.66667 16.3012 3.81885 16.574 4.08973C16.8468 4.36062 17 4.72802 17 5.11111V13.0556Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.6111C10.6066 11.6111 11.9091 10.3177 11.9091 8.72222C11.9091 7.12673 10.6066 5.83333 9 5.83333C7.39335 5.83333 6.09091 7.12673 6.09091 8.72222C6.09091 10.3177 7.39335 11.6111 9 11.6111Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 17C23 17.5304 22.7893 18.0391 22.4142 18.4142C22.0391 18.7893 21.5304 19 21 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H7L9 1H15L17 4H21C21.5304 4 22.0391 4.21071 22.4142 4.58579C22.7893 4.96086 23 5.46957 23 6V17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 18.7778C25 19.3671 24.7701 19.9324 24.361 20.3491C23.9518 20.7659 23.3968 21 22.8182 21H3.18182C2.60316 21 2.04821 20.7659 1.63904 20.3491C1.22987 19.9324 1 19.3671 1 18.7778V6.55556C1 5.96619 1.22987 5.40095 1.63904 4.98421C2.04821 4.56746 2.60316 4.33333 3.18182 4.33333H7.54545L9.72727 1H16.2727L18.4545 4.33333H22.8182C23.3968 4.33333 23.9518 4.56746 24.361 4.98421C24.7701 5.40095 25 5.96619 25 6.55556V18.7778Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 16.5556C15.41 16.5556 17.3636 14.5657 17.3636 12.1111C17.3636 9.65651 15.41 7.66667 13 7.66667C10.59 7.66667 8.63636 9.65651 8.63636 12.1111C8.63636 14.5657 10.59 16.5556 13 16.5556Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="27"
      viewBox="0 0 32 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 23.2222C31 23.9589 30.7127 24.6655 30.2012 25.1864C29.6897 25.7073 28.996 26 28.2727 26H3.72727C3.00396 26 2.31026 25.7073 1.7988 25.1864C1.28734 24.6655 1 23.9589 1 23.2222V7.94444C1 7.20773 1.28734 6.50119 1.7988 5.98026C2.31026 5.45932 3.00396 5.16667 3.72727 5.16667H9.18182L11.9091 1H20.0909L22.8182 5.16667H28.2727C28.996 5.16667 29.6897 5.45932 30.2012 5.98026C30.7127 6.50119 31 7.20773 31 7.94444V23.2222Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20.4444C19.0125 20.4444 21.4545 17.9571 21.4545 14.8889C21.4545 11.8206 19.0125 9.33333 16 9.33333C12.9875 9.33333 10.5455 11.8206 10.5455 14.8889C10.5455 17.9571 12.9875 20.4444 16 20.4444Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
