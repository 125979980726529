import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.10993V5.77659M13 5.77659H9.39928M13 5.77659L10.2214 3.11215C9.39364 2.26865 8.31905 1.72198 7.1596 1.55451C6.00015 1.38704 4.81865 1.60785 3.79316 2.18365C2.76766 2.75945 1.95372 3.65906 1.474 4.74691C0.994269 5.83476 0.874743 7.05191 1.13343 8.21495C1.39212 9.378 2.015 10.4239 2.90822 11.1951C3.80144 11.9663 4.9166 12.421 6.08566 12.4906C7.25472 12.5603 8.41433 12.2411 9.38977 11.5812C10.3652 10.9214 11.1036 9.95653 11.4937 8.83214"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.72082V6.05415M15 6.05415H10.7992M15 6.05415L11.7583 2.90526C10.7926 1.90841 9.53889 1.26234 8.1862 1.06443C6.83351 0.866506 5.4551 1.12745 4.25868 1.80795C3.06227 2.48844 2.11268 3.55162 1.553 4.83726C0.993314 6.1229 0.853867 7.56135 1.15567 8.93585C1.45747 10.3104 2.18417 11.5464 3.22626 12.4579C4.26835 13.3693 5.56937 13.9066 6.93327 13.9889C8.29717 14.0712 9.65006 13.694 10.7881 12.9142C11.9261 12.1343 12.7875 10.9941 13.2426 9.66526"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1.77627V6.44293M17 6.44293H12.199M17 6.44293L13.2953 3.05182C12.1915 1.97829 10.7587 1.28253 9.2128 1.06938C7.66686 0.856237 6.09154 1.13726 4.72421 1.8701C3.35688 2.60294 2.27163 3.74789 1.632 5.13243C0.992359 6.51697 0.832991 8.06607 1.17791 9.5463C1.52282 11.0265 2.35334 12.3577 3.54429 13.3392C4.73525 14.3207 6.22214 14.8994 7.78088 14.988C9.33962 15.0767 10.8858 14.6705 12.1864 13.8307C13.4869 12.9908 14.4715 11.7629 14.9916 10.3318"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 2.10896V8.77562M23 8.77562H16.3987M23 8.77562L17.906 3.93118C16.3883 2.39755 14.4183 1.40361 12.2926 1.09912C10.1669 0.794624 8.00087 1.19608 6.12079 2.243C4.24071 3.28991 2.74849 4.92556 1.86899 6.90347C0.989494 8.88138 0.770363 11.0944 1.24462 13.209C1.71888 15.3236 2.86084 17.2253 4.4984 18.6275C6.13597 20.0296 8.18044 20.8563 10.3237 20.9829C12.467 21.1096 14.5929 20.5293 16.3812 19.3295C18.1695 18.1297 19.5233 16.3755 20.2384 14.3312"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.6644V9.6644M25 9.6644H17.7986M25 9.6644L19.4429 4.57773C17.7873 2.96743 15.6381 1.92379 13.3192 1.60407C11.0003 1.28436 8.63731 1.70589 6.58632 2.80515C4.53533 3.90441 2.90745 5.62184 1.94799 7.69865C0.988539 9.77545 0.749487 12.0991 1.26686 14.3195C1.78423 16.5398 3.03 18.5366 4.81644 20.0088C6.60288 21.4811 8.8332 22.3491 11.1713 22.4821C13.5094 22.615 15.8287 22.0057 17.7795 20.746C19.7304 19.4862 21.2072 17.6443 21.9874 15.4977"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="29"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 2.49709V11.4971M31 11.4971H21.9982M31 11.4971L24.0536 4.95709C21.9841 2.8867 19.2976 1.54487 16.399 1.13381C13.5004 0.722742 10.5466 1.26471 7.9829 2.67805C5.41916 4.09138 3.38431 6.29951 2.18499 8.96969C0.985673 11.6399 0.686858 14.6274 1.33358 17.4822C1.98029 20.3369 3.5375 22.9042 5.77055 24.7971C8.0036 26.69 10.7915 27.806 13.7141 27.9769C16.6368 28.1479 19.5358 27.3645 21.9744 25.7448C24.413 24.1252 26.259 21.7569 27.2342 18.9971"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
