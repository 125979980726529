import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.652174C0 0.291988 0.298477 0 0.666667 0H11.3333C11.7015 0 12 0.291988 12 0.652174C12 1.01236 11.7015 1.30435 11.3333 1.30435H0.666667C0.298477 1.30435 0 1.01236 0 0.652174Z"
        fill={color}
      />
      <path
        d="M0 5C0 4.63981 0.298477 4.34783 0.666667 4.34783H11.3333C11.7015 4.34783 12 4.63981 12 5C12 5.36019 11.7015 5.65217 11.3333 5.65217H0.666667C0.298477 5.65217 0 5.36019 0 5Z"
        fill={color}
      />
      <path
        d="M0.666667 8.69565C0.298477 8.69565 0 8.98764 0 9.34783C0 9.70801 0.298477 10 0.666667 10H11.3333C11.7015 10 12 9.70801 12 9.34783C12 8.98764 11.7015 8.69565 11.3333 8.69565H0.666667Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.782609C0 0.350386 0.348223 0 0.777778 0H13.2222C13.6518 0 14 0.350386 14 0.782609C14 1.21483 13.6518 1.56522 13.2222 1.56522H0.777778C0.348223 1.56522 0 1.21483 0 0.782609Z"
        fill={color}
      />
      <path
        d="M0 6C0 5.56778 0.348223 5.21739 0.777778 5.21739H13.2222C13.6518 5.21739 14 5.56778 14 6C14 6.43222 13.6518 6.78261 13.2222 6.78261H0.777778C0.348223 6.78261 0 6.43222 0 6Z"
        fill={color}
      />
      <path
        d="M0.777778 10.4348C0.348223 10.4348 0 10.7852 0 11.2174C0 11.6496 0.348223 12 0.777778 12H13.2222C13.6518 12 14 11.6496 14 11.2174C14 10.7852 13.6518 10.4348 13.2222 10.4348H0.777778Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44532e-07 0.573015C2.44532e-07 0.256548 0.275517 0 0.615385 0H15.3846C15.7245 0 16 0.256548 16 0.573015C16 0.889483 15.7245 1.14603 15.3846 1.14603H0.615385C0.275517 1.14603 2.44532e-07 0.889483 2.44532e-07 0.573015Z"
        fill={color}
      />
      <path
        d="M2.44532e-07 13.427C2.44532e-07 13.1105 0.275517 12.854 0.615385 12.854H15.3846C15.7245 12.854 16 13.1105 16 13.427C16 13.7435 15.7245 14 15.3846 14H0.615385C0.275517 14 2.44532e-07 13.7435 2.44532e-07 13.427Z"
        fill={color}
      />
      <path
        d="M0.615385 6.74181C0.275517 6.74181 0 6.99836 0 7.31483C0 7.63129 0.275517 7.88784 0.615385 7.88784H15.3846C15.7245 7.88784 16 7.63129 16 7.31483C16 6.99836 15.7245 6.74181 15.3846 6.74181H0.615385Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.36231e-07 0.859523C3.36231e-07 0.384821 0.378836 0 0.846154 0H21.1538C21.6212 0 22 0.384821 22 0.859523C22 1.33422 21.6212 1.71905 21.1538 1.71905H0.846154C0.378836 1.71905 3.36231e-07 1.33422 3.36231e-07 0.859523Z"
        fill={color}
      />
      <path
        d="M3.36231e-07 20.1405C3.36231e-07 19.6658 0.378836 19.281 0.846154 19.281H21.1538C21.6212 19.281 22 19.6658 22 20.1405C22 20.6152 21.6212 21 21.1538 21H0.846154C0.378836 21 3.36231e-07 20.6152 3.36231e-07 20.1405Z"
        fill={color}
      />
      <path
        d="M0.846154 10.1127C0.378836 10.1127 0 10.4975 0 10.9722C0 11.4469 0.378836 11.8318 0.846154 11.8318H21.1538C21.6212 11.8318 22 11.4469 22 10.9722C22 10.4975 21.6212 10.1127 21.1538 10.1127H0.846154Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38228e-06 0.909091C6.38228e-06 0.407014 0.413282 0 0.923083 0H23.0769C23.5867 0 24 0.407014 24 0.909091C24 1.41117 23.5867 1.81818 23.0769 1.81818H0.923083C0.413282 1.81818 6.38228e-06 1.41117 6.38228e-06 0.909091Z"
        fill={color}
      />
      <path
        d="M0 10C0 9.49792 0.413275 9.09091 0.923076 9.09091H23.0769C23.5867 9.09091 24 9.49792 24 10C24 10.5021 23.5867 10.9091 23.0769 10.9091H0.923076C0.413275 10.9091 0 10.5021 0 10Z"
        fill={color}
      />
      <path
        d="M0.92309 18.1818C0.413289 18.1818 1.4085e-05 18.5888 1.4085e-05 19.0909C1.4085e-05 19.593 0.413289 20 0.92309 20H23.0769C23.5867 20 24 19.593 24 19.0909C24 18.5888 23.5867 18.1818 23.0769 18.1818H0.92309Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.97785e-06 1.13636C7.97785e-06 0.508767 0.516602 0 1.15385 0H28.8461C29.4834 0 30 0.508767 30 1.13636C30 1.76396 29.4834 2.27273 28.8461 2.27273H1.15385C0.516602 2.27273 7.97785e-06 1.76396 7.97785e-06 1.13636Z"
        fill={color}
      />
      <path
        d="M0 12.5C0 11.8724 0.516594 11.3636 1.15385 11.3636H28.8461C29.4834 11.3636 30 11.8724 30 12.5C30 13.1276 29.4834 13.6364 28.8461 13.6364H1.15385C0.516594 13.6364 0 13.1276 0 12.5Z"
        fill={color}
      />
      <path
        d="M1.15386 22.7273C0.516612 22.7273 1.76063e-05 23.236 1.76063e-05 23.8636C1.76063e-05 24.4912 0.516612 25 1.15386 25H28.8462C29.4834 25 30 24.4912 30 23.8636C30 23.236 29.4834 22.7273 28.8462 22.7273H1.15386Z"
        fill={color}
      />
    </svg>
  )
};
