import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.37117V6.58078M7 8.79039H7.00574M6.018 1.53182L1.15392 9.34279C1.05363 9.50985 1.00057 9.69926 1 9.89217C0.999443 10.0851 1.0514 10.2748 1.15071 10.4424C1.25003 10.61 1.39323 10.7496 1.56607 10.8475C1.73892 10.9453 1.93538 10.9979 2.13592 11H11.8641C12.0646 10.9979 12.2611 10.9453 12.4339 10.8475C12.6068 10.7496 12.75 10.61 12.8493 10.4424C12.9486 10.2748 13.0006 10.0851 13 9.89217C12.9994 9.69926 12.9464 9.50985 12.8461 9.34279L7.982 1.53182C7.87963 1.36947 7.73548 1.23525 7.56347 1.14209C7.39146 1.04894 7.1974 1 7 1C6.8026 1 6.60854 1.04894 6.43653 1.14209C6.26452 1.23525 6.12037 1.36947 6.018 1.53182Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5.0454V7.69694M8 10.3485H8.0067M6.85433 1.63819L1.17957 11.0114C1.06257 11.2118 1.00066 11.4391 1.00001 11.6706C0.99935 11.9021 1.05997 12.1297 1.17583 12.3308C1.2917 12.532 1.45877 12.6995 1.66042 12.8169C1.86207 12.9343 2.09128 12.9975 2.32524 13H13.6748C13.9087 12.9975 14.1379 12.9343 14.3396 12.8169C14.5412 12.6995 14.7083 12.532 14.8242 12.3308C14.94 12.1297 15.0006 11.9021 15 11.6706C14.9993 11.4391 14.9374 11.2118 14.8204 11.0114L9.14567 1.63819C9.02623 1.44337 8.85806 1.2823 8.65738 1.17051C8.45671 1.05873 8.2303 1 8 1C7.7697 1 7.54329 1.05873 7.34262 1.17051C7.14194 1.2823 6.97377 1.44337 6.85433 1.63819Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.88252V8.75501M9 11.6275H9.00766M7.69066 2.19137L1.20522 12.3456C1.07151 12.5628 1.00075 12.809 1.00001 13.0598C0.999257 13.3106 1.06854 13.5572 1.20095 13.7751C1.33337 13.9929 1.52431 14.1745 1.75477 14.3017C1.98523 14.4289 2.24718 14.4972 2.51456 14.5H15.4854C15.7528 14.4972 16.0148 14.4289 16.2452 14.3017C16.4757 14.1745 16.6666 13.9929 16.799 13.7751C16.9315 13.5572 17.0007 13.3106 17 13.0598C16.9992 12.809 16.9285 12.5628 16.7948 12.3456L10.3093 2.19137C10.1728 1.98032 9.98064 1.80582 9.7513 1.68472C9.52195 1.56362 9.2632 1.5 9 1.5C8.7368 1.5 8.47805 1.56362 8.2487 1.68472C8.01936 1.80582 7.82716 1.98032 7.69066 2.19137Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.40522V11.6035M12 15.8017H12.0105M10.1997 2.01046L1.28218 16.8513C1.09832 17.1687 1.00104 17.5286 1.00001 17.8951C0.998979 18.2616 1.09424 18.6221 1.27631 18.9405C1.45838 19.2589 1.72092 19.5243 2.0378 19.7102C2.35469 19.896 2.71487 19.996 3.08252 20H20.9175C21.2851 19.996 21.6453 19.896 21.9622 19.7102C22.2791 19.5243 22.5416 19.2589 22.7237 18.9405C22.9058 18.6221 23.001 18.2616 23 17.8951C22.999 17.5286 22.9017 17.1687 22.7178 16.8513L13.8003 2.01046C13.6127 1.702 13.3484 1.44697 13.033 1.26998C12.7177 1.09298 12.3619 1 12 1C11.6381 1 11.2823 1.09298 10.967 1.26998C10.6516 1.44697 10.3873 1.702 10.1997 2.01046Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7.74234V12.1616M13 16.5808H13.0115M11.036 2.06364L1.30783 17.6856C1.10726 18.0197 1.00113 18.3985 1.00001 18.7843C0.998886 19.1702 1.10281 19.5495 1.30143 19.8847C1.50005 20.2199 1.78646 20.4992 2.13215 20.6949C2.47784 20.8906 2.87077 20.9958 3.27184 21H22.7282C23.1292 20.9958 23.5222 20.8906 23.8679 20.6949C24.2135 20.4992 24.4999 20.2199 24.6986 19.8847C24.8972 19.5495 25.0011 19.1702 25 18.7843C24.9989 18.3985 24.8927 18.0197 24.6922 17.6856L14.964 2.06364C14.7593 1.73895 14.471 1.4705 14.1269 1.28419C13.7829 1.09788 13.3948 1 13 1C12.6052 1 12.2171 1.09788 11.8731 1.28419C11.529 1.4705 11.2407 1.73895 11.036 2.06364Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.76504V15.51M16 21.255H16.0144M13.545 2.38274L1.38479 22.6913C1.13407 23.1256 1.00142 23.6181 1.00001 24.1196C0.998607 24.6212 1.12851 25.1144 1.37679 25.5501C1.62507 25.9859 1.98307 26.349 2.41519 26.6034C2.8473 26.8577 3.33846 26.9945 3.8398 27H28.1602C28.6615 26.9945 29.1527 26.8577 29.5848 26.6034C30.0169 26.349 30.3749 25.9859 30.6232 25.5501C30.8715 25.1144 31.0014 24.6212 31 24.1196C30.9986 23.6181 30.8659 23.1256 30.6152 22.6913L18.455 2.38274C18.1991 1.96063 17.8387 1.61164 17.4087 1.36944C16.9787 1.12724 16.4935 1 16 1C15.5065 1 15.0213 1.12724 14.5913 1.36944C14.1613 1.61164 13.8009 1.96063 13.545 2.38274Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
