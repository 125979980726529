import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6 1.4L1.4 12.6M1.4 1.4L12.6 12.6M2.06667 13H11.9333C12.5224 13 13 12.5224 13 11.9333V2.06667C13 1.47756 12.5224 1 11.9333 1H2.06667C1.47756 1 1 1.47756 1 2.06667V11.9333C1 12.5224 1.47756 13 2.06667 13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5333 1.46667L1.46667 14.5333M1.46667 1.46667L14.5333 14.5333M2.24444 15H13.7556C14.4428 15 15 14.4428 15 13.7556V2.24444C15 1.55716 14.4428 1 13.7556 1H2.24444C1.55716 1 1 1.55716 1 2.24444V13.7556C1 14.4428 1.55716 15 2.24444 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2472 1.53333L1.31382 16.4667M1.31382 1.53333L16.2472 16.4667M2.20271 17H15.3583C16.1437 17 16.7805 16.3632 16.7805 15.5778V2.42222C16.7805 1.63675 16.1437 1 15.3583 1H2.20271C1.41724 1 0.780487 1.63675 0.780487 2.42222V15.5778C0.780487 16.3632 1.41724 17 2.20271 17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3154 1.73333L1.78212 22.2667M1.78212 1.73333L22.3154 22.2667M3.00434 23H21.0932C22.1733 23 23.0488 22.1245 23.0488 21.0444V2.95556C23.0488 1.87553 22.1733 1 21.0932 1H3.00434C1.92431 1 1.04878 1.87553 1.04878 2.95556V21.0444C1.04878 22.1245 1.92431 23 3.00434 23Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2 1.8L1.8 24.2M1.8 1.8L24.2 24.2M3.13333 25H22.8667C24.0449 25 25 24.0449 25 22.8667V3.13333C25 1.95513 24.0449 1 22.8667 1H3.13333C1.95513 1 1 1.95513 1 3.13333V22.8667C1 24.0449 1.95513 25 3.13333 25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 2L2 30M2 2L30 30M3.66667 31H28.3333C29.8061 31 31 29.8061 31 28.3333V3.66667C31 2.19391 29.8061 1 28.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V28.3333C1 29.8061 2.19391 31 3.66667 31Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
