import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8 1H2.2C1.8 1 1 1.3 1 2.5V11.5C1 12 1.24 13 2.2 13H11.8C12.2 13 13 12.7 13 11.5V9.625M10.3333 7V1M7.66667 4H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6 1H2.4C1.93333 1 1 1.35 1 2.75V13.25C1 13.8333 1.28 15 2.4 15H13.6C14.0667 15 15 14.65 15 13.25V11.0625M11.8889 8V1M8.77778 4.5H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4 1H2.6C2.06667 1 1 1.4 1 3C1 4.6 1 11.6667 1 15C1 15.6667 1.32 17 2.6 17C3.88 17 11.6667 17 15.4 17C15.9333 17 17 16.6 17 15C17 13.4 17 12.6667 17 12.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.4443 9V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88867 5H16.9998"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8 1H3.2C2.46667 1 1 1.55 1 3.75C1 5.95 1 15.6667 1 20.25C1 21.1667 1.44 23 3.2 23C4.96 23 15.6667 23 20.8 23C21.5333 23 23 22.45 23 20.25C23 18.05 23 17.0417 23 16.8125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.111 12V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2219 6.5H22.9997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6 1H3.4C2.6 1 1 1.6 1 4V22C1 23 1.48 25 3.4 25H22.6C23.4 25 25 24.4 25 22V18.25M19.6667 13V1M14.3333 7H25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1H4C3 1 1 1.75 1 4.75V27.25C1 28.5 1.6 31 4 31H28C29 31 31 30.25 31 27.25V22.5625M24.3333 16V1M17.6667 8.5H31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
