import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.3984L3.89323 8.1992M3.89323 8.1992L1.82666 5.74127C1.47861 5.3273 1.86534 5.22382 2.10221 5.22382L4.85763 4.96509L7.61306 2.89525L7.8886 1.34286C8.10903 0.721908 8.53153 1.08413 8.71523 1.34286L12.8484 6.25873C13.2892 6.6727 12.6647 6.77619 12.2973 6.77619H10.3685L7.61306 8.84603L6.78643 10.6571C6.566 11.2781 6.1435 10.9159 5.9598 10.6571L3.89323 8.1992Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12.2781L4.37544 9.63905M4.37544 9.63905L1.96444 6.68952C1.55838 6.19276 2.00956 6.06858 2.28591 6.06858L5.50057 5.75811L8.71523 3.2743L9.0367 1.41143C9.29387 0.666289 9.78679 1.10096 10.0011 1.41143L14.8231 7.31048C15.3374 7.80724 14.6088 7.93143 14.1802 7.93143H11.9299L8.71523 10.4152L7.75084 12.5886C7.49366 13.3337 7.00075 12.899 6.78644 12.5886L4.37544 9.63905Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.1578L4.85764 11.0789M4.85764 11.0789L2.10222 7.63778C1.63815 7.05822 2.15378 6.91334 2.46961 6.91335L6.14351 6.55112L9.81741 3.65335L10.1848 1.48C10.4787 0.610671 11.042 1.11778 11.287 1.48L16.7978 8.36222C17.3856 8.94178 16.5529 9.08667 16.063 9.08667H13.4913L9.81741 11.9844L8.71524 14.52C8.42133 15.3893 7.858 14.8822 7.61307 14.52L4.85764 11.0789Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19.7968L6.30426 15.3984M6.30426 15.3984L2.51555 10.4825C1.87745 9.6546 2.58645 9.44763 3.02071 9.44764L8.07232 8.93018L13.1239 4.7905L13.6291 1.68572C14.0332 0.443815 14.8078 1.16826 15.1446 1.68572L22.722 11.5175C23.5303 12.3454 22.3852 12.5524 21.7117 12.5524H18.1756L13.1239 16.6921L11.6085 20.3143C11.2043 21.5562 10.4297 20.8317 10.093 20.3143L6.30426 15.3984Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.2367L6.78647 16.6183M6.78647 16.6183L2.65333 11.4567C1.95722 10.5873 2.73067 10.37 3.20441 10.37L8.71526 9.82668L14.2261 5.48002L14.7772 2.22001C15.2181 0.916006 16.0631 1.67667 16.4305 2.22001L24.6967 12.5433C25.5785 13.4127 24.3293 13.63 23.5946 13.63H19.737L14.2261 17.9767L12.5729 21.78C12.132 23.084 11.287 22.3233 10.9196 21.78L6.78647 16.6183Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="29"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 26.3757L8.23308 20.4379M8.23308 20.4379L3.06666 13.8014C2.19653 12.6837 3.16334 12.4043 3.75552 12.4043L10.6441 11.7057L17.5326 6.11717L18.2215 1.92572C18.7726 0.249151 19.8288 1.22715 20.2881 1.92572L30.6209 15.1986C31.7231 16.3163 30.1617 16.5957 29.2432 16.5957H24.4212L17.5326 22.1843L15.4661 27.0743C14.915 28.7508 13.8587 27.7728 13.3995 27.0743L8.23308 20.4379Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
