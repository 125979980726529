import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.67666 6.90368C7.58318 6.90368 9.12872 5.35824 9.12872 3.45184C9.12872 1.54544 7.58318 0 5.67666 0C3.77015 0 2.22461 1.54544 2.22461 3.45184C2.22461 5.35824 3.77015 6.90368 5.67666 6.90368Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M5.67671 7.29492C2.54685 7.29492 0 9.84161 0 12.9713V13.3855C0 13.723 0.276164 13.9992 0.613699 13.9992H10.7397C11.0773 13.9992 11.3534 13.723 11.3534 13.3855V12.9713C11.3534 9.82627 8.80658 7.29492 5.67671 7.29492Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86562 7.88992C9.04449 7.88992 10.8108 6.1237 10.8108 3.94496C10.8108 1.76622 9.04449 0 6.86562 0C4.68674 0 2.92041 1.76622 2.92041 3.94496C2.92041 6.1237 4.68674 7.88992 6.86562 7.88992Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M6.8656 8.33789C3.28862 8.33789 0.37793 11.2484 0.37793 14.8252V15.2985C0.37793 15.6843 0.693546 15.9999 1.0793 15.9999H12.6519C13.0377 15.9999 13.3533 15.6843 13.3533 15.2985V14.8252C13.3533 11.2309 10.4426 8.33789 6.8656 8.33789Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.48767 8.87615C9.93891 8.87615 11.926 6.88916 11.926 4.43808C11.926 1.98699 9.93891 0 7.48767 0C5.03644 0 3.04932 1.98699 3.04932 4.43808C3.04932 6.88916 5.03644 8.87615 7.48767 8.87615Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M7.4876 9.38086C3.46349 9.38086 0.188965 12.6552 0.188965 16.679V17.2116C0.188965 17.6455 0.544033 18.0006 0.978006 18.0006H13.9972C14.4312 18.0006 14.7862 17.6455 14.7862 17.2116V16.679C14.7862 12.6354 11.5117 9.38086 7.4876 9.38086Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73129 11.8349C12.9996 11.8349 15.6491 9.18555 15.6491 5.91744C15.6491 2.64933 12.9996 0 9.73129 0C6.46297 0 3.81348 2.64933 3.81348 5.91744C3.81348 9.18555 6.46297 11.8349 9.73129 11.8349Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M9.73151 12.5078C4.36603 12.5078 0 16.8736 0 22.2387V22.9488C0 23.5274 0.473425 24.0008 1.05206 24.0008H18.411C18.9896 24.0008 19.463 23.5274 19.463 22.9488V22.2387C19.463 16.8473 15.097 12.5078 9.73151 12.5078Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7313 12.8207C14.2719 12.8207 17.1422 9.95068 17.1422 6.41035C17.1422 2.87001 14.2719 0 10.7313 0C7.1906 0 4.32031 2.87001 4.32031 6.41035C4.32031 9.95068 7.1906 12.8207 10.7313 12.8207Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M10.7314 13.5488C4.91883 13.5488 0.188965 18.2782 0.188965 24.0903V24.8595C0.188965 25.4863 0.701842 25.9991 1.32869 25.9991H20.1342C20.761 25.9991 21.2739 25.4863 21.2739 24.8595V24.0903C21.2739 18.2497 16.544 13.5488 10.7314 13.5488Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9754 15.7793C17.3331 15.7793 20.8658 12.247 20.8658 7.88966C20.8658 3.53232 17.3331 0 12.9754 0C8.61762 0 5.08496 3.53232 5.08496 7.88966C5.08496 12.247 8.61762 15.7793 12.9754 15.7793Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <path
        d="M12.9753 16.6758C5.82137 16.6758 0 22.4966 0 29.6499V30.5966C0 31.3681 0.631233 31.9992 1.40274 31.9992H24.548C25.3195 31.9992 25.9507 31.3681 25.9507 30.5966V29.6499C25.9507 22.4615 20.1293 16.6758 12.9753 16.6758Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
    </svg>
  )
};
