import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="12"
        r="0.75"
        transform="rotate(-90 2 12)"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="2"
        cy="7"
        r="0.75"
        transform="rotate(-90 2 7)"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="2"
        cy="2"
        r="0.75"
        transform="rotate(-90 2 2)"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="14"
        r="0.75"
        transform="rotate(-90 2 14)"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="2"
        cy="8"
        r="0.75"
        transform="rotate(-90 2 8)"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="2"
        cy="2"
        r="0.75"
        transform="rotate(-90 2 2)"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="16"
        r="1"
        transform="rotate(-90 2 16)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="2"
        cy="9"
        r="1"
        transform="rotate(-90 2 9)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="2"
        cy="2"
        r="1"
        transform="rotate(-90 2 2)"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="4"
      height="24"
      viewBox="0 0 4 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2"
        cy="22"
        r="1"
        transform="rotate(-90 2 22)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="2"
        cy="12"
        r="1"
        transform="rotate(-90 2 12)"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="2"
        cy="2"
        r="1"
        transform="rotate(-90 2 2)"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="6"
      height="26"
      viewBox="0 0 6 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="23.5"
        r="1.25"
        transform="rotate(-90 3 23.5)"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="3"
        cy="13"
        r="1.25"
        transform="rotate(-90 3 13)"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="3"
        cy="2.5"
        r="1.25"
        transform="rotate(-90 3 2.5)"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="6"
      height="30"
      viewBox="0 0 6 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="27.5"
        r="1.25"
        transform="rotate(-90 3 27.5)"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="3"
        cy="15"
        r="1.25"
        transform="rotate(-90 3 15)"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="3"
        cy="2.5"
        r="1.25"
        transform="rotate(-90 3 2.5)"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  )
};
