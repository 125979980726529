import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7874 0H8.81263C8.57684 0 8.4 0.191579 8.4 0.412631C8.4 0.633684 8.59158 0.825263 8.81263 0.825263H10.7874C12.0989 0.825263 13.1747 1.90105 13.1747 3.21263C13.1747 4.52421 12.0989 5.6 10.7874 5.6H8.81263C8.57684 5.6 8.4 5.79158 8.4 6.01263C8.4 6.24842 8.59158 6.42526 8.81263 6.42526H10.7874C12.5558 6.42526 14 4.98105 14 3.21263C14 1.44421 12.5558 0 10.7874 0Z"
        fill={color}
      />
      <path
        d="M5.18737 5.6H3.21263C1.90105 5.6 0.825263 4.52421 0.825263 3.21263C0.825263 1.90105 1.90105 0.825263 3.21263 0.825263H5.18737C5.42316 0.825263 5.6 0.633684 5.6 0.412631C5.6 0.191579 5.40842 0 5.18737 0H3.21263C1.44421 0 0 1.44421 0 3.21263C0 4.98105 1.44421 6.42526 3.21263 6.42526H5.18737C5.42316 6.42526 5.6 6.23368 5.6 6.01263C5.6 5.77684 5.42316 5.6 5.18737 5.6Z"
        fill={color}
      />
      <path
        d="M3.41898 3.21263C3.41898 3.44842 3.61055 3.62526 3.83161 3.62526H10.1684C10.4042 3.62526 10.5811 3.43369 10.5811 3.21263C10.5811 2.97684 10.3895 2.8 10.1684 2.8H3.83161C3.59582 2.8 3.41898 2.97684 3.41898 3.21263Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="17"
      height="8"
      viewBox="0 0 17 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8284 0.199219H10.5716C10.3021 0.199219 10.1 0.418166 10.1 0.670798C10.1 0.923429 10.3189 1.14238 10.5716 1.14238H12.8284C14.3274 1.14238 15.5568 2.37185 15.5568 3.8708C15.5568 5.36974 14.3274 6.59922 12.8284 6.59922H10.5716C10.3021 6.59922 10.1 6.81817 10.1 7.0708C10.1 7.34027 10.3189 7.54238 10.5716 7.54238H12.8284C14.8495 7.54238 16.5 5.89185 16.5 3.8708C16.5 1.84974 14.8495 0.199219 12.8284 0.199219Z"
        fill={color}
      />
      <path
        d="M6.42842 6.59922H4.17158C2.67263 6.59922 1.44316 5.36974 1.44316 3.8708C1.44316 2.37185 2.67263 1.14238 4.17158 1.14238H6.42842C6.6979 1.14238 6.9 0.923429 6.9 0.670798C6.9 0.418166 6.68105 0.199219 6.42842 0.199219H4.17158C2.15053 0.199219 0.5 1.84974 0.5 3.8708C0.5 5.89185 2.15053 7.54238 4.17158 7.54238H6.42842C6.6979 7.54238 6.9 7.32343 6.9 7.0708C6.9 6.80132 6.6979 6.59922 6.42842 6.59922Z"
        fill={color}
      />
      <path
        d="M4.4074 3.8708C4.4074 4.14027 4.62635 4.34238 4.87898 4.34238H12.1211C12.3906 4.34238 12.5927 4.12343 12.5927 3.8708C12.5927 3.60132 12.3737 3.39922 12.1211 3.39922H4.87898C4.60951 3.39922 4.4074 3.60132 4.4074 3.8708Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8695 0H11.3305C11.0274 0 10.8 0.246316 10.8 0.530526C10.8 0.814737 11.0463 1.06105 11.3305 1.06105H13.8695C15.5558 1.06105 16.9389 2.44421 16.9389 4.13053C16.9389 5.81684 15.5558 7.2 13.8695 7.2H11.3305C11.0274 7.2 10.8 7.44632 10.8 7.73053C10.8 8.03368 11.0463 8.26105 11.3305 8.26105H13.8695C16.1432 8.26105 18 6.40421 18 4.13053C18 1.85684 16.1432 0 13.8695 0Z"
        fill={color}
      />
      <path
        d="M6.66947 7.2H4.13053C2.44421 7.2 1.06105 5.81684 1.06105 4.13053C1.06105 2.44421 2.44421 1.06105 4.13053 1.06105H6.66947C6.97263 1.06105 7.2 0.814737 7.2 0.530526C7.2 0.246316 6.95368 0 6.66947 0H4.13053C1.85684 0 0 1.85684 0 4.13053C0 6.40421 1.85684 8.26105 4.13053 8.26105H6.66947C6.97263 8.26105 7.2 8.01474 7.2 7.73053C7.2 7.42737 6.97263 7.2 6.66947 7.2Z"
        fill={color}
      />
      <path
        d="M4.39583 4.13053C4.39583 4.43368 4.64214 4.66105 4.92635 4.66105H13.0737C13.3769 4.66105 13.6042 4.41474 13.6042 4.13053C13.6042 3.82737 13.3579 3.6 13.0737 3.6H4.92635C4.62319 3.6 4.39583 3.82737 4.39583 4.13053Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4926 0H15.1074C14.7032 0 14.4 0.328421 14.4 0.707368C14.4 1.08632 14.7284 1.41474 15.1074 1.41474H18.4926C20.7411 1.41474 22.5853 3.25895 22.5853 5.50737C22.5853 7.75579 20.7411 9.6 18.4926 9.6H15.1074C14.7032 9.6 14.4 9.92842 14.4 10.3074C14.4 10.7116 14.7284 11.0147 15.1074 11.0147H18.4926C21.5242 11.0147 24 8.53895 24 5.50737C24 2.47579 21.5242 0 18.4926 0Z"
        fill={color}
      />
      <path
        d="M8.89263 9.6H5.50737C3.25895 9.6 1.41474 7.75579 1.41474 5.50737C1.41474 3.25895 3.25895 1.41474 5.50737 1.41474H8.89263C9.29684 1.41474 9.6 1.08632 9.6 0.707368C9.6 0.328421 9.27158 0 8.89263 0H5.50737C2.47579 0 0 2.47579 0 5.50737C0 8.53895 2.47579 11.0147 5.50737 11.0147H8.89263C9.29684 11.0147 9.6 10.6863 9.6 10.3074C9.6 9.90316 9.29684 9.6 8.89263 9.6Z"
        fill={color}
      />
      <path
        d="M5.8611 5.50737C5.8611 5.91158 6.18952 6.21474 6.56847 6.21474H17.4316C17.8358 6.21474 18.139 5.88631 18.139 5.50737C18.139 5.10316 17.8106 4.8 17.4316 4.8H6.56847C6.16426 4.8 5.8611 5.10316 5.8611 5.50737Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="12"
      viewBox="0 0 26 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0337 0H16.3663C15.9284 0 15.6 0.355789 15.6 0.766316C15.6 1.17684 15.9558 1.53263 16.3663 1.53263H20.0337C22.4695 1.53263 24.4674 3.53053 24.4674 5.96632C24.4674 8.4021 22.4695 10.4 20.0337 10.4H16.3663C15.9284 10.4 15.6 10.7558 15.6 11.1663C15.6 11.6042 15.9558 11.9326 16.3663 11.9326H20.0337C23.3179 11.9326 26 9.25053 26 5.96632C26 2.6821 23.3179 0 20.0337 0Z"
        fill={color}
      />
      <path
        d="M9.63369 10.4H5.96632C3.53053 10.4 1.53263 8.4021 1.53263 5.96632C1.53263 3.53053 3.53053 1.53263 5.96632 1.53263H9.63369C10.0716 1.53263 10.4 1.17684 10.4 0.766316C10.4 0.355789 10.0442 0 9.63369 0H5.96632C2.68211 0 0 2.6821 0 5.96632C0 9.25053 2.68211 11.9326 5.96632 11.9326H9.63369C10.0716 11.9326 10.4 11.5768 10.4 11.1663C10.4 10.7284 10.0716 10.4 9.63369 10.4Z"
        fill={color}
      />
      <path
        d="M6.34953 5.96631C6.34953 6.40421 6.70532 6.73263 7.11584 6.73263H18.8843C19.3222 6.73263 19.6506 6.37684 19.6506 5.96631C19.6506 5.52842 19.2948 5.2 18.8843 5.2H7.11584C6.67795 5.2 6.34953 5.52842 6.34953 5.96631Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="15"
      viewBox="0 0 32 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6568 0H20.1432C19.6042 0 19.2 0.437894 19.2 0.943158C19.2 1.44842 19.6379 1.88632 20.1432 1.88632H24.6568C27.6547 1.88632 30.1137 4.34526 30.1137 7.34316C30.1137 10.3411 27.6547 12.8 24.6568 12.8H20.1432C19.6042 12.8 19.2 13.2379 19.2 13.7432C19.2 14.2821 19.6379 14.6863 20.1432 14.6863H24.6568C28.6989 14.6863 32 11.3853 32 7.34316C32 3.30105 28.6989 0 24.6568 0Z"
        fill={color}
      />
      <path
        d="M11.8568 12.8H7.34316C4.34526 12.8 1.88632 10.3411 1.88632 7.34316C1.88632 4.34526 4.34526 1.88632 7.34316 1.88632H11.8568C12.3958 1.88632 12.8 1.44842 12.8 0.943158C12.8 0.437894 12.3621 0 11.8568 0H7.34316C3.30105 0 0 3.30105 0 7.34316C0 11.3853 3.30105 14.6863 7.34316 14.6863H11.8568C12.3958 14.6863 12.8 14.2484 12.8 13.7432C12.8 13.2042 12.3958 12.8 11.8568 12.8Z"
        fill={color}
      />
      <path
        d="M7.8148 7.34315C7.8148 7.8821 8.2527 8.28631 8.75796 8.28631H23.2422C23.7811 8.28631 24.1853 7.84841 24.1853 7.34315C24.1853 6.8042 23.7474 6.39999 23.2422 6.39999H8.75796C8.21901 6.39999 7.8148 6.8042 7.8148 7.34315Z"
        fill={color}
      />
    </svg>
  )
};
