import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94364 11.9091C7.84774 12.0744 7.7101 12.2116 7.54449 12.307C7.37888 12.4024 7.19112 12.4526 7 12.4526C6.80888 12.4526 6.62112 12.4024 6.45551 12.307C6.2899 12.2116 6.15226 12.0744 6.05636 11.9091M10.6164 7.54545C10.3737 6.65713 10.258 5.73893 10.2727 4.81818C10.2736 4.22531 10.1134 3.64333 9.80927 3.13441C9.50513 2.6255 9.06845 2.20876 8.54588 1.92871C8.02332 1.64867 7.4345 1.51583 6.84231 1.5444C6.25012 1.57297 5.67682 1.76186 5.18364 2.09091M3.86909 3.86909C3.77433 4.17652 3.72652 4.49648 3.72727 4.81818C3.72727 8.63636 2.09091 9.72727 2.09091 9.72727H9.72727M1 1L13 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.10091 13.7273C8.98903 13.9201 8.82845 14.0802 8.63523 14.1915C8.44202 14.3028 8.22297 14.3614 8 14.3614C7.77703 14.3614 7.55798 14.3028 7.36477 14.1915C7.17155 14.0802 7.01097 13.9201 6.89909 13.7273M12.2191 8.63636C11.936 7.59999 11.801 6.52876 11.8182 5.45455C11.8192 4.76286 11.6323 4.08388 11.2775 3.49015C10.9226 2.89641 10.4132 2.41022 9.80353 2.0835C9.19387 1.75678 8.50691 1.6018 7.81603 1.63513C7.12514 1.66846 6.45629 1.88884 5.88091 2.27273M4.34727 4.34727C4.23671 4.70593 4.18093 5.07923 4.18182 5.45455C4.18182 9.90909 2.27273 11.1818 2.27273 11.1818H11.1818M1 1L15 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2582 15.5455C10.1303 15.7659 9.94679 15.9488 9.72598 16.076C9.50517 16.2032 9.25482 16.2702 9 16.2702C8.74518 16.2702 8.49483 16.2032 8.27402 16.076C8.05321 15.9488 7.86968 15.7659 7.74182 15.5455M13.8218 9.72727C13.4983 8.54284 13.344 7.31858 13.3636 6.09091C13.3648 5.30041 13.1512 4.52444 12.7457 3.84588C12.3402 3.16733 11.7579 2.61168 11.0612 2.23828C10.3644 1.86489 9.57933 1.68778 8.78975 1.72587C8.00016 1.76396 7.23576 2.01582 6.57818 2.45455M4.82545 4.82545C4.6991 5.23535 4.63535 5.66198 4.63636 6.09091C4.63636 11.1818 2.45455 12.6364 2.45455 12.6364H12.6364M1 1L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18.63 13C18.1851 11.3714 17.973 9.68804 18 8C18.0016 6.91306 17.7079 5.84611 17.1503 4.91309C16.5927 3.98008 15.7922 3.21606 14.8341 2.70264C13.8761 2.18922 12.7966 1.94569 11.7109 1.99807C10.6252 2.05044 9.57417 2.39675 8.67 3M6.26 6.26C6.08627 6.82361 5.99861 7.41022 6 8C6 15 3 17 3 17H17M1 1L23 23"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8873 22.8182C14.6955 23.1488 14.4202 23.4233 14.089 23.614C13.7578 23.8048 13.3822 23.9052 13 23.9052C12.6178 23.9052 12.2422 23.8048 11.911 23.614C11.5798 23.4233 11.3045 23.1488 11.1127 22.8182M20.2327 14.0909C19.7474 12.3143 19.516 10.4779 19.5455 8.63636C19.5472 7.45062 19.2268 6.28666 18.6185 5.26883C18.0103 4.25099 17.1369 3.41752 16.0918 2.85742C15.0466 2.29733 13.869 2.03167 12.6846 2.0888C11.5002 2.14594 10.3536 2.52373 9.36727 3.18182M6.73818 6.73818C6.54865 7.35303 6.45303 7.99297 6.45455 8.63636C6.45455 16.2727 3.18182 18.4545 3.18182 18.4545H18.4545M1 1L25 25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3591 28.2727C18.1193 28.686 17.7752 29.0291 17.3612 29.2675C16.9472 29.506 16.4778 29.6315 16 29.6315C15.5222 29.6315 15.0528 29.506 14.6388 29.2675C14.2248 29.0291 13.8807 28.686 13.6409 28.2727M25.0409 17.3636C24.4343 15.1428 24.145 12.8473 24.1818 10.5455C24.184 9.06327 23.7835 7.60833 23.0232 6.33603C22.2628 5.06374 21.1711 4.0219 19.8647 3.32178C18.5583 2.62167 17.0862 2.28958 15.6058 2.361C14.1253 2.43242 12.692 2.90466 11.4591 3.72727M8.17273 8.17273C7.93582 8.94129 7.81629 9.74121 7.81818 10.5455C7.81818 20.0909 3.72727 22.8182 3.72727 22.8182H22.8182M1 1L31 31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
