import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22222 6.40166V4.00227C3.22153 3.25848 3.47682 2.54095 3.93854 1.98897C4.40026 1.43699 5.03546 1.08994 5.72084 1.01519C6.40621 0.940442 7.09286 1.14333 7.64749 1.58446C8.20211 2.0256 8.58514 2.67351 8.72222 3.40242M2.11111 6.40166H9.88889C10.5025 6.40166 11 6.93878 11 7.60136V11.8003C11 12.4629 10.5025 13 9.88889 13H2.11111C1.49746 13 1 12.4629 1 11.8003V7.60136C1 6.93878 1.49746 6.40166 2.11111 6.40166Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66667 7.30194V4.50264C3.66584 3.6349 3.97219 2.79778 4.52625 2.1538C5.08031 1.50982 5.84256 1.10493 6.665 1.01772C7.48745 0.930516 8.31143 1.16722 8.97698 1.68187C9.64253 2.19653 10.1022 2.95243 10.2667 3.80282M2.33333 7.30194H11.6667C12.403 7.30194 13 7.92858 13 8.70159V13.6004C13 14.3734 12.403 15 11.6667 15H2.33333C1.59695 15 1 14.3734 1 13.6004V8.70159C1 7.92858 1.59695 7.30194 2.33333 7.30194Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11111 8.20222V5.00302C4.11014 4.01131 4.46755 3.0546 5.11396 2.31863C5.76036 1.58266 6.64965 1.11992 7.60917 1.02026C8.5687 0.920589 9.53001 1.1911 10.3065 1.77928C11.083 2.36746 11.6192 3.23134 11.8111 4.20322M2.55556 8.20221H13.4444C14.3036 8.20221 15 8.91838 15 9.80181V15.4004C15 16.2838 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2838 1 15.4004V9.80181C1 8.91838 1.69645 8.20221 2.55556 8.20221Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22222 10.903V6.50415C5.22091 5.14055 5.70597 3.82508 6.58323 2.81312C7.46049 1.80115 8.66738 1.16489 9.96959 1.02785C11.2718 0.89081 12.5764 1.26277 13.6302 2.07151C14.684 2.88026 15.4118 4.0681 15.6722 5.40443M3.11111 10.903H17.8889C19.0548 10.903 20 11.8878 20 13.1025V20.8006C20 22.0153 19.0548 23 17.8889 23H3.11111C1.94518 23 1 22.0153 1 20.8006V13.1025C1 11.8878 1.94518 10.903 3.11111 10.903Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.88889 11.8033V7.00453C5.88737 5.51696 6.44901 4.08191 7.46479 2.97795C8.48057 1.87398 9.87802 1.17988 11.3858 1.03038C12.8937 0.880884 14.4043 1.28666 15.6245 2.16893C16.8446 3.05119 17.6873 4.34702 17.9889 5.80483M3.44444 11.8033H20.5556C21.9056 11.8033 23 12.8776 23 14.2027V22.6006C23 23.9258 21.9056 25 20.5556 25H3.44444C2.09442 25 1 23.9258 1 22.6006V14.2027C1 12.8776 2.09442 11.8033 3.44444 11.8033Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22223 14.5042V8.50566C7.22029 6.64621 7.93511 4.85238 9.22792 3.47243C10.5207 2.09248 12.2993 1.22485 14.2183 1.03798C16.1374 0.851105 18.06 1.35832 19.613 2.46116C21.1659 3.56399 22.2384 5.18377 22.6222 7.00604M4.11111 14.5042H25.8889C27.6071 14.5042 29 15.847 29 17.5034V28.0008C29 29.6572 27.6071 31 25.8889 31H4.11111C2.39289 31 1 29.6572 1 28.0008V17.5034C1 15.847 2.39289 14.5042 4.11111 14.5042Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
