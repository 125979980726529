import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.55172V7.03448C0 7.71724 0.768104 8.27586 1.7069 8.27586H4.55172V3.31034H1.7069C0.768104 3.31034 0 3.86896 0 4.55172Z"
        fill={color}
      />
      <path
        d="M9.14299 0.169564C8.90781 -0.0523342 8.62712 -0.0564437 8.38942 0.157236L4.96552 3.24737V8.75372L8.38942 11.8439C8.50575 11.9507 8.63218 12 8.75862 12C8.88506 12 9.02414 11.9466 9.14299 11.8315C9.37816 11.6055 9.51724 11.211 9.51724 10.7672V1.23385C9.51724 0.790055 9.37816 0.39557 9.14299 0.169564Z"
        fill={color}
      />
      <path
        d="M12 6.04243C12 5.12795 11.2593 4.38726 10.3448 4.38726V5.21485C10.8 5.21485 11.1724 5.58726 11.1724 6.04243C11.1724 6.49761 10.8 6.87002 10.3448 6.87002V7.69761C11.2593 7.69761 12 6.95692 12 6.04243Z"
        fill={color}
      />
      <path
        d="M10.3448 2.73209V3.55967C11.7145 3.55967 12.8276 4.67278 12.8276 6.04243C12.8276 7.41209 11.7145 8.52519 10.3448 8.52519V9.35278C12.1697 9.35278 13.6552 7.86726 13.6552 6.04243C13.6552 4.2176 12.1697 2.73209 10.3448 2.73209Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 5.31034V8.2069C0 9.00345 0.896121 9.65517 1.99138 9.65517H5.31034V3.86207H1.99138C0.896121 3.86207 0 4.51379 0 5.31034Z"
        fill={color}
      />
      <path
        d="M10.6668 0.197824C10.3924 -0.0610566 10.065 -0.065851 9.78765 0.183442L5.79309 3.78859V10.2127L9.78765 13.8178C9.92336 13.9425 10.0709 14 10.2184 14C10.3659 14 10.5281 13.9377 10.6668 13.8034C10.9412 13.5398 11.1034 13.0795 11.1034 12.5618V1.43949C11.1034 0.921731 10.9412 0.461499 10.6668 0.197824Z"
        fill={color}
      />
      <path
        d="M14 7.0495C14 5.98261 13.1359 5.11847 12.069 5.11847V6.08399C12.6 6.08399 13.0345 6.51847 13.0345 7.0495C13.0345 7.58054 12.6 8.01502 12.069 8.01502V8.98054C13.1359 8.98054 14 8.1164 14 7.0495Z"
        fill={color}
      />
      <path
        d="M12.069 3.18744V4.15295C13.6669 4.15295 14.9655 5.45157 14.9655 7.0495C14.9655 8.64744 13.6669 9.94606 12.069 9.94606V10.9116C14.1979 10.9116 15.931 9.17847 15.931 7.0495C15.931 4.92054 14.1979 3.18744 12.069 3.18744Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.06896V9.37931C0 10.2897 1.02414 11.0345 2.27586 11.0345H6.06897V4.41379H2.27586C1.02414 4.41379 0 5.15862 0 6.06896Z"
        fill={color}
      />
      <path
        d="M12.1906 0.226085C11.8771 -0.069779 11.5028 -0.0752583 11.1859 0.209647L6.62068 4.32982V11.6716L11.1859 15.7918C11.341 15.9343 11.5096 16 11.6782 16C11.8467 16 12.0322 15.9288 12.1906 15.7754C12.5042 15.474 12.6896 14.948 12.6896 14.3563V1.64513C12.6896 1.05341 12.5042 0.527427 12.1906 0.226085Z"
        fill={color}
      />
      <path
        d="M16 8.05657C16 6.83726 15.0124 5.84968 13.7931 5.84968V6.95313C14.4 6.95313 14.8966 7.44968 14.8966 8.05657C14.8966 8.66347 14.4 9.16002 13.7931 9.16002V10.2635C15.0124 10.2635 16 9.27588 16 8.05657Z"
        fill={color}
      />
      <path
        d="M13.7931 3.64278V4.74623C15.6193 4.74623 17.1035 6.23037 17.1035 8.05658C17.1035 9.88278 15.6193 11.3669 13.7931 11.3669V12.4704C16.2262 12.4704 18.2069 10.4897 18.2069 8.05658C18.2069 5.62347 16.2262 3.64278 13.7931 3.64278Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.34482V12.8965C0 14.1483 1.40819 15.1724 3.12931 15.1724H8.34483V6.06896H3.12931C1.40819 6.06896 0 7.0931 0 8.34482Z"
        fill={color}
      />
      <path
        d="M16.7621 0.310866C16.331 -0.0959461 15.8164 -0.10348 15.3806 0.288265L9.10344 5.95351V16.0485L15.3806 21.7137C15.5939 21.9096 15.8257 22 16.0575 22C16.2893 22 16.5442 21.9021 16.7621 21.6911C17.1933 21.2768 17.4483 20.5536 17.4483 19.7399V2.26206C17.4483 1.44843 17.1933 0.725213 16.7621 0.310866Z"
        fill={color}
      />
      <path
        d="M22 11.0778C22 9.40124 20.6421 8.0433 18.9655 8.0433V9.56055C19.8 9.56055 20.4828 10.2433 20.4828 11.0778C20.4828 11.9123 19.8 12.595 18.9655 12.595V14.1123C20.6421 14.1123 22 12.7543 22 11.0778Z"
        fill={color}
      />
      <path
        d="M18.9655 5.00883V6.52607C21.4765 6.52607 23.5172 8.56676 23.5172 11.0778C23.5172 13.5888 21.4765 15.6295 18.9655 15.6295V17.1468C22.311 17.1468 25.0345 14.4233 25.0345 11.0778C25.0345 7.73228 22.311 5.00883 18.9655 5.00883Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.10344V14.069C0 15.4345 1.53621 16.5517 3.41379 16.5517H9.10345V6.62069H3.41379C1.53621 6.62069 0 7.73793 0 9.10344Z"
        fill={color}
      />
      <path
        d="M18.286 0.339127C17.8156 -0.104668 17.2542 -0.112887 16.7788 0.314471L9.93103 6.49473V17.5074L16.7788 23.6877C17.0115 23.9014 17.2644 24 17.5172 24C17.7701 24 18.0483 23.8932 18.286 23.663C18.7563 23.211 19.0345 22.4221 19.0345 21.5345V2.4677C19.0345 1.58011 18.7563 0.791141 18.286 0.339127Z"
        fill={color}
      />
      <path
        d="M24 12.0849C24 10.2559 22.5186 8.77451 20.6897 8.77451V10.4297C21.6 10.4297 22.3448 11.1745 22.3448 12.0849C22.3448 12.9952 21.6 13.74 20.6897 13.74V15.3952C22.5186 15.3952 24 13.9138 24 12.0849Z"
        fill={color}
      />
      <path
        d="M20.6897 5.46417V7.11934C23.429 7.11934 25.6552 9.34555 25.6552 12.0849C25.6552 14.8242 23.429 17.0504 20.6897 17.0504V18.7056C24.3393 18.7056 27.3103 15.7345 27.3103 12.0849C27.3103 8.43521 24.3393 5.46417 20.6897 5.46417Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="37"
      height="32"
      viewBox="0 0 37 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.1379V18.7586C0 20.5793 2.04828 22.069 4.55172 22.069H12.1379V8.82758H4.55172C2.04828 8.82758 0 10.3172 0 12.1379Z"
        fill={color}
      />
      <path
        d="M24.3813 0.452169C23.7542 -0.139558 23.0057 -0.150517 22.3718 0.419295L13.2414 8.65965V23.3433L22.3718 31.5836C22.682 31.8685 23.0191 32 23.3563 32C23.6935 32 24.0644 31.8575 24.3813 31.5507C25.0084 30.948 25.3793 29.8961 25.3793 28.7126V3.29027C25.3793 2.10681 25.0084 1.05485 24.3813 0.452169Z"
        fill={color}
      />
      <path
        d="M32 16.1131C32 13.6745 30.0248 11.6994 27.5862 11.6994V13.9063C28.8 13.9063 29.7931 14.8994 29.7931 16.1131C29.7931 17.3269 28.8 18.32 27.5862 18.32V20.5269C30.0248 20.5269 32 18.5518 32 16.1131Z"
        fill={color}
      />
      <path
        d="M27.5862 7.28556V9.49246C31.2386 9.49246 34.2069 12.4607 34.2069 16.1132C34.2069 19.7656 31.2386 22.7338 27.5862 22.7338V24.9407C32.4524 24.9407 36.4138 20.9794 36.4138 16.1132C36.4138 11.2469 32.4524 7.28556 27.5862 7.28556Z"
        fill={color}
      />
    </svg>
  )
};
