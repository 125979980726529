import React from 'react';
import { concatClassNames } from '../concatClassNames';
import { INiagaraButtonProps, NiagaraButton2 } from './NiagaraButton2';
import './ActiveEffectButton.scss';

export function ActiveEffectButton({
  className,
  ...props
}: INiagaraButtonProps) {
  return (
    <NiagaraButton2
      className={concatClassNames('with-active-effect', className)}
      type="secondary-link"
      {...props}
    />
  );
}
