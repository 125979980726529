import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.10541V5.77207M1 5.77207H4.60025M1 5.77207L3.7842 3.10763C4.6128 2.26517 5.6878 1.71978 6.84722 1.55365C8.00664 1.38752 9.18767 1.60964 10.2124 2.18654C11.237 2.76345 12.0499 3.66389 12.5284 4.75219C13.0069 5.84049 13.1252 7.05769 12.8654 8.2204C12.6056 9.38311 11.9818 10.4283 11.088 11.1986C10.1942 11.9688 9.07882 12.4224 7.90992 12.4909C6.74102 12.5594 5.58191 12.2392 4.60725 11.5785C3.63259 10.9177 2.89517 9.9523 2.50611 8.82763"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.71548V6.04881M1 6.04881H5.2003M1 6.04881L4.24823 2.89993C5.21494 1.90429 6.4691 1.25974 7.82176 1.0634C9.17441 0.867064 10.5523 1.12957 11.7477 1.81137C12.9432 2.49317 13.8915 3.55732 14.4498 4.8435C15.0081 6.12967 15.146 7.56819 14.8429 8.94229C14.5398 10.3164 13.8121 11.5517 12.7693 12.462C11.7266 13.3723 10.4253 13.9083 9.06157 13.9893C7.69785 14.0702 6.34556 13.6918 5.20846 12.9109C4.07135 12.13 3.21103 10.9891 2.75712 9.65992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.77052V6.43718M1 6.43718H5.80034M1 6.43718L4.71226 3.04608C5.81707 1.97385 7.2504 1.27972 8.79629 1.06828C10.3422 0.856838 11.9169 1.13954 13.2831 1.87378C14.6494 2.60803 15.7332 3.75404 16.3712 5.13915C17.0092 6.52426 17.1669 8.07343 16.8205 9.55324C16.4741 11.033 15.6424 12.3633 14.4507 13.3437C13.2589 14.324 11.7718 14.9012 10.2132 14.9884C8.65469 15.0756 7.10921 14.6681 5.80967 13.8271C4.51012 12.9862 3.52689 11.7575 3.00814 10.3261"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.10075V8.76741M1 8.76741H7.60047M1 8.76741L6.10436 3.92297C7.62347 2.39121 9.5943 1.3996 11.7199 1.09754C13.8455 0.795483 16.0107 1.19934 17.8893 2.24826C19.7679 3.29718 21.2581 4.93435 22.1354 6.91307C23.0127 8.8918 23.2295 11.1049 22.7532 13.2189C22.2769 15.3329 21.1333 17.2333 19.4946 18.6338C17.856 20.0343 15.8112 20.8589 13.6682 20.9835C11.5252 21.108 9.40017 20.5258 7.61329 19.3245C5.82641 18.1232 4.47448 16.3678 3.7612 14.323"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.65578V9.65578M1 9.65578H8.20051M1 9.65578L6.56839 4.56911C8.22561 2.96077 10.3756 1.91958 12.6944 1.60242C15.0133 1.28526 17.3753 1.70931 19.4247 2.81067C21.4741 3.91204 23.0998 5.63106 24.0568 7.70873C25.0138 9.78639 25.2503 12.1101 24.7307 14.3299C24.2111 16.5496 22.9636 18.545 21.176 20.0155C19.3884 21.486 17.1576 22.3518 14.8198 22.4826C12.482 22.6135 10.1638 22.0021 8.2145 20.7407C6.26518 19.4793 4.79034 17.6362 4.01221 15.4891"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2.48611V11.4867M1 11.4867H10.0006M1 11.4867L7.96049 4.94629C10.032 2.87827 12.7195 1.5395 15.6181 1.13169C18.5166 0.723882 21.4692 1.26913 24.0309 2.68527C26.5926 4.10142 28.6247 6.31175 29.821 8.98322C31.0173 11.6547 31.3129 14.6426 30.6634 17.4967C30.0139 20.3508 28.4545 22.9166 26.22 24.8073C23.9855 26.6981 21.1971 27.8114 18.2748 27.9796C15.3525 28.1478 12.4548 27.3617 10.0181 25.7398C7.58147 24.1179 5.73793 21.748 4.76527 18.9873"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
