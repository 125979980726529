import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.36744 2.26512H2.19276C1.87642 2.26512 1.57304 2.39078 1.34935 2.61447C1.12567 2.83816 1 3.14154 1 3.45788V11.8072C1 12.1236 1.12567 12.427 1.34935 12.6506C1.57304 12.8743 1.87642 13 2.19276 13H10.5421C10.8585 13 11.1618 12.8743 11.3855 12.6506C11.6092 12.427 11.7349 12.1236 11.7349 11.8072V7.63256M10.8403 1.37054C11.0776 1.13329 11.3994 1 11.7349 1C12.0704 1 12.3922 1.13329 12.6295 1.37054C12.8667 1.6078 13 1.92959 13 2.26512C13 2.60065 12.8667 2.92244 12.6295 3.15969L6.96382 8.82532L4.57829 9.42171L5.17468 7.03618L10.8403 1.37054Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26201 2.47597H2.39156C2.02249 2.47597 1.66855 2.62258 1.40758 2.88355C1.14661 3.14452 1 3.49847 1 3.86753V13.6084C1 13.9775 1.14661 14.3315 1.40758 14.5924C1.66855 14.8534 2.02249 15 2.39156 15H12.1325C12.5015 15 12.8555 14.8534 13.1165 14.5924C13.3774 14.3315 13.524 13.9775 13.524 13.6084V8.73799M12.4804 1.4323C12.7572 1.1555 13.1326 1 13.524 1C13.9155 1 14.2909 1.1555 14.5677 1.4323C14.8445 1.7091 15 2.08452 15 2.47597C15 2.86742 14.8445 3.24284 14.5677 3.51964L7.95779 10.1295L5.17468 10.8253L5.87046 8.04221L12.4804 1.4323Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.15659 2.68682H2.59035C2.16857 2.68682 1.76405 2.85438 1.4658 3.15263C1.16755 3.45088 1 3.85539 1 4.27718V15.4096C1 15.8314 1.16755 16.2359 1.4658 16.5342C1.76405 16.8324 2.16857 17 2.59035 17H13.7228C14.1446 17 14.5491 16.8324 14.8474 16.5342C15.1456 16.2359 15.3132 15.8314 15.3132 15.4096V9.84341M14.1204 1.49406C14.4368 1.17772 14.8658 1 15.3132 1C15.7605 1 16.1896 1.17772 16.5059 1.49406C16.8223 1.8104 17 2.23945 17 2.68682C17 3.1342 16.8223 3.56325 16.5059 3.87959L8.95176 11.4338L5.77106 12.2289L6.56624 9.04824L14.1204 1.49406Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8403 3.31938H3.18674C2.60678 3.31938 2.05057 3.54977 1.64048 3.95986C1.23039 4.36995 1 4.92616 1 5.50612V20.8133C1 21.3932 1.23039 21.9494 1.64048 22.3595C2.05057 22.7696 2.60678 23 3.18674 23H18.4939C19.0738 23 19.63 22.7696 20.0401 22.3595C20.4502 21.9494 20.6806 21.3932 20.6806 20.8133V13.1597M19.0406 1.67933C19.4755 1.24436 20.0655 1 20.6806 1C21.2958 1 21.8857 1.24436 22.3207 1.67933C22.7556 2.1143 23 2.70424 23 3.31938C23 3.93452 22.7556 4.52447 22.3207 4.95943L11.9337 15.3464L7.56021 16.4398L8.65357 12.0663L19.0406 1.67933Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7349 3.53024H3.38553C2.75285 3.53024 2.14608 3.78157 1.69871 4.22894C1.25133 4.67631 1 5.28308 1 5.91576V22.6145C1 23.2472 1.25133 23.8539 1.69871 24.3013C2.14608 24.7487 2.75285 25 3.38553 25H20.0842C20.7169 25 21.3237 24.7487 21.7711 24.3013C22.2184 23.8539 22.4698 23.2472 22.4698 22.6145V14.2651M20.6806 1.74109C21.1551 1.26658 21.7987 1 22.4698 1C23.1408 1 23.7844 1.26658 24.2589 1.74109C24.7334 2.2156 25 2.85918 25 3.53024C25 4.2013 24.7334 4.84487 24.2589 5.31938L12.9276 16.6506L8.15659 17.8434L9.34935 13.0724L20.6806 1.74109Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4186 4.16279H3.98191C3.19106 4.16279 2.4326 4.47696 1.87338 5.03618C1.31416 5.59539 1 6.35385 1 7.14471V28.0181C1 28.8089 1.31416 29.5674 1.87338 30.1266C2.4326 30.6858 3.19106 31 3.98191 31H24.8553C25.6461 31 26.4046 30.6858 26.9638 30.1266C27.523 29.5674 27.8372 28.8089 27.8372 28.0181V17.5814M25.6008 1.92636C26.1939 1.33322 26.9984 1 27.8372 1C28.676 1 29.4805 1.33322 30.0736 1.92636C30.6668 2.5195 31 3.32397 31 4.16279C31 5.00162 30.6668 5.80609 30.0736 6.39923L15.9096 20.5633L9.94574 22.0543L11.4367 16.0904L25.6008 1.92636Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
