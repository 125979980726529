import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V9M3.66667 5.66667L7 9M7 9L10.3333 5.66667M7 9V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10.3333V13.4444C15 13.857 14.8361 14.2527 14.5444 14.5444C14.2527 14.8361 13.857 15 13.4444 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V10.3333M4.11111 6.44444L8 10.3333M8 10.3333L11.8889 6.44444M8 10.3333V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75176 0.748821C8.75111 0.334608 8.4148 -0.000649925 8.00058 9.46099e-07C7.58637 0.000651817 7.25111 0.336965 7.25176 0.751179L7.26413 8.62068L4.50335 5.8599C4.21046 5.567 3.73558 5.567 3.44269 5.8599C3.1498 6.15279 3.1498 6.62766 3.44269 6.92056L7.47142 10.9493C7.76431 11.2422 8.23919 11.2422 8.53208 10.9493L12.5608 6.92056C12.8537 6.62766 12.8537 6.15279 12.5608 5.8599C12.2679 5.567 11.793 5.567 11.5002 5.8599L8.76409 8.59595L8.75176 0.748821ZM15.2534 9.66895C15.6676 9.66895 16.0034 10.0047 16.0034 10.4189V13.6419C16.0034 14.2682 15.7546 14.8689 15.3118 15.3118C14.8689 15.7546 14.2682 16.0034 13.6419 16.0034H2.36149C1.73518 16.0034 1.13453 15.7546 0.691665 15.3118C0.248799 14.8689 -2.73576e-08 14.2682 0 13.6419L1.40863e-07 10.4189C1.59023e-07 10.0047 0.335786 9.66894 0.75 9.66894C1.16421 9.66894 1.5 10.0047 1.5 10.4189L1.5 13.6419C1.5 13.8704 1.59076 14.0895 1.75233 14.2511C1.91389 14.4127 2.13301 14.5034 2.36149 14.5034L13.6419 14.5034C13.8704 14.5034 14.0895 14.4127 14.2511 14.2511C14.4127 14.0895 14.5034 13.8704 14.5034 13.6419V10.4189C14.5034 10.0047 14.8392 9.66895 15.2534 9.66895Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7496 0.748821C11.749 0.334608 11.4127 -0.000649925 10.9985 9.46099e-07C10.5842 0.000651817 10.249 0.336965 10.2496 0.751179L10.2683 12.6242L5.83572 8.19162C5.54283 7.89873 5.06796 7.89873 4.77506 8.19162C4.48217 8.48451 4.48217 8.95939 4.77506 9.25228L10.4693 14.9465C10.7622 15.2394 11.2371 15.2394 11.53 14.9465L17.2242 9.25228C17.5171 8.95939 17.5171 8.48451 17.2242 8.19162C16.9313 7.89873 16.4564 7.89873 16.1636 8.19162L11.7682 12.5869L11.7496 0.748821ZM21.2493 13.6662C21.6635 13.6662 21.9993 14.002 21.9993 14.4162V18.9716C21.9993 19.7746 21.6803 20.5447 21.1125 21.1125C20.5447 21.6803 19.7746 21.9993 18.9716 21.9993H3.0277C2.2247 21.9993 1.4546 21.6803 0.886793 21.1125C0.318991 20.5447 -3.50992e-08 19.7746 0 18.9716L1.99128e-07 14.4162C2.17231e-07 14.002 0.335787 13.6662 0.75 13.6662C1.16421 13.6662 1.5 14.002 1.5 14.4162L1.5 18.9716C1.5 19.3768 1.66095 19.7653 1.94745 20.0518C2.23395 20.3383 2.62253 20.4993 3.0277 20.4993H18.9716C19.3768 20.4993 19.7653 20.3383 20.0518 20.0518C20.3383 19.7653 20.4993 19.3768 20.4993 18.9716V14.4162C20.4993 14.002 20.8351 13.6662 21.2493 13.6662Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0014 1C13.0014 0.447715 12.5537 0 12.0014 0C11.4491 0 11.0014 0.447715 11.0014 1V13.2542L6.59672 8.84951C6.20619 8.45899 5.57303 8.45899 5.1825 8.84951C4.79198 9.24004 4.79198 9.8732 5.1825 10.2637L11.2866 16.3679C11.3159 16.3977 11.3469 16.4257 11.3796 16.4518C11.4398 16.4996 11.5041 16.5395 11.5713 16.5716C11.7016 16.6337 11.8474 16.6685 12.0014 16.6685C12.1273 16.6685 12.2478 16.6452 12.3588 16.6028C12.4863 16.5541 12.6058 16.4784 12.7086 16.3756L18.8205 10.2637C19.211 9.8732 19.211 9.24004 18.8205 8.84951C18.4299 8.45899 17.7968 8.45899 17.4063 8.84951L13.0014 13.2544V1ZM23.0028 14.6685C23.555 14.6685 24.0028 15.1162 24.0028 15.6685V20.558C24.0028 21.4716 23.6398 22.3478 22.9938 22.9938C22.3478 23.6398 21.4716 24.0028 20.558 24.0028H3.44475C2.53114 24.0028 1.65496 23.6398 1.00894 22.9938C0.362928 22.3478 -3.99305e-08 21.4716 0 20.558L2.13739e-07 15.6685C2.37837e-07 15.1162 0.447714 14.6685 1 14.6685C1.55228 14.6685 2 15.1162 2 15.6685L2 20.558C2 20.9412 2.15221 21.3087 2.42316 21.5796C2.6941 21.8505 3.06158 22.0028 3.44475 22.0028H20.558C20.9412 22.0028 21.3087 21.8505 21.5796 21.5796C21.8505 21.3087 22.0028 20.9412 22.0028 20.558V15.6685C22.0028 15.1162 22.4505 14.6685 23.0028 14.6685Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 1.99811C17 1.44582 16.5523 0.998108 16 0.998108C15.4477 0.998108 15 1.44582 15 1.99811V18.253L8.92837 12.1813C8.53785 11.7908 7.90468 11.7908 7.51416 12.1813C7.12363 12.5719 7.12363 13.205 7.51416 13.5956L15.2842 21.3656C15.3634 21.4467 15.4562 21.5144 15.559 21.565C15.692 21.6305 15.8417 21.6673 16 21.6673C16.0192 21.6673 16.0382 21.6667 16.0571 21.6657C16.156 21.6601 16.251 21.6402 16.3402 21.6079C16.4741 21.5596 16.5998 21.4817 16.7072 21.3744L24.486 13.5956C24.8765 13.205 24.8765 12.5719 24.486 12.1813C24.0955 11.7908 23.4623 11.7908 23.0718 12.1813L17 18.2532V1.99811ZM30.0019 19.6673C30.5542 19.6673 31.0019 20.115 31.0019 20.6673V26.8904C31.0019 27.9808 30.5688 29.0266 29.7977 29.7976C29.0266 30.5687 27.9809 31.0019 26.8904 31.0019L5.1097 31.0019C4.01925 31.0019 2.97347 30.5687 2.20241 29.7976C1.43135 29.0266 0.998169 27.9808 0.998169 26.8904L0.998169 20.6673C0.998169 20.115 1.44589 19.6673 1.99817 19.6673C2.55046 19.6673 2.99817 20.115 2.99817 20.6673L2.99817 26.8904C2.99817 27.4504 3.22063 27.9874 3.61662 28.3834C4.01261 28.7794 4.54969 29.0019 5.1097 29.0019L26.8904 29.0019C27.4504 29.0019 27.9875 28.7794 28.3835 28.3834C28.7795 27.9874 29.0019 27.4504 29.0019 26.8904V20.6673C29.0019 20.115 29.4496 19.6673 30.0019 19.6673Z"
        fill={color}
      />
    </svg>
  )
};
