import React, { useState, useEffect, ReactNode } from 'react';
import { concatClassNames } from '../concatClassNames';
import { DataTable } from '@scuf/datatable';
import { IDataTableProps } from '@scuf/datatable/dist/components/DataTable/IDataTableProps';
import NiagaraPagination, {
  INiagaraPaginationProps
} from '../NiagaraPagination/NiagaraPagination';
import './NiagaraDataTable.scss';

interface INiagaraTableProps extends IDataTableProps {
  className?: string;
  paginationSettings: INiagaraPaginationProps;
  enableLazyPagination?: boolean;
  actionBar?: ReactNode;
  showItemCountAtBottom?: boolean;
  tableName?: string;
}

export const NiagaraDataTable: React.FC<INiagaraTableProps> = ({
  className,
  paginationSettings,
  enableLazyPagination,
  children,
  actionBar,
  showItemCountAtBottom,
  tableName,
  ...tableProps
}) => {
  const [activePage, setActivePage] = useState(
    paginationSettings.activePage || 1
  );
  const { itemsPerPage, totalItems } = paginationSettings;
  const displayPaginationWidget = totalItems > itemsPerPage;

  const handlePageChange = (
    currentPage: string | number,
    currentItemsPerPage: string | number | undefined
  ) => {
    setActivePage(currentPage);
    if (paginationSettings.onPageChange)
      paginationSettings.onPageChange(currentPage, currentItemsPerPage);
  };

  useEffect(() => {
    setActivePage(paginationSettings.activePage || 1);
  }, [paginationSettings.activePage]);

  const firstItemIndex = (parseInt(activePage.toString()) - 1) * itemsPerPage;
  const lastItemIndex = firstItemIndex + itemsPerPage;
  const lastValidItemIndex =
    lastItemIndex > totalItems ? totalItems : lastItemIndex;
  const firstItemDisplayIndex = totalItems === 0 ? 0 : firstItemIndex + 1;
  const lastItemDisplayIndex = lastValidItemIndex;

  const itemCountDisplay =
    totalItems <= itemsPerPage
      ? `${totalItems} ${tableName ? tableName : 'Result'}${
          totalItems !== 1 ? 's' : ''
        }`
      : `${firstItemDisplayIndex}-${lastItemDisplayIndex} of ${totalItems} ${
          tableName ? tableName + 's' : ''
        }`;

  return (
    <div className={concatClassNames(className, 'niagara-data-table')}>
      {(!showItemCountAtBottom || actionBar) && (
        <div className="item-count-row">
          {!showItemCountAtBottom && (
            <span className="item-count-display">{itemCountDisplay}</span>
          )}
          {actionBar && (
            <div
              className={
                'action-bar ' + (!showItemCountAtBottom && 'top-bar-item-count')
              }
            >
              {actionBar}
            </div>
          )}
        </div>
      )}
      <DataTable
        scrollable={true}
        scrollHeight="1px"
        {...tableProps}
        data={
          enableLazyPagination
            ? tableProps.data
            : tableProps.data.slice(firstItemIndex, lastValidItemIndex)
        }
      >
        {children}
      </DataTable>
      {displayPaginationWidget && (
        <NiagaraPagination
          {...paginationSettings}
          activePage={activePage}
          onPageChange={handlePageChange}
          showItemCountAtBottom={showItemCountAtBottom}
          itemCountDisplay={itemCountDisplay}
        />
      )}
    </div>
  );
};
