import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.49368V7.04996C12.9993 8.35384 12.5803 9.62255 11.8056 10.6669C11.0309 11.7112 9.94197 12.4752 8.70121 12.8449C7.90066 13.0834 6.54999 13.0174 5.70831 12.8449M1.01301 6.64866C0.927875 7.94972 1.26236 9.24365 1.96658 10.3375C2.3003 10.8558 2.62827 11.2491 3.17499 11.705M2.43897 3.11797C3.27961 2.12679 4.41567 1.43602 5.67771 1.14867C6.93975 0.861314 8.26016 0.992781 9.442 1.52346M13 2.21276L7 8.26531L5.2 6.45136"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7.40929V8.05829C14.9991 9.57948 14.5103 11.0596 13.6065 12.278C12.7027 13.4964 11.4323 14.3877 9.98475 14.819C9.05077 15.0973 7.47499 15.0203 6.49303 14.819M1.01517 7.59011C0.915854 9.108 1.30609 10.6176 2.12767 11.8937C2.51702 12.4985 2.89965 12.9573 3.53749 13.4892M2.6788 3.47096C3.65955 2.31459 4.98495 1.50869 6.45733 1.17344C7.92971 0.838199 9.47018 0.991578 10.849 1.6107M15 2.41489L8 9.47619L5.9 7.35992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.32491V9.06661C16.999 10.8051 16.4404 12.4967 15.4075 13.8892C14.3745 15.2816 12.9226 16.3002 11.2683 16.7932C10.2009 17.1112 8.39999 17.0232 7.27775 16.7932M1.01734 8.53155C0.903833 10.2663 1.34981 11.9915 2.28877 13.45C2.73374 14.1411 3.17103 14.6655 3.89999 15.2734M2.91862 3.82395C4.03948 2.50239 5.55423 1.58136 7.23695 1.19822C8.91967 0.815085 10.6802 0.990375 12.256 1.69795M17 2.61701L9 10.6871L6.6 8.26847"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 11.0717V12.0916C22.9986 14.482 22.2305 16.808 20.8103 18.7226C19.39 20.6372 17.3936 22.0378 15.1189 22.7156C13.6512 23.1529 11.175 23.0319 9.63191 22.7156M1.02384 11.3559C0.86777 13.7411 1.48099 16.1134 2.77206 18.1187C3.38389 19.0691 3.98516 19.7901 4.98748 20.6259M3.63811 4.88294C5.17929 3.06578 7.26206 1.79936 9.57581 1.27255C11.8895 0.745742 14.3103 0.986765 16.477 1.95968M23 3.22339L12 14.3197L8.7 10.9942"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 11.9874V13.0999C24.9985 15.7077 24.1606 18.2451 22.6112 20.3337C21.0618 22.4224 18.8839 23.9503 16.4024 24.6897C14.8013 25.1668 12.1 25.0348 10.4166 24.6897M1.02601 12.2973C0.855749 14.8994 1.52472 17.4873 2.93315 19.675C3.60061 20.7117 4.25654 21.4983 5.34998 22.41M3.87794 5.23593C5.55922 3.25358 7.83134 1.87203 10.3554 1.29733C12.8795 0.722627 15.5203 0.985562 17.884 2.04692M25 3.42552L13 15.5306L9.4 11.9027"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 14.7342V16.1249C30.9982 19.3846 29.9507 22.5564 28.014 25.1672C26.0772 27.778 23.3549 29.6879 20.253 30.6122C18.2516 31.2085 14.875 31.0435 12.7708 30.6122M1.03252 15.1217C0.819687 18.3743 1.6559 21.6091 3.41644 24.3437C4.25076 25.6396 5.07068 26.6228 6.43748 27.7625M4.59742 6.29491C6.69903 3.81698 9.53918 2.09004 12.6943 1.37166C15.8494 0.653284 19.1504 0.981952 22.105 2.30865M31 4.0319L16 19.1633L11.5 14.6284"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
