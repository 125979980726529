import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66667 8.33333H5.66667M5.66667 8.33333V12.3333M5.66667 8.33333L1 13M12.3333 5.66667H8.33333M8.33333 5.66667V1.66667M8.33333 5.66667L13 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77778 9.55556H6.44444M6.44444 9.55556V14.2222M6.44444 9.55556L1 15M14.2222 6.44444H9.55556M9.55556 6.44444V1.77778M9.55556 6.44444L15 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.88889 10.7778H7.22222M7.22222 10.7778V16.1111M7.22222 10.7778L1 17M16.1111 7.22222H10.7778M10.7778 7.22222V1.88889M10.7778 7.22222L17 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22222 14.4444H9.55556M9.55556 14.4444V21.7778M9.55556 14.4444L1 23M21.7778 9.55556H14.4444M14.4444 9.55556V2.22222M14.4444 9.55556L23 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33333 15.6667H10.3333M10.3333 15.6667V23.6667M10.3333 15.6667L1 25M23.6667 10.3333H15.6667M15.6667 10.3333V2.33333M15.6667 10.3333L25 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 19.3333H12.6667M12.6667 19.3333V29.3333M12.6667 19.3333L1 31M29.3333 12.6667H19.3333M19.3333 12.6667V2.66667M19.3333 12.6667L31 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
