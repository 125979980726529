import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.24 10.24C9.3076 10.9507 8.17224 11.3445 7 11.3636C3.18182 11.3636 1 7 1 7C1.67849 5.73558 2.61953 4.63088 3.76 3.76M5.85455 2.76727C6.23 2.67939 6.6144 2.63546 7 2.63636C10.8182 2.63636 13 7 13 7C12.6689 7.61942 12.274 8.20258 11.8218 8.74M8.15636 8.15636C8.00656 8.31713 7.8259 8.44608 7.62517 8.53552C7.42445 8.62496 7.20776 8.67305 6.98805 8.67693C6.76833 8.6808 6.55009 8.64039 6.34633 8.55809C6.14258 8.47578 5.95748 8.35329 5.8021 8.1979C5.64671 8.04251 5.52421 7.85742 5.44191 7.65367C5.35961 7.44991 5.3192 7.23167 5.32307 7.01195C5.32695 6.79223 5.37504 6.57555 5.46448 6.37483C5.55392 6.1741 5.68287 5.99344 5.84364 5.84364M1 1L13 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.78 11.78C10.6922 12.6092 9.36761 13.0685 8 13.0909C3.54545 13.0909 1 8 1 8C1.79157 6.52484 2.88945 5.23602 4.22 4.22M6.66364 3.06182C7.10167 2.95929 7.55013 2.90803 8 2.90909C12.4545 2.90909 15 8 15 8C14.6137 8.72266 14.153 9.40301 13.6255 10.03M9.34909 9.34909C9.17431 9.53666 8.96355 9.6871 8.72937 9.79144C8.49519 9.89578 8.24239 9.95189 7.98606 9.95641C7.72972 9.96094 7.4751 9.91378 7.23739 9.81777C6.99967 9.72175 6.78373 9.57884 6.60245 9.39755C6.42116 9.21627 6.27825 9.00033 6.18223 8.76261C6.08622 8.5249 6.03906 8.27028 6.04358 8.01394C6.04811 7.75761 6.10421 7.50481 6.20856 7.27063C6.3129 7.03645 6.46334 6.82568 6.65091 6.65091M1 1L15 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.32 13.32C12.0768 14.2676 10.563 14.7926 9 14.8182C3.90909 14.8182 1 9 1 9C1.90465 7.3141 3.15937 5.84117 4.68 4.68M7.47273 3.35636C7.97333 3.23918 8.48586 3.18061 9 3.18182C14.0909 3.18182 17 9 17 9C16.5585 9.8259 16.032 10.6034 15.4291 11.32M10.5418 10.5418C10.3421 10.7562 10.1012 10.9281 9.83357 11.0474C9.56593 11.1666 9.27702 11.2307 8.98407 11.2359C8.69111 11.2411 8.40012 11.1872 8.12844 11.0774C7.85677 10.9677 7.60998 10.8044 7.4028 10.5972C7.19562 10.39 7.03229 10.1432 6.92255 9.87156C6.81282 9.59988 6.75893 9.30889 6.7641 9.01593C6.76927 8.72298 6.83339 8.43407 6.95264 8.16643C7.07189 7.8988 7.24382 7.65793 7.45818 7.45818M1 1L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68189 3.96914 7.65661 6.06 6.06M9.9 4.24C10.5883 4.07888 11.2931 3.99834 12 4C19 4 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19M14.12 14.12C13.8454 14.4147 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99262 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2218 9.18488 10.8538C9.34884 10.4858 9.58525 10.1546 9.88 9.88M1 1L23 23"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.48 19.48C17.6152 20.9015 15.3445 21.6889 13 21.7273C5.36364 21.7273 1 13 1 13C2.35697 10.4712 4.23906 8.26175 6.52 6.52M10.7091 4.53454C11.46 4.35878 12.2288 4.27091 13 4.27273C20.6364 4.27273 25 13 25 13C24.3378 14.2388 23.5481 15.4052 22.6436 16.48M15.3127 15.3127C15.0131 15.6343 14.6518 15.8922 14.2503 16.071C13.8489 16.2499 13.4155 16.3461 12.9761 16.3539C12.5367 16.3616 12.1002 16.2808 11.6927 16.1162C11.2852 15.9516 10.915 15.7066 10.6042 15.3958C10.2934 15.085 10.0484 14.7148 9.88383 14.3073C9.71923 13.8998 9.63839 13.4633 9.64615 13.0239C9.6539 12.5845 9.75008 12.1511 9.92896 11.7497C10.1078 11.3482 10.3657 10.9869 10.6873 10.6873M1 1L25 25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1 24.1C21.769 25.8768 18.9306 26.8612 16 26.9091C6.45455 26.9091 1 16 1 16C2.69621 12.8389 5.04882 10.0772 7.9 7.9M13.1364 5.41818C14.075 5.19847 15.036 5.08864 16 5.09091C25.5455 5.09091 31 16 31 16C30.1722 17.5486 29.1851 19.0065 28.0545 20.35M18.8909 18.8909C18.5164 19.2928 18.0648 19.6152 17.5629 19.8388C17.0611 20.0624 16.5194 20.1826 15.9701 20.1923C15.4208 20.202 14.8752 20.101 14.3658 19.8952C13.8564 19.6895 13.3937 19.3832 13.0052 18.9948C12.6168 18.6063 12.3105 18.1436 12.1048 17.6342C11.899 17.1248 11.798 16.5792 11.8077 16.0299C11.8174 15.4806 11.9376 14.9389 12.1612 14.4371C12.3848 13.9352 12.7072 13.4836 13.1091 13.1091M1 1L31 31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
