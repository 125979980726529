import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91611 7.906L9.09 10.294M9.08389 3.706L4.91611 6.094M12.5 2.8C12.5 3.79411 11.6792 4.6 10.6667 4.6C9.65414 4.6 8.83333 3.79411 8.83333 2.8C8.83333 1.80589 9.65414 1 10.6667 1C11.6792 1 12.5 1.80589 12.5 2.8ZM5.16667 7C5.16667 7.99411 4.34586 8.8 3.33333 8.8C2.32081 8.8 1.5 7.99411 1.5 7C1.5 6.00589 2.32081 5.2 3.33333 5.2C4.34586 5.2 5.16667 6.00589 5.16667 7ZM12.5 11.2C12.5 12.1941 11.6792 13 10.6667 13C9.65414 13 8.83333 12.1941 8.83333 11.2C8.83333 10.2059 9.65414 9.4 10.6667 9.4C11.6792 9.4 12.5 10.2059 12.5 11.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03722 9.057L9.97 11.843M9.96278 4.157L5.03722 6.943M14 3.1C14 4.2598 13.03 5.2 11.8333 5.2C10.6367 5.2 9.66667 4.2598 9.66667 3.1C9.66667 1.9402 10.6367 1 11.8333 1C13.03 1 14 1.9402 14 3.1ZM5.33333 8C5.33333 9.1598 4.36328 10.1 3.16667 10.1C1.97005 10.1 1 9.1598 1 8C1 6.8402 1.97005 5.9 3.16667 5.9C4.36328 5.9 5.33333 6.8402 5.33333 8ZM14 12.9C14 14.0598 13.03 15 11.8333 15C10.6367 15 9.66667 14.0598 9.66667 12.9C9.66667 11.7402 10.6367 10.8 11.8333 10.8C13.03 10.8 14 11.7402 14 12.9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34778 10.208L10.66 13.392M10.6522 4.608L5.34778 7.792M15 3.4C15 4.72548 13.9553 5.8 12.6667 5.8C11.378 5.8 10.3333 4.72548 10.3333 3.4C10.3333 2.07452 11.378 1 12.6667 1C13.9553 1 15 2.07452 15 3.4ZM5.66667 9C5.66667 10.3255 4.622 11.4 3.33333 11.4C2.04467 11.4 1 10.3255 1 9C1 7.67452 2.04467 6.6 3.33333 6.6C4.622 6.6 5.66667 7.67452 5.66667 9ZM15 14.6C15 15.9255 13.9553 17 12.6667 17C11.378 17 10.3333 15.9255 10.3333 14.6C10.3333 13.2745 11.378 12.2 12.6667 12.2C13.9553 12.2 15 13.2745 15 14.6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21111 13.661L14.8 18.039M14.7889 5.961L7.21111 10.339M21 4.3C21 6.12254 19.5076 7.6 17.6667 7.6C15.8257 7.6 14.3333 6.12254 14.3333 4.3C14.3333 2.47746 15.8257 1 17.6667 1C19.5076 1 21 2.47746 21 4.3ZM7.66667 12C7.66667 13.8225 6.17428 15.3 4.33333 15.3C2.49238 15.3 1 13.8225 1 12C1 10.1775 2.49238 8.7 4.33333 8.7C6.17428 8.7 7.66667 10.1775 7.66667 12ZM21 19.7C21 21.5225 19.5076 23 17.6667 23C15.8257 23 14.3333 21.5225 14.3333 19.7C14.3333 17.8775 15.8257 16.4 17.6667 16.4C19.5076 16.4 21 17.8775 21 19.7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.02167 14.812L15.99 19.588M15.9783 6.412L8.02167 11.188M22.5 4.6C22.5 6.58823 20.933 8.2 19 8.2C17.067 8.2 15.5 6.58823 15.5 4.6C15.5 2.61177 17.067 1 19 1C20.933 1 22.5 2.61177 22.5 4.6ZM8.5 13C8.5 14.9882 6.933 16.6 5 16.6C3.067 16.6 1.5 14.9882 1.5 13C1.5 11.0118 3.067 9.4 5 9.4C6.933 9.4 8.5 11.0118 8.5 13ZM22.5 21.4C22.5 23.3882 20.933 25 19 25C17.067 25 15.5 23.3882 15.5 21.4C15.5 19.4118 17.067 17.8 19 17.8C20.933 17.8 22.5 19.4118 22.5 21.4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.385 18.265L19.63 24.235M19.615 7.765L9.385 13.735M28 5.5C28 7.98528 25.9853 10 23.5 10C21.0147 10 19 7.98528 19 5.5C19 3.01472 21.0147 1 23.5 1C25.9853 1 28 3.01472 28 5.5ZM10 16C10 18.4853 7.98528 20.5 5.5 20.5C3.01472 20.5 1 18.4853 1 16C1 13.5147 3.01472 11.5 5.5 11.5C7.98528 11.5 10 13.5147 10 16ZM28 26.5C28 28.9853 25.9853 31 23.5 31C21.0147 31 19 28.9853 19 26.5C19 24.0147 21.0147 22 23.5 22C25.9853 22 28 24.0147 28 26.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
