import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H13M1 5H9M1 9H5M1 13H3M11 8.33333V13.6667M11 13.6667L13.6667 11M11 13.6667L8.33333 11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H15.2105M1 5.73684H10.4737M1 10.4737H5.73684M1 15.2105H3.36842M12.8421 9.68421V16M12.8421 16L16 12.8421M12.8421 16L9.68421 12.8421"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H21M1 7H15M1 13H9M1 19H5M17 12V20M17 20L21 16M17 20L13 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H24M1 7.9H17.1M1 14.8H10.2M1 21.7H5.6M19.4 13.65V22.85M19.4 22.85L24 18.25M19.4 22.85L14.8 18.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H29M1 9H23M1 17H17M1 25H13M23 15V26M28.6666 21L23.1666 27L17.6666 21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H31M1 9.57143H24.5714M1 18.1429H18.1429M1 26.7143H13.8571M24.5714 16V27.7857M30.6428 22.4286L24.75 28.8571L18.8571 22.4286"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
