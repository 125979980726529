import React from 'react';

export default {
  10: ({ color }: { color: string }) => (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="1" r="0.5" stroke={color} />
      <circle cx="5" cy="1" r="0.5" stroke={color} />
      <circle cx="8.5" cy="1" r="0.5" stroke={color} />
    </svg>
  ),
  12: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="0.999969" r="0.5" stroke={color} />
      <circle cx="6" cy="0.999969" r="0.5" stroke={color} />
      <circle cx="10.5" cy="0.999969" r="0.5" stroke={color} />
    </svg>
  ),
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="4"
      viewBox="0 0 14 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="0.75" stroke={color} strokeWidth="1.5" />
      <circle cx="7" cy="2" r="0.75" stroke={color} strokeWidth="1.5" />
      <circle cx="12" cy="2" r="0.75" stroke={color} strokeWidth="1.5" />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.04993"
        cy="2.05002"
        r="0.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="8.04993"
        cy="2.05002"
        r="0.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="14.0499"
        cy="2.05002"
        r="0.75"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="1" stroke={color} strokeWidth="2" />
      <circle cx="9" cy="2" r="1" stroke={color} strokeWidth="2" />
      <circle cx="16" cy="2" r="1" stroke={color} strokeWidth="2" />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="4"
      viewBox="0 0 24 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="1" stroke={color} strokeWidth="2" />
      <circle cx="12" cy="2" r="1" stroke={color} strokeWidth="2" />
      <circle cx="22" cy="2" r="1" stroke={color} strokeWidth="2" />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="6"
      viewBox="0 0 26 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="3" r="1.25" stroke={color} strokeWidth="2.5" />
      <circle cx="13" cy="3" r="1.25" stroke={color} strokeWidth="2.5" />
      <circle cx="23.5" cy="3" r="1.25" stroke={color} strokeWidth="2.5" />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="31"
      height="6"
      viewBox="0 0 31 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.54993"
        cy="3.04999"
        r="1.25"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="15.0499"
        cy="3.04999"
        r="1.25"
        stroke={color}
        strokeWidth="2.5"
      />
      <circle
        cx="27.5499"
        cy="3.04999"
        r="1.25"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  )
};
