import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.94316 3.74416C5.89846 2.3922 7.1962 0.569909 7.1962 0.569909C7.1962 0.569909 7.57793 0 8.07298 0C8.18453 0 8.29926 0.0377304 8.41717 0.113191C8.56985 0.241375 8.87193 0.569909 8.87193 0.569909L10.8132 2.5411L11.8061 3.55627C11.8061 3.55627 11.9553 3.70796 11.9886 3.87855C12.0219 4.04914 11.9765 4.23997 11.9271 4.3331C11.8776 4.42624 11.761 4.61685 11.6093 4.72823C11.4577 4.83961 10.1297 5.74262 10.1297 5.74262L8.26399 7.02313C8.26399 7.02313 8.43885 7.66455 8.38216 8.43816C8.34879 8.89374 8.2471 9.3534 8.07355 9.79051C8.06607 9.80935 2.2506 3.93648 2.2506 3.93648C2.89806 3.67135 3.83817 3.47987 4.94316 3.74416Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18712 7.36801L0.48647 11.9271C0.48647 11.9271 0.388153 12.0362 0.171065 11.9875C0.052769 11.945 0 11.8207 0 11.7226C0 11.5868 0.0996346 11.488 0.140343 11.4327C0.239345 11.2981 1.1426 10.1488 1.1426 10.1488L4.55942 5.78922L6.18712 7.36801Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76702 4.36819C6.88154 2.7909 8.39557 0.664894 8.39557 0.664894C8.39557 0.664894 8.84092 0 9.41847 0C9.54862 0 9.68247 0.0440187 9.82003 0.132056C9.99815 0.281604 10.3506 0.664894 10.3506 0.664894L12.6154 2.96462L13.7738 4.14898C13.7738 4.14898 13.9478 4.32595 13.9867 4.52497C14.0255 4.72399 13.9725 4.94663 13.9149 5.05529C13.8573 5.16394 13.7212 5.38633 13.5442 5.51627C13.3673 5.64622 11.818 6.69972 11.818 6.69972L9.64132 8.19366C9.64132 8.19366 9.84532 8.94197 9.77919 9.84452C9.74025 10.376 9.62162 10.9123 9.41915 11.4223C9.41041 11.4442 2.62569 4.59256 2.62569 4.59256C3.38108 4.28324 4.47786 4.05985 5.76702 4.36819Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21831 8.59601L0.567549 13.9149C0.567549 13.9149 0.452845 14.0422 0.199575 13.9854C0.0615638 13.9359 0 13.7909 0 13.6764C0 13.5179 0.11624 13.4027 0.163733 13.3381C0.279236 13.1811 1.33303 11.8402 1.33303 11.8402L5.31932 6.75409L7.21831 8.59601Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59088 4.99222C7.86462 3.1896 9.59494 0.759879 9.59494 0.759879C9.59494 0.759879 10.1039 0 10.764 0C10.9127 0 11.0657 0.0503071 11.2229 0.150921C11.4265 0.321834 11.8292 0.759879 11.8292 0.759879L14.4177 3.38813L15.7414 4.7417C15.7414 4.7417 15.9404 4.94395 15.9848 5.1714C16.0292 5.39885 15.9686 5.65329 15.9027 5.77747C15.8369 5.90165 15.6813 6.1558 15.4791 6.30431C15.2769 6.45282 13.5063 7.65683 13.5063 7.65683L11.0187 9.36418C11.0187 9.36418 11.2518 10.2194 11.1762 11.2509C11.1317 11.8583 10.9961 12.4712 10.7647 13.054C10.7548 13.0791 3.00079 5.24864 3.00079 5.24864C3.86409 4.89513 5.11756 4.63982 6.59088 4.99222Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2495 9.82401L0.648627 15.9028C0.648627 15.9028 0.517537 16.0483 0.228086 15.9833C0.0703587 15.9267 0 15.761 0 15.6302C0 15.449 0.132846 15.3174 0.187123 15.2436C0.319127 15.0641 1.52346 13.5317 1.52346 13.5317L6.07923 7.71896L8.2495 9.82401Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06246 6.8643C10.8138 4.3857 13.193 1.04483 13.193 1.04483C13.193 1.04483 13.8929 0 14.8005 0C15.005 0 15.2153 0.0691723 15.4315 0.207517C15.7114 0.442521 16.2652 1.04483 16.2652 1.04483L19.8243 4.65868L21.6445 6.51983C21.6445 6.51983 21.918 6.79793 21.9791 7.11067C22.0402 7.42342 21.9568 7.77328 21.8663 7.94402C21.7757 8.11477 21.5618 8.46423 21.2838 8.66843C21.0057 8.87263 18.5711 10.5281 18.5711 10.5281L15.1507 12.8757C15.1507 12.8757 15.4712 14.0517 15.3673 15.47C15.3061 16.3052 15.1197 17.1479 14.8015 17.9493C14.7878 17.9838 4.12609 7.21687 4.12609 7.21687C5.31312 6.73081 7.03664 6.37976 9.06246 6.8643Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3431 13.508L0.891862 21.8663C0.891862 21.8663 0.711614 22.0664 0.313619 21.977C0.0967432 21.8992 0 21.6714 0 21.4915C0 21.2424 0.182664 21.0614 0.257295 20.9599C0.4388 20.7132 2.09476 18.6061 2.09476 18.6061L8.35894 10.6136L11.3431 13.508Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88632 7.48833C11.7969 4.7844 14.3924 1.13982 14.3924 1.13982C14.3924 1.13982 15.1559 0 16.146 0C16.3691 0 16.5985 0.0754607 16.8343 0.226382C17.1397 0.48275 17.7439 1.13982 17.7439 1.13982L21.6265 5.0822L23.6121 7.11254C23.6121 7.11254 23.9106 7.41592 23.9772 7.7571C24.0438 8.09828 23.9529 8.47994 23.8541 8.66621C23.7553 8.85247 23.522 9.2337 23.2187 9.45646C22.9153 9.67923 20.2594 11.4852 20.2594 11.4852L16.528 14.0463C16.528 14.0463 16.8777 15.3291 16.7643 16.8763C16.6976 17.7875 16.4942 18.7068 16.1471 19.581C16.1321 19.6187 4.50119 7.87295 4.50119 7.87295C5.79613 7.3427 7.67634 6.95974 9.88632 7.48833Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3742 14.736L0.97294 23.8541C0.97294 23.8541 0.776306 24.0724 0.342129 23.9749C0.105538 23.8901 0 23.6415 0 23.4453C0 23.1735 0.199269 22.9761 0.280685 22.8654C0.478691 22.5962 2.28519 20.2975 2.28519 20.2975L9.11884 11.5784L12.3742 14.736Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3579 9.36041C14.7462 5.9805 17.9905 1.42477 17.9905 1.42477C17.9905 1.42477 18.9448 0 20.1824 0C20.4613 0 20.7481 0.0943259 21.0429 0.282978C21.4246 0.603438 22.1798 1.42477 22.1798 1.42477L27.0331 6.35275L29.5152 8.89068C29.5152 8.89068 29.8882 9.2699 29.9715 9.69637C30.0548 10.1228 29.9411 10.5999 29.8176 10.8328C29.6941 11.0656 29.4025 11.5421 29.0233 11.8206C28.6442 12.099 25.3242 14.3565 25.3242 14.3565L20.66 17.5578C20.66 17.5578 21.0971 19.1614 20.9554 21.0954C20.872 22.2343 20.6178 23.3835 20.1839 24.4763C20.1652 24.5234 5.62649 9.84119 5.62649 9.84119C7.24516 9.17838 9.59542 8.69967 12.3579 9.36041Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4678 18.42L1.21618 29.8177C1.21618 29.8177 0.970383 30.0905 0.427662 29.9687C0.131922 29.8626 0 29.5519 0 29.3066C0 28.9669 0.249087 28.7201 0.350857 28.5817C0.598363 28.2452 2.85649 25.3719 2.85649 25.3719L11.3986 14.4731L15.4678 18.42Z"
        fill={color}
      />
    </svg>
  )
};
