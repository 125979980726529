import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.37124 1.32878C5.95388 1.18842 6.56041 1.20749 7.13425 1.38422C7.70808 1.56095 8.23052 1.88957 8.65281 2.33942C8.6571 2.34399 8.66146 2.34848 8.66588 2.35291L9.9781 3.66576H8.3241C8.00355 3.66576 7.74369 3.94244 7.74369 4.28374C7.74369 4.62504 8.00355 4.90172 8.3241 4.90172H11.4196C11.7401 4.90172 12 4.62504 12 4.28374V0.987868C12 0.64657 11.7401 0.369892 11.4196 0.369892C11.099 0.369892 10.8392 0.64657 10.8392 0.987868V2.83128L9.46718 1.45861C8.90744 0.864724 8.21598 0.43075 7.45679 0.196939C6.69459 -0.0378002 5.88896 -0.0631338 5.11507 0.123302C4.34119 0.309738 3.62427 0.701866 3.03122 1.2631C2.43816 1.82433 1.9883 2.53637 1.72361 3.33279C1.61668 3.65453 1.77496 4.00766 2.07715 4.12152C2.37933 4.23537 2.71099 4.06684 2.81793 3.7451C3.0172 3.1455 3.35589 2.60943 3.80238 2.1869C4.24887 1.76436 4.78861 1.46914 5.37124 1.32878Z"
        fill={color}
      />
      <path
        d="M1.16081 6.86019V8.3292C1.16081 8.6705 0.900955 8.94718 0.580406 8.94718C0.259857 8.94718 0 8.6705 0 8.3292V5.40709C0 5.06579 0.259857 4.78911 0.580406 4.78911H3.32487C3.64542 4.78911 3.90528 5.06579 3.90528 5.40709C3.90528 5.74838 3.64542 6.02506 3.32487 6.02506H2.02125L3.6425 7.64709C3.64692 7.65152 3.65128 7.65601 3.65557 7.66058C4.07786 8.11043 4.6003 8.43905 5.17413 8.61578C5.74797 8.79251 6.3545 8.81158 6.93714 8.67122C7.51977 8.53086 8.05951 8.23564 8.506 7.8131C8.95249 7.39057 9.29118 6.8545 9.49045 6.2549C9.59739 5.93316 9.92905 5.76463 10.2312 5.87848C10.5334 5.99234 10.6917 6.34547 10.5848 6.66721C10.3201 7.46363 9.87022 8.17567 9.27716 8.7369C8.68411 9.29813 7.9672 9.69026 7.19331 9.8767C6.41942 10.0631 5.61379 10.0378 4.85159 9.80306C4.0924 9.56925 3.40094 9.13528 2.8412 8.54139L1.16081 6.86019Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26645 1.46166C6.94619 1.30726 7.65381 1.32824 8.32329 1.52264C8.99276 1.71704 9.60228 2.07853 10.0949 2.57336C10.1 2.57839 10.105 2.58333 10.1102 2.5882L11.6411 4.03234H9.71144C9.33747 4.03234 9.0343 4.33669 9.0343 4.71211C9.0343 5.08754 9.33747 5.39189 9.71144 5.39189H13.3229C13.6968 5.39189 14 5.08754 14 4.71211V1.08666C14 0.711227 13.6968 0.406882 13.3229 0.406882C12.9489 0.406882 12.6457 0.711227 12.6457 1.08666V3.11441L11.045 1.60447C10.392 0.951196 9.58531 0.473825 8.69959 0.216633C7.81035 -0.0415802 6.87045 -0.0694472 5.96758 0.135632C5.06472 0.340711 4.22831 0.772053 3.53642 1.38941C2.84452 2.00676 2.31969 2.79001 2.01088 3.66607C1.88612 4.01999 2.07079 4.40843 2.42334 4.53367C2.77589 4.65891 3.16283 4.47353 3.28758 4.11961C3.52007 3.46005 3.9152 2.87037 4.43611 2.40559C4.95701 1.9408 5.58671 1.61606 6.26645 1.46166Z"
        fill={color}
      />
      <path
        d="M1.35428 7.54621V9.16212C1.35428 9.53755 1.05111 9.8419 0.67714 9.8419C0.303166 9.8419 0 9.53755 0 9.16212V5.9478C0 5.57237 0.303166 5.26802 0.67714 5.26802H3.87902C4.25299 5.26802 4.55616 5.57237 4.55616 5.9478C4.55616 6.32322 4.25299 6.62757 3.87902 6.62757H2.35812L4.24959 8.4118C4.25474 8.41667 4.25983 8.42161 4.26483 8.42664C4.7575 8.92148 5.36701 9.28296 6.03649 9.47736C6.70597 9.67176 7.41359 9.69274 8.09332 9.53834C8.77306 9.38394 9.40276 9.0592 9.92367 8.59441C10.4446 8.12963 10.8397 7.53995 11.0722 6.88039C11.197 6.52647 11.5839 6.34109 11.9364 6.46633C12.289 6.59157 12.4737 6.98001 12.3489 7.33394C12.0401 8.20999 11.5153 8.99324 10.8234 9.61059C10.1315 10.2279 9.29506 10.6593 8.39219 10.8644C7.48933 11.0694 6.54943 11.0416 5.66019 10.7834C4.77447 10.5262 3.96776 10.0488 3.31473 9.39552L1.35428 7.54621Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75663 1.63588C7.68208 1.43079 8.64549 1.45866 9.55697 1.71688C10.4684 1.97509 11.2983 2.45524 11.969 3.11252C11.9728 3.11622 11.9767 3.11986 11.9805 3.12344L14.2276 5.19139L11.4043 5.19139C11.1222 5.19139 10.8936 5.4153 10.8936 5.69151C10.8936 5.96772 11.1222 6.19163 11.4043 6.19163L15.4894 6.19163C15.7714 6.19163 16 5.96772 16 5.69151L16 1.69054C16 1.41433 15.7714 1.19042 15.4894 1.19042C15.2073 1.19042 14.9787 1.41433 14.9787 1.69054L14.9787 4.51013L12.6856 2.39976C11.8939 1.62588 10.9153 1.06045 9.84074 0.756024C8.76354 0.450859 7.62496 0.417925 6.53125 0.660295C5.43754 0.902664 4.42434 1.41244 3.58619 2.14205C2.74804 2.87166 2.11227 3.79732 1.73818 4.83268C1.64411 5.09306 1.78336 5.37884 2.04923 5.47099C2.31509 5.56313 2.60688 5.42674 2.70096 5.16635C3.01749 4.29029 3.55545 3.50703 4.26466 2.88967C4.97386 2.2723 5.83118 1.84096 6.75663 1.63588Z"
        fill={color}
      />
      <path
        d="M0.309776 7.87371C0.246862 7.90003 0.188352 7.93923 0.138506 7.99119C0.0915505 8.04013 0.0566072 8.09588 0.0335631 8.15496C0.0151435 8.20218 0.00432598 8.25153 0.00105735 8.30122C0.000347 8.31202 -2.33033e-06 8.32284 2.65819e-07 8.33366L-1.37949e-07 12.3346C-1.65823e-07 12.6108 0.228621 12.8347 0.510638 12.8347C0.792657 12.8347 1.02128 12.6108 1.02128 12.3346L1.02128 9.48987L3.3144 11.6002C4.1061 12.3741 5.08467 12.9396 6.15926 13.244C7.23646 13.5491 8.37504 13.5821 9.46875 13.3397C10.5625 13.0973 11.5757 12.5876 12.4138 11.858C13.252 11.1283 13.8877 10.2027 14.2618 9.16732C14.3559 8.90694 14.2166 8.62115 13.9508 8.52901C13.6849 8.43687 13.3931 8.57326 13.299 8.83365C12.9825 9.70971 12.4445 10.493 11.7353 11.1103C11.0261 11.7277 10.1688 12.159 9.24337 12.3641C8.31792 12.5692 7.35451 12.5413 6.44303 12.2831C5.53155 12.0249 4.70172 11.5448 4.03095 10.8875C4.02718 10.8838 4.02335 10.8801 4.01946 10.8766L1.79977 8.83378L4.59575 8.83378C4.87776 8.83378 5.10639 8.60987 5.10639 8.33366C5.10639 8.05745 4.87776 7.83354 4.59575 7.83354L0.51228 7.83354"
        fill={color}
      />
      <path
        d="M0.502412 7.8336C0.434103 7.83466 0.369051 7.84885 0.309776 7.87371L0.502412 7.8336Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.29036 1.66013C10.5629 1.36039 11.8875 1.40112 13.1408 1.77851C14.3941 2.15591 15.5351 2.85766 16.4574 3.8183C16.4626 3.8237 16.4679 3.82902 16.4732 3.83425L19.5629 6.85665L15.6808 6.85665C15.2931 6.85665 14.9787 7.1839 14.9787 7.58759C14.9787 7.99128 15.2931 8.31854 15.6808 8.31854L21.2979 8.31854C21.6856 8.31854 22 7.99128 22 7.58759L22 1.74002C22 1.33633 21.6856 1.00908 21.2979 1.00908C20.9101 1.00908 20.5957 1.33633 20.5957 1.74002L20.5957 5.86096L17.4427 2.77657C16.3541 1.64552 15.0086 0.819113 13.531 0.374188C12.0499 -0.0718216 10.4843 -0.119956 8.98047 0.234277C7.47661 0.588509 6.08346 1.33356 4.93101 2.39991C3.77856 3.46627 2.90437 4.81917 2.39 6.33237C2.26064 6.71294 2.45212 7.13062 2.81768 7.26529C3.18325 7.39996 3.58446 7.20062 3.71382 6.82005C4.14905 5.53965 4.88875 4.39489 5.8639 3.49259C6.83905 2.59029 8.01787 1.95986 9.29036 1.66013Z"
        fill={color}
      />
      <path
        d="M0.425942 10.777C0.339435 10.8154 0.258984 10.8727 0.190446 10.9487C0.125882 11.0202 0.0778348 11.1017 0.0461493 11.188C0.0208223 11.257 0.00594821 11.3292 0.00145384 11.4018C0.000477117 11.4176 -3.2121e-06 11.4334 3.576e-07 11.4492L-2.01617e-07 17.2968C-2.40223e-07 17.7005 0.314353 18.0277 0.702128 18.0277C1.0899 18.0277 1.40426 17.7005 1.40426 17.2968L1.40426 13.139L4.5573 16.2234C5.64589 17.3545 6.99143 18.1809 8.46898 18.6258C9.95013 19.0718 11.5157 19.12 13.0195 18.7657C14.5234 18.4115 15.9165 17.6664 17.069 16.6001C18.2214 15.5337 19.0956 14.1808 19.61 12.6676C19.7394 12.2871 19.5479 11.8694 19.1823 11.7347C18.8167 11.6 18.4155 11.7994 18.2862 12.1799C17.8509 13.4603 17.1112 14.6051 16.1361 15.5074C15.1609 16.4097 13.9821 17.0401 12.7096 17.3399C11.4371 17.6396 10.1125 17.5989 8.85917 17.2215C7.60589 16.8441 6.46486 16.1423 5.54256 15.1817C5.53737 15.1763 5.5321 15.171 5.52676 15.1657L2.47468 12.1801L6.31915 12.1801C6.70693 12.1801 7.02128 11.8529 7.02128 11.4492C7.02128 11.0455 6.70693 10.7182 6.31915 10.7182L0.704385 10.7182"
        fill={color}
      />
      <path
        d="M0.690816 10.7183C0.596892 10.7199 0.507446 10.7406 0.425942 10.777L0.690816 10.7183Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1349 1.7475C11.5231 1.43199 12.9682 1.47486 14.3354 1.87212C15.7027 2.26938 16.9474 3.00807 17.9536 4.01926C17.9592 4.02495 17.965 4.03055 17.9708 4.03606L21.3414 7.21752L17.1064 7.21752C16.6834 7.21752 16.3404 7.562 16.3404 7.98694C16.3404 8.41188 16.6834 8.75636 17.1064 8.75636H23.234C23.6571 8.75636 24 8.41188 24 7.98694V1.8316C24 1.40666 23.6571 1.06219 23.234 1.06219C22.811 1.06219 22.4681 1.40667 22.4681 1.8316V6.16943L19.0284 2.92271C17.8408 1.73212 16.373 0.862224 14.7611 0.393883C13.1453 -0.0756015 11.4374 -0.126269 9.79687 0.246607C8.1563 0.619483 6.63651 1.40375 5.37928 2.52623C4.12206 3.6487 3.1684 5.07281 2.60728 6.66566C2.46616 7.06625 2.67504 7.50591 3.07384 7.64767C3.47263 7.78943 3.91032 7.5796 4.05144 7.17901C4.52623 5.83121 5.33318 4.6262 6.39698 3.67641C7.46078 2.72662 8.74677 2.06301 10.1349 1.7475Z"
        fill={color}
      />
      <path
        d="M0.464664 11.3442C0.370293 11.3847 0.282528 11.445 0.207759 11.5249C0.137326 11.6002 0.0849109 11.686 0.0503448 11.7769C0.0227154 11.8495 0.00648911 11.9254 0.00158616 12.0019C0.000520636 12.0185 -3.35872e-06 12.0351 5.35697e-07 12.0518L0 18.2071C-3.69822e-08 18.6321 0.342931 18.9765 0.765958 18.9765C1.18899 18.9765 1.53192 18.6321 1.53192 18.2071L1.53192 13.8306L4.9716 17.0773C6.15916 18.2679 7.62701 19.1378 9.23889 19.6061C10.8547 20.0756 12.5626 20.1263 14.2031 19.7534C15.8437 19.3805 17.3635 18.5963 18.6207 17.4738C19.8779 16.3513 20.8316 14.9272 21.3927 13.3343C21.5338 12.9337 21.325 12.4941 20.9262 12.3523C20.5274 12.2106 20.0897 12.4204 19.9486 12.821C19.4738 14.1688 18.6668 15.3738 17.603 16.3236C16.5392 17.2734 15.2532 17.937 13.8651 18.2525C12.4769 18.568 11.0318 18.5251 9.66455 18.1279C8.29733 17.7306 7.05257 16.9919 6.04643 15.9807C6.04077 15.975 6.03502 15.9695 6.02919 15.9639L2.69965 12.8212H6.89362C7.31665 12.8212 7.65958 12.4767 7.65958 12.0518C7.65958 11.6268 7.31665 11.2824 6.89362 11.2824L0.76842 11.2824"
        fill={color}
      />
      <path
        d="M0.753618 11.2825C0.651155 11.2841 0.553577 11.3059 0.464664 11.3442L0.753618 11.2825Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6687 2.18438C14.4039 1.78999 16.2103 1.84358 17.9193 2.34015C19.6283 2.83672 21.1843 3.76008 22.442 5.02408C22.449 5.03119 22.4562 5.03819 22.4635 5.04507L26.6767 9.02191L21.383 9.0219C20.8542 9.0219 20.4255 9.4525 20.4255 9.98368C20.4255 10.5148 20.8542 10.9454 21.383 10.9454H29.0426C29.5713 10.9454 30 10.5148 30 9.98368V2.2895C30 1.75833 29.5713 1.32773 29.0426 1.32773C28.5138 1.32773 28.0851 1.75833 28.0851 2.2895V7.71179L23.7855 3.65339C22.301 2.16515 20.4662 1.07778 18.4514 0.492353C16.4316 -0.0945018 14.2968 -0.157836 12.2461 0.308259C10.1954 0.774354 8.29563 1.75469 6.7241 3.15778C5.15258 4.56088 3.9605 6.34101 3.2591 8.33207C3.0827 8.83281 3.3438 9.38239 3.8423 9.55959C4.34079 9.73679 4.8879 9.4745 5.0643 8.97376C5.65779 7.28901 6.66648 5.78275 7.99623 4.59551C9.32598 3.40828 10.9335 2.57876 12.6687 2.18438Z"
        fill={color}
      />
      <path
        d="M0.58083 14.1802C0.462866 14.2308 0.353161 14.3062 0.259699 14.4061C0.171657 14.5002 0.106139 14.6075 0.062931 14.7211C0.0283942 14.8119 0.00811138 14.9068 0.00198269 15.0024C0.000650795 15.0231 -4.1984e-06 15.0439 6.69621e-07 15.0647L0 22.7589C-4.62277e-08 23.2901 0.428664 23.7207 0.957447 23.7207C1.48623 23.7207 1.91489 23.2901 1.91489 22.7589L1.91489 17.2882L6.2145 21.3466C7.69895 22.8348 9.53376 23.9222 11.5486 24.5077C13.5684 25.0945 15.7032 25.1578 17.7539 24.6917C19.8046 24.2256 21.7044 23.2453 23.2759 21.8422C24.8474 20.4391 26.0395 18.659 26.7409 16.6679C26.9173 16.1672 26.6562 15.6176 26.1577 15.4404C25.6592 15.2632 25.1121 15.5255 24.9357 16.0262C24.3422 17.711 23.3335 19.2172 22.0038 20.4045C20.674 21.5917 19.0665 22.4212 17.3313 22.8156C15.5961 23.21 13.7897 23.1564 12.0807 22.6599C10.3717 22.1633 8.81572 21.2399 7.55803 19.9759C7.55096 19.9688 7.54378 19.9618 7.53648 19.9549L3.37457 16.0265H8.61702C9.14581 16.0265 9.57447 15.5959 9.57447 15.0647C9.57447 14.5336 9.14581 14.103 8.61702 14.103L0.960525 14.103"
        fill={color}
      />
      <path
        d="M0.942023 14.1031C0.813944 14.1051 0.691972 14.1324 0.58083 14.1802L0.942023 14.1031Z"
        fill={color}
      />
    </svg>
  )
};
