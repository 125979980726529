import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22222 6.4V4C3.22222 3.20435 3.51488 2.44129 4.03581 1.87868C4.55675 1.31607 5.26329 1 6 1C6.73671 1 7.44325 1.31607 7.96419 1.87868C8.48512 2.44129 8.77778 3.20435 8.77778 4V6.4M2.11111 6.4H9.88889C10.5025 6.4 11 6.93726 11 7.6V11.8C11 12.4627 10.5025 13 9.88889 13H2.11111C1.49746 13 1 12.4627 1 11.8V7.6C1 6.93726 1.49746 6.4 2.11111 6.4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66667 7.3V4.5C3.66667 3.57174 4.01786 2.6815 4.64298 2.02513C5.2681 1.36875 6.11595 1 7 1C7.88406 1 8.7319 1.36875 9.35702 2.02513C9.98214 2.6815 10.3333 3.57174 10.3333 4.5V7.3M2.33333 7.3H11.6667C12.403 7.3 13 7.9268 13 8.7V13.6C13 14.3732 12.403 15 11.6667 15H2.33333C1.59695 15 1 14.3732 1 13.6V8.7C1 7.9268 1.59695 7.3 2.33333 7.3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11111 8.2V5C4.11111 3.93913 4.52083 2.92172 5.25014 2.17157C5.97945 1.42143 6.9686 1 8 1C9.0314 1 10.0206 1.42143 10.7499 2.17157C11.4792 2.92172 11.8889 3.93913 11.8889 5V8.2M2.55556 8.2H13.4444C14.3036 8.2 15 8.91634 15 9.8V15.4C15 16.2837 14.3036 17 13.4444 17H2.55556C1.69645 17 1 16.2837 1 15.4V9.8C1 8.91634 1.69645 8.2 2.55556 8.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22222 10.9V6.5C5.22222 5.04131 5.77827 3.64236 6.76805 2.61091C7.75782 1.57946 9.10025 1 10.5 1C11.8998 1 13.2422 1.57946 14.232 2.61091C15.2217 3.64236 15.7778 5.04131 15.7778 6.5V10.9M3.11111 10.9H17.8889C19.0548 10.9 20 11.885 20 13.1V20.8C20 22.015 19.0548 23 17.8889 23H3.11111C1.94518 23 1 22.015 1 20.8V13.1C1 11.885 1.94518 10.9 3.11111 10.9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.88889 11.8V7C5.88889 5.4087 6.53274 3.88258 7.67879 2.75736C8.82485 1.63214 10.3792 1 12 1C13.6208 1 15.1752 1.63214 16.3212 2.75736C17.4673 3.88258 18.1111 5.4087 18.1111 7V11.8M3.44444 11.8H20.5556C21.9056 11.8 23 12.8745 23 14.2V22.6C23 23.9255 21.9056 25 20.5556 25H3.44444C2.09441 25 1 23.9255 1 22.6V14.2C1 12.8745 2.09441 11.8 3.44444 11.8Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22222 14.5V8.5C7.22222 6.51088 8.04166 4.60322 9.50028 3.1967C10.9589 1.79018 12.9372 1 15 1C17.0628 1 19.0411 1.79018 20.4997 3.1967C21.9583 4.60322 22.7778 6.51088 22.7778 8.5V14.5M4.11111 14.5H25.8889C27.6071 14.5 29 15.8431 29 17.5V28C29 29.6569 27.6071 31 25.8889 31H4.11111C2.39289 31 1 29.6569 1 28V17.5C1 15.8431 2.39289 14.5 4.11111 14.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
