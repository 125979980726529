import React, { useEffect, useRef, useState } from 'react';
import { concatClassNames } from '../concatClassNames';
import { Input, Pagination } from '@scuf/common';
import { IPaginationProps } from '@scuf/common/dist/components/Pagination/IPaginationProps';
import './NiagaraPagination.scss';
import { isEmpty } from 'lodash';

export interface INiagaraPaginationProps extends IPaginationProps {
  style?: React.CSSProperties;
  goToPageText?: string;
  onChangeDebounceTimer?: number;
  showItemCountAtBottom?: boolean;
  itemCountDisplay?: string;
}

const NiagaraPagination: React.FC<INiagaraPaginationProps> = ({
  goToPageText = 'Go to Page',
  itemsPerPage,
  totalItems,
  className,
  style,
  onPageChange,
  onChangeDebounceTimer,
  showItemCountAtBottom,
  itemCountDisplay,
  ...props
}) => {
  const [value, setValue] = useState<string>();
  const prev = useRef<string>();

  const [activePage, setActivePage] = useState(props.activePage);
  const [showError, setShowError] = useState(false);
  const firstPageNumber = 1;
  const lastPageNumber = Math.ceil(totalItems / itemsPerPage);

  const changeActivePage = (value: string | undefined) => {
    const newPageNumber = Number(value);
    if (
      !isNaN(newPageNumber) &&
      newPageNumber >= firstPageNumber &&
      newPageNumber <= lastPageNumber
    ) {
      setShowError(false);
      setActivePage(newPageNumber);
      onPageChange?.(newPageNumber);
    } else if (!isEmpty(value)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  useEffect(() => {
    if (value === prev.current) return;
    if (!onChangeDebounceTimer) {
      changeActivePage(value);
      return;
    }
    const i = setTimeout(
      (v: string) => {
        prev.current = v;
        return changeActivePage(v);
      },
      onChangeDebounceTimer,
      value
    );
    return () => clearTimeout(i);
  }, [value, onPageChange, onChangeDebounceTimer]);

  const handlePageChange = (
    currentlyActivePage: string | number,
    itemsPerPage?: string | number
  ) => {
    setActivePage(currentlyActivePage);
    onPageChange?.(currentlyActivePage, itemsPerPage);
  };
  const handleKeydown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (
      event.key == 'e' ||
      event.key == '.' ||
      event.key === '-' ||
      event.key === '+'
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setActivePage(props.activePage);
  }, [props.activePage]);

  return (
    <div
      className={concatClassNames(
        className,
        'niagara-pagination',
        'ncs-pagination-root'
      )}
      style={style}
    >
      {showItemCountAtBottom && (
        <span className="item-count-display">{itemCountDisplay}</span>
      )}
      <Pagination
        {...props}
        showDisplayDetails={false}
        expandEllipsis={false}
        activePage={activePage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onPageChange={handlePageChange}
      />
      <div className="go-to-page">
        {goToPageText}
        <Input
          placeholder=""
          type="number"
          min={firstPageNumber}
          max={lastPageNumber}
          onChange={setValue}
          onKeyDown={handleKeydown}
          fluid={true}
        />
        {showError && (
          <div className="error-text">This page does not exist</div>
        )}
      </div>
    </div>
  );
};

export default NiagaraPagination;
