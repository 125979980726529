import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33333 13V7H7.66667V13M1 5.2L6 1L11 5.2V11.8C11 12.1183 10.8829 12.4235 10.6746 12.6485C10.4662 12.8736 10.1836 13 9.88889 13H2.11111C1.81643 13 1.53381 12.8736 1.32544 12.6485C1.11706 12.4235 1 12.1183 1 11.8V5.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15V8H9V15M1 5.9L7 1L13 5.9V13.6C13 13.9713 12.8595 14.3274 12.6095 14.5899C12.3594 14.8525 12.0203 15 11.6667 15H2.33333C1.97971 15 1.64057 14.8525 1.39052 14.5899C1.14048 14.3274 1 13.9713 1 13.6V5.9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66667 17V9H10.3333V17M1 6.6L8 1L15 6.6V15.4C15 15.8243 14.8361 16.2313 14.5444 16.5314C14.2527 16.8314 13.857 17 13.4444 17H2.55556C2.143 17 1.74733 16.8314 1.45561 16.5314C1.16389 16.2313 1 15.8243 1 15.4V6.6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 23V12H14.3333V23M1 8.7L11 1L21 8.7V20.8C21 21.3835 20.7659 21.9431 20.3491 22.3556C19.9324 22.7682 19.3671 23 18.7778 23H3.22222C2.63285 23 2.06762 22.7682 1.65087 22.3556C1.23413 21.9431 1 21.3835 1 20.8V8.7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 25V13H15.6667V25M1 9.4L12 1L23 9.4V22.6C23 23.2365 22.7425 23.847 22.284 24.2971C21.8256 24.7471 21.2039 25 20.5556 25H3.44444C2.79614 25 2.17438 24.7471 1.71596 24.2971C1.25754 23.847 1 23.2365 1 22.6V9.4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 31V16H19V31M1 11.5L14.5 1L28 11.5V28C28 28.7956 27.6839 29.5587 27.1213 30.1213C26.5587 30.6839 25.7956 31 25 31H4C3.20435 31 2.44129 30.6839 1.87868 30.1213C1.31607 29.5587 1 28.7956 1 28V11.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
