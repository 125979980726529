import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13L6 9.66667L1.5 13V2.33333C1.5 1.97971 1.63546 1.64057 1.87658 1.39052C2.1177 1.14048 2.44472 1 2.78571 1H9.21429C9.55528 1 9.8823 1.14048 10.1234 1.39052C10.3645 1.64057 10.5 1.97971 10.5 2.33333V13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15L6.5 11.1111L1 15V2.55556C1 2.143 1.16556 1.74733 1.46026 1.45561C1.75496 1.16389 2.15466 1 2.57143 1H10.4286C10.8453 1 11.245 1.16389 11.5397 1.45561C11.8344 1.74733 12 2.143 12 2.55556V15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17L7 12.5556L1 17V2.77778C1 2.30628 1.18061 1.8541 1.5021 1.5207C1.82359 1.1873 2.25963 1 2.71429 1H11.2857C11.7404 1 12.1764 1.1873 12.4979 1.5207C12.8194 1.8541 13 2.30628 13 2.77778V17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 23L9.5 16.8889L1 23V3.44444C1 2.79614 1.25587 2.17438 1.71131 1.71596C2.16676 1.25754 2.78447 1 3.42857 1H15.5714C16.2155 1 16.8332 1.25754 17.2887 1.71596C17.7441 2.17438 18 2.79614 18 3.44444V23Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 25L10 18.3333L1 25V3.66667C1 2.95942 1.27092 2.28115 1.75315 1.78105C2.23539 1.28095 2.88944 1 3.57143 1H16.4286C17.1106 1 17.7646 1.28095 18.2468 1.78105C18.7291 2.28115 19 2.95942 19 3.66667V25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 31L12.5 22.6667L1 31V4.33333C1 3.44928 1.34617 2.60143 1.96236 1.97631C2.57855 1.35119 3.41429 1 4.28571 1H20.7143C21.5857 1 22.4214 1.35119 23.0376 1.97631C23.6538 2.60143 24 3.44928 24 4.33333V31Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
