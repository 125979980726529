import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.81818 8.09091C5.05243 8.40407 5.35129 8.66319 5.69448 8.85069M6.86364 3.28L7.80182 2.34727C8.31619 1.85048 9.0051 1.57558 9.72019 1.5818C10.4353 1.58801 11.1193 1.87483 11.625 2.38049C12.1306 2.88615 12.4174 3.57019 12.4237 4.28527C12.4299 5.00035 12.155 5.68926 11.6582 6.20363L10.4309 7.43091M8.63637 5.90909C8.40213 5.59593 8.10327 5.33681 7.76007 5.1493M3.5 6.5L2.34183 7.79636C1.84503 8.31073 1.57014 8.99965 1.57635 9.71473C1.58257 10.4298 1.86939 11.1138 2.37505 11.6195C2.88071 12.1252 3.56474 12.412 4.27982 12.4182C4.99491 12.4244 5.68382 12.1495 6.19819 11.6527L7.13092 10.72M1 1L13 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45455 9.27272C5.72783 9.63808 6.0765 9.94039 6.4769 10.1591M7.84091 3.66L8.93545 2.57182C9.53555 1.99222 10.3393 1.67151 11.1735 1.67876C12.0078 1.68601 12.8059 2.02064 13.3958 2.61057C13.9857 3.20051 14.3204 3.99855 14.3276 4.83281C14.3348 5.66707 14.0141 6.47081 13.4345 7.07091L12.0027 8.50272M9.9091 6.72727C9.63581 6.36191 9.28715 6.05961 8.88675 5.84085M3.91667 7.41667L2.56547 8.92909C1.98587 9.52918 1.66516 10.3329 1.67241 11.1672C1.67966 12.0014 2.01429 12.7995 2.60422 13.3894C3.19416 13.9794 3.9922 14.314 4.82646 14.3212C5.66072 14.3285 6.46446 14.0078 7.06456 13.4282L8.15274 12.34M1 1L15 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09091 10.4545C6.40324 10.8721 6.80171 11.2176 7.25931 11.4676C7.7169 11.7176 8.4799 11.8718 9 11.9091M8.81818 4.04L10.0691 2.79636C10.7549 2.13397 11.6735 1.76744 12.6269 1.77573C13.5804 1.78401 14.4924 2.16645 15.1666 2.84066C15.8408 3.51487 16.2233 4.42691 16.2315 5.38036C16.2398 6.3338 15.8733 7.25235 15.2109 7.93818L13.5745 9.57454M11.1818 7.54545C10.8695 7.1279 10.471 6.78241 10.0134 6.5324C9.55583 6.2824 9.04983 6.13373 8.52972 6.09648M4.97092 7.88L2.7891 10.0618C2.12671 10.7476 1.76019 11.6662 1.76847 12.6196C1.77676 13.5731 2.15919 14.4851 2.8334 15.1593C3.50761 15.8335 4.41966 16.216 5.3731 16.2243C6.32654 16.2326 7.24509 15.866 7.93092 15.2036L9.17456 13.96M1 1L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C8.42945 14.5741 8.97736 15.0492 9.60655 15.3929C10.2357 15.7367 11.2849 15.9488 12 16M11.75 5.18L13.47 3.47C14.413 2.55921 15.676 2.05523 16.987 2.06663C18.298 2.07802 19.5521 2.60386 20.4791 3.5309C21.4061 4.45794 21.932 5.71201 21.9434 7.02299C21.9548 8.33397 21.4508 9.59699 20.54 10.54L18.29 12.79M15 9.99999C14.5706 9.42587 14.0227 8.95081 13.3935 8.60705C12.7643 8.2633 12.0685 8.05888 11.3534 8.00766M6.46002 10.46L3.46002 13.46C2.54923 14.403 2.04525 15.666 2.05665 16.977C2.06804 18.288 2.59388 19.542 3.52092 20.4691C4.44796 21.3961 5.70203 21.922 7.01301 21.9334C8.32399 21.9448 9.58701 21.4408 10.53 20.53L12.24 18.82M1 1L23 23"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63636 15.1818C9.10486 15.8081 9.70257 16.3264 10.389 16.7014C11.0754 17.0764 12.2198 17.3078 13 17.3636M12.7273 5.56L14.6036 3.69454C15.6324 2.70095 17.0102 2.15116 18.4404 2.16359C19.8705 2.17602 21.2386 2.74967 22.2499 3.76099C23.2612 4.7723 23.8349 6.14037 23.8473 7.57053C23.8597 9.0007 23.31 10.3785 22.3164 11.4073L19.8618 13.8618M16.2727 10.8182C15.8043 10.1919 15.2065 9.67361 14.5201 9.2986C13.8338 8.9236 13.0747 8.70059 12.2946 8.64472M6.95638 11.32L3.68365 14.5927C2.69007 15.6215 2.14028 16.9993 2.15271 18.4295C2.16513 19.8596 2.73878 21.2277 3.7501 22.239C4.76142 23.2503 6.12948 23.824 7.55965 23.8364C8.98981 23.8488 10.3676 23.299 11.3964 22.3054L13.2618 20.44M1 1L25 25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5455 18.7273C11.1311 19.5102 11.8782 20.158 12.7362 20.6267C13.5942 21.0955 15.0248 21.3847 16 21.4545M15.6591 6.69999L18.0045 4.36818C19.2905 3.12619 21.0128 2.43896 22.8005 2.45449C24.5882 2.47002 26.2983 3.18709 27.5624 4.45123C28.8265 5.71538 29.5436 7.42546 29.5591 9.21317C29.5747 11.0009 28.8874 12.7232 27.6455 14.0091L24.5773 17.0773M20.0909 13.2727C19.5053 12.4898 18.7582 11.842 17.9002 11.3733C17.0422 10.9045 16.0934 10.6257 15.1182 10.5559M8.44548 13.9L4.35457 17.9909C3.11258 19.2768 2.42535 20.9991 2.44088 22.7868C2.45642 24.5745 3.17348 26.2846 4.43762 27.5488C5.70177 28.8129 7.41185 29.53 9.19956 29.5455C10.9873 29.561 12.7096 28.8738 13.9955 27.6318L16.3273 25.3M1 1L31 31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
