import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 6.6256L7.29945 11.9763C6.66235 12.6317 5.79824 13 4.89724 13C3.99624 13 3.13213 12.6317 2.49503 11.9763C1.85792 11.3208 1.5 10.4317 1.5 9.5047C1.5 8.57769 1.85792 7.68865 2.49503 7.03315L7.69558 1.6825C8.12031 1.2455 8.69638 1 9.29705 1C9.89772 1 10.4738 1.2455 10.8985 1.6825C11.3233 2.1195 11.5619 2.71219 11.5619 3.3302C11.5619 3.94821 11.3233 4.5409 10.8985 4.9779L5.69232 10.3286C5.47995 10.5471 5.19192 10.6698 4.89158 10.6698C4.59125 10.6698 4.30321 10.5471 4.09084 10.3286C3.87848 10.1101 3.75917 9.81371 3.75917 9.5047C3.75917 9.1957 3.87848 8.89935 4.09084 8.68085L8.89527 3.74358"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.5632L7.8539 13.8056C7.10095 14.5704 6.07974 15 5.01492 15C3.9501 15 2.92889 14.5704 2.17594 13.8056C1.423 13.0409 1 12.0037 1 10.9222C1 9.84064 1.423 8.80342 2.17594 8.03868L8.32204 1.79625C8.82401 1.28642 9.50481 1 10.2147 1C10.9246 1 11.6054 1.28642 12.1073 1.79625C12.6093 2.30608 12.8913 2.99756 12.8913 3.71856C12.8913 4.43957 12.6093 5.13105 12.1073 5.64088L5.95456 11.8833C5.70358 12.1382 5.36317 12.2814 5.00823 12.2814C4.65329 12.2814 4.31289 12.1382 4.06191 11.8833C3.81093 11.6284 3.66993 11.2827 3.66993 10.9222C3.66993 10.5616 3.81093 10.2159 4.06191 9.961L9.73986 4.20084"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 8.5008L9.40834 15.635C8.53956 16.509 7.36124 17 6.1326 17C4.90396 17 3.72564 16.509 2.85686 15.635C1.98808 14.761 1.5 13.5756 1.5 12.3396C1.5 11.1036 1.98808 9.9182 2.85686 9.04421L9.94851 1.91C10.5277 1.32734 11.3132 1 12.1323 1C12.9514 1 13.737 1.32734 14.3162 1.91C14.8954 2.49266 15.2207 3.28292 15.2207 4.10693C15.2207 4.93094 14.8954 5.7212 14.3162 6.30386L7.2168 13.4381C6.9272 13.7294 6.53443 13.8931 6.12488 13.8931C5.71534 13.8931 5.32256 13.7294 5.03297 13.4381C4.74338 13.1467 4.58068 12.7516 4.58068 12.3396C4.58068 11.9276 4.74338 11.5325 5.03297 11.2411L11.5845 4.6581"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.3136L12.0717 21.1231C10.8554 22.3249 9.20574 23 7.48564 23C5.76554 23 4.11589 22.3249 2.8996 21.1231C1.68331 19.9214 1 18.2915 1 16.592C1 14.8924 1.68331 13.2625 2.8996 12.0608L12.8279 2.25125C13.6388 1.45009 14.7385 1 15.8853 1C17.032 1 18.1318 1.45009 18.9426 2.25125C19.7535 3.05241 20.209 4.13902 20.209 5.27203C20.209 6.40504 19.7535 7.49165 18.9426 8.29281L9.00352 18.1023C8.59809 18.5029 8.0482 18.728 7.47484 18.728C6.90147 18.728 6.35159 18.5029 5.94616 18.1023C5.54073 17.7018 5.31296 17.1585 5.31296 16.592C5.31296 16.0254 5.54073 15.4821 5.94616 15.0816L15.1182 6.02989"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5 12.2512L13.6261 22.9525C12.294 24.2635 10.4872 25 8.60332 25C6.7194 25 4.91265 24.2635 3.58051 22.9525C2.24838 21.6415 1.5 19.8634 1.5 18.0094C1.5 16.1554 2.24838 14.3773 3.58051 13.0663L14.4544 2.365C15.3425 1.491 16.547 1 17.8029 1C19.0589 1 20.2634 1.491 21.1515 2.365C22.0395 3.23899 22.5385 4.42438 22.5385 5.6604C22.5385 6.89641 22.0395 8.0818 21.1515 8.9558L10.2658 19.6571C9.82171 20.0941 9.21946 20.3396 8.59149 20.3396C7.96352 20.3396 7.36126 20.0941 6.91722 19.6571C6.47318 19.2201 6.22371 18.6274 6.22371 18.0094C6.22371 17.3914 6.47318 16.7987 6.91722 16.3617L16.9628 6.48716"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 15.064L16.2895 28.4406C14.6098 30.0794 12.3317 31 9.95636 31C7.58099 31 5.3029 30.0794 3.62326 28.4406C1.94361 26.8019 1 24.5793 1 22.2618C1 19.9442 1.94361 17.7216 3.62326 16.0829L17.3338 2.70625C18.4536 1.61376 19.9723 1 21.5559 1C23.1394 1 24.6582 1.61376 25.7779 2.70625C26.8977 3.79874 27.5268 5.28048 27.5268 6.82549C27.5268 8.37051 26.8977 9.85225 25.7779 10.9447L12.0525 24.3214C11.4926 24.8676 10.7332 25.1745 9.94144 25.1745C9.14965 25.1745 8.39029 24.8676 7.83041 24.3214C7.27053 23.7751 6.95599 23.0343 6.95599 22.2618C6.95599 21.4892 7.27053 20.7484 7.83041 20.2021L20.4966 7.85895"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
