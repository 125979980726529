import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94737 0.947368C6.94737 0.424151 6.52322 0 6 0C5.47678 0 5.05263 0.424151 5.05263 0.947368V5.05263H0.947368C0.424151 5.05263 0 5.47678 0 6C0 6.52322 0.424151 6.94737 0.947368 6.94737H5.05263V11.0526C5.05263 11.5758 5.47678 12 6 12C6.52322 12 6.94737 11.5758 6.94737 11.0526V6.94737H11.0526C11.5758 6.94737 12 6.52322 12 6C12 5.47678 11.5758 5.05263 11.0526 5.05263H6.94737V0.947368Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10526 1.10526C8.10526 0.494843 7.61042 0 7 0C6.38958 0 5.89474 0.494843 5.89474 1.10526V5.89474H1.10526C0.494843 5.89474 0 6.38958 0 7C0 7.61042 0.494843 8.10526 1.10526 8.10526H5.89474V12.8947C5.89474 13.5052 6.38958 14 7 14C7.61042 14 8.10526 13.5052 8.10526 12.8947V8.10526H12.8947C13.5052 8.10526 14 7.61042 14 7C14 6.38958 13.5052 5.89474 12.8947 5.89474H8.10526V1.10526Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.7293 0.774194C8.7293 0.346618 8.38268 0 7.95511 0C7.52753 0 7.18092 0.346618 7.18092 0.774194V7.22581H0.774194C0.346618 7.22581 0 7.57242 0 8C0 8.42758 0.346618 8.77419 0.774194 8.77419H7.18092V15.2258C7.18092 15.6534 7.52753 16 7.95511 16C8.38268 16 8.7293 15.6534 8.7293 15.2258V8.77419H15.2258C15.6534 8.77419 16 8.42758 16 8C16 7.57242 15.6534 7.22581 15.2258 7.22581H8.7293V0.774194Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0028 1.06452C12.0028 0.4766 11.5262 0 10.9383 0C10.3504 0 9.87376 0.4766 9.87376 1.06452V9.93548H1.06452C0.4766 9.93548 0 10.4121 0 11C0 11.5879 0.4766 12.0645 1.06452 12.0645H9.87376V20.9355C9.87376 21.5234 10.3504 22 10.9383 22C11.5262 22 12.0028 21.5234 12.0028 20.9355V12.0645H20.9355C21.5234 12.0645 22 11.5879 22 11C22 10.4121 21.5234 9.93548 20.9355 9.93548H12.0028V1.06452Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 1.2C13.2 0.537258 12.6627 0 12 0C11.3373 0 10.8 0.537258 10.8 1.2V10.8H1.2C0.537258 10.8 0 11.3373 0 12C0 12.6627 0.537258 13.2 1.2 13.2H10.8V22.8C10.8 23.4627 11.3373 24 12 24C12.6627 24 13.2 23.4627 13.2 22.8V13.2H22.8C23.4627 13.2 24 12.6627 24 12C24 11.3373 23.4627 10.8 22.8 10.8H13.2V1.2Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 1.5C16.5 0.671573 15.8284 0 15 0C14.1716 0 13.5 0.671573 13.5 1.5V13.5H1.5C0.671573 13.5 0 14.1716 0 15C0 15.8284 0.671573 16.5 1.5 16.5H13.5V28.5C13.5 29.3284 14.1716 30 15 30C15.8284 30 16.5 29.3284 16.5 28.5V16.5H28.5C29.3284 16.5 30 15.8284 30 15C30 14.1716 29.3284 13.5 28.5 13.5H16.5V1.5Z"
        fill={color}
      />
    </svg>
  )
};
