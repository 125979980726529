import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99999 4V7L4.99999 8M11.1667 4.23517C10.2709 2.88792 8.73913 2 6.99999 2C4.23857 2 1.99999 4.23858 1.99999 7C1.99999 9.05032 3.23408 10.8124 4.99999 11.584"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5041 7.25666C10.6684 7.09232 10.8913 7 11.1237 7C11.3561 7 11.579 7.09232 11.7433 7.25666C11.9077 7.421 12 7.64388 12 7.87629C12 8.1087 11.9077 8.33158 11.7433 8.49592L8.65235 11.5869L7 12L7.41309 10.3477L10.5041 7.25666Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.4V8L5.6 9.2M13 4.6822C11.9251 3.06551 10.087 2 8 2C4.68629 2 2 4.68629 2 8C2 10.4604 3.48091 12.5749 5.6 13.5007"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2049 8.30799C12.4021 8.11079 12.6696 8 12.9485 8C13.2273 8 13.4948 8.11079 13.692 8.30799C13.8892 8.50519 14 8.77266 14 9.05155C14 9.33044 13.8892 9.5979 13.692 9.7951L9.98282 13.5043L8 14L8.4957 12.0172L12.2049 8.30799Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00001 4.8V9L6.20001 10.4M14.8333 5.12924C13.5793 3.24309 11.4348 2 9.00001 2C5.13402 2 2.00001 5.13401 2.00001 9C2.00001 11.8704 3.72774 14.3374 6.20001 15.4175"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9057 9.35932C14.1358 9.12925 14.4478 9 14.7732 9C15.0986 9 15.4106 9.12925 15.6407 9.35932C15.8707 9.58939 16 9.90144 16 10.2268C16 10.5522 15.8707 10.8642 15.6407 11.0943L11.3133 15.4217L9 16L9.57832 13.6867L13.9057 9.35932Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6V12L8.00001 14M20.3333 6.47034C18.5418 3.77585 15.4783 2 12 2C6.47716 2 2.00001 6.47715 2.00001 12C2.00001 16.1006 4.4682 19.6248 8.00001 21.1679"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0082 12.5133C19.3368 12.1846 19.7826 12 20.2474 12C20.7122 12 21.158 12.1846 21.4867 12.5133C21.8154 12.842 22 13.2878 22 13.7526C22 14.2174 21.8154 14.6632 21.4867 14.9918L15.3047 21.1738L12 22L12.8262 18.6953L19.0082 12.5133Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.4V13L8.59999 15.2M22.1667 6.91738C20.196 3.95343 16.8261 2 13 2C6.92486 2 1.99999 6.92487 1.99999 13C1.99999 17.5107 4.715 21.3873 8.59999 23.0847"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.709 13.5647C21.0705 13.2031 21.5609 13 22.0722 13C22.5835 13 23.0738 13.2031 23.4353 13.5647C23.7969 13.9262 24 14.4165 24 14.9278C24 15.4391 23.7969 15.9295 23.4353 16.291L16.6352 23.0912L13 24L13.9088 20.3648L20.709 13.5647Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.6V16L10.4 18.8M27.6667 8.25848C25.1585 4.48619 20.8696 2 16 2C8.268 2 1.99999 8.26801 1.99999 16C1.99999 21.7409 5.45546 26.6747 10.4 28.8351"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8114 16.7186C26.2716 16.2585 26.8956 16 27.5464 16C28.1971 16 28.8212 16.2585 29.2814 16.7186C29.7415 17.1788 30 17.8029 30 18.4536C30 19.1043 29.7415 19.7284 29.2814 20.1886L20.6266 28.8434L16 30L17.1566 25.3734L25.8114 16.7186Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
