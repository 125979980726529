import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57037 7.94841C7.57037 7.61706 7.30174 7.34845 6.97037 7.34845C6.639 7.34845 6.37037 7.61706 6.37037 7.94841V8.97057C6.37037 9.211 6.51391 9.42821 6.7351 9.52248L7.56473 9.87609C7.86957 10.006 8.22201 9.86424 8.35195 9.55943C8.48188 9.25461 8.3401 8.90219 8.03527 8.77226L7.57037 8.57411V7.94841Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5588C0 0.674415 0.740326 0 1.6 0H5.5C5.66739 0 5.82717 0.0699216 5.94075 0.192876L9.04075 3.5488C9.14314 3.65965 9.2 3.805 9.2 3.95589V6.96071C9.69647 7.49593 10 8.21262 10 9.0002C10 10.6569 8.65685 12 7 12C6.01322 12 5.13772 11.5236 4.59096 10.7883H1.60043L1.60002 9.58836H4.05764C4.01983 9.39817 4 9.2015 4 9.0002L4.00004 8.98535L4 8.97057C4 7.33019 5.32988 6.0004 6.97037 6.0004L6.98515 6.00043L7 6.0004C7.35064 6.0004 7.68722 6.06055 8 6.17109V4.80048H4.8C4.46863 4.80048 4.2 4.53187 4.2 4.20052V1.19992H1.6C1.35511 1.19992 1.2 1.38408 1.2 1.5588V9.22949C1.2 9.40421 1.35512 9.58836 1.60002 9.58836L1.60043 10.7883C0.740754 10.7883 0 10.1139 0 9.22949V1.5588ZM5.4 3.60056V1.37593L7.45498 3.60056H5.4ZM5.20006 8.98532C5.20801 8.00308 6.00281 7.20833 6.98512 7.20038C7.95608 7.2083 8.74074 7.99781 8.74074 8.97057C8.74074 9.94825 7.94812 10.7408 6.97037 10.7408C5.99754 10.7408 5.20799 9.95621 5.20006 8.98532Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08444 9.27315C9.08444 8.88658 8.76209 8.5732 8.36444 8.5732C7.9668 8.5732 7.64444 8.88658 7.64444 9.27315V10.4657C7.64444 10.7462 7.81669 10.9996 8.08212 11.1096L9.07768 11.5221C9.44348 11.6737 9.86642 11.5083 10.0223 11.1527C10.1783 10.7971 10.0081 10.3859 9.64232 10.2343L9.08444 10.0031V9.27315Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.8186C0 0.786818 0.888391 0 1.92 0H6.6C6.80087 0 6.99261 0.0815752 7.1289 0.225022L10.8489 4.14027C10.9718 4.26959 11.04 4.43916 11.04 4.6152V8.12083C11.6358 8.74525 12 9.58139 12 10.5002C12 12.4331 10.3882 14 8.4 14C7.21587 14 6.16526 13.4442 5.50916 12.5863H1.92051L1.92002 11.1864H4.86917C4.8238 10.9645 4.8 10.7351 4.8 10.5002L4.80004 10.4829L4.8 10.4657C4.8 8.55189 6.39586 7.00046 8.36444 7.00046L8.38218 7.00051L8.4 7.00046C8.82076 7.00046 9.22466 7.07064 9.6 7.19961V5.60056H5.76C5.36235 5.60056 5.04 5.28718 5.04 4.9006V1.39991H1.92C1.62613 1.39991 1.44 1.61476 1.44 1.8186V10.7677C1.44 10.9716 1.62615 11.1864 1.92002 11.1864L1.92051 12.5863C0.888905 12.5863 0 11.7995 0 10.7677V1.8186ZM6.48 4.20065V1.60525L8.94597 4.20065H6.48ZM6.24007 10.4829C6.24961 9.33693 7.20338 8.40972 8.38215 8.40044C9.54729 8.40969 10.4889 9.33078 10.4889 10.4657C10.4889 11.6063 9.53774 12.531 8.36444 12.531C7.19705 12.531 6.24958 11.6156 6.24007 10.4829Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2099 10.6871C10.2099 10.3824 9.96736 10.1354 9.66821 10.1354C9.36906 10.1354 9.12654 10.3824 9.12654 10.6871V12.0971C9.12654 12.3182 9.25613 12.5179 9.45581 12.6046L10.5793 13.0924C10.8545 13.2118 11.1727 13.0815 11.29 12.8012C11.4073 12.5209 11.2793 12.1968 11.0041 12.0773L10.2099 11.7325V10.6871Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 1.87586C0.5 0.818842 1.36985 0 2.39583 0H7.67708C7.82831 0 7.97265 0.0643914 8.07519 0.177601L12.2731 4.81208C12.3654 4.91399 12.4167 5.04756 12.4167 5.18621V9.43504C13.0868 10.1316 13.5 11.0856 13.5 12.1379C13.5 14.2709 11.8024 16 9.70833 16C8.41985 16 7.28147 15.3454 6.59629 14.3448H2.39641L2.39583 13.7931L2.39586 13.2414H6.07368C5.97153 12.8917 5.91667 12.5214 5.91667 12.1379L5.91672 12.1174L5.91667 12.0971C5.91667 9.98667 7.59629 8.27586 9.66821 8.27586L9.68822 8.27592L9.70833 8.27586C10.2899 8.27586 10.8408 8.40921 11.3333 8.64752V5.51724H7.54167C7.24251 5.51724 7 5.27023 7 4.96552V1.10345H2.39583C1.92605 1.10345 1.58333 1.47028 1.58333 1.87586V12.469C1.58333 12.8745 1.92607 13.2414 2.39586 13.2414L2.39583 13.7931L2.39641 14.3448C1.37043 14.3448 0.5 13.526 0.5 12.469V1.87586ZM10.4384 4.41379L8.08333 1.81386V4.41379H10.4384ZM7.00007 12.1174C7.01085 10.6101 8.2084 9.39036 9.6882 9.37938C11.1526 9.39033 12.3364 10.6029 12.3364 12.0971C12.3364 13.598 11.1418 14.8148 9.66821 14.8148C8.20127 14.8148 7.01082 13.609 7.00007 12.1174Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4444 14.6948C13.4444 14.2758 13.1087 13.9361 12.6944 13.9361C12.2802 13.9361 11.9444 14.2758 11.9444 14.6948V16.6335C11.9444 16.9375 12.1239 17.2121 12.4004 17.3313L13.9559 18.002C14.337 18.1663 14.7775 17.987 14.9399 17.6016C15.1024 17.2162 14.9251 16.7706 14.5441 16.6063L13.4444 16.1322V14.6948Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.57931C0 1.12591 1.2044 0 2.625 0H9.9375C10.1469 0 10.3467 0.0885382 10.4887 0.244201L16.3012 6.61661C16.429 6.75673 16.5 6.94039 16.5 7.13103V12.9732C17.4279 13.931 18 15.2427 18 16.6897C18 19.6225 15.6495 22 12.75 22C10.9659 22 9.38973 21.0999 8.44102 19.7241H2.6258L2.625 18.9655L2.62503 18.2069H7.71741C7.57596 17.7262 7.5 17.2169 7.5 16.6897L7.50007 16.6615L7.5 16.6335C7.5 13.7317 9.82563 11.3793 12.6944 11.3793L12.7221 11.3794L12.75 11.3793C13.5552 11.3793 14.3181 11.5627 15 11.8903V7.58621H9.75C9.33579 7.58621 9 7.24656 9 6.82759V1.51724H2.625C1.97453 1.51724 1.5 2.02164 1.5 2.57931V17.1448C1.5 17.7025 1.97456 18.2069 2.62503 18.2069L2.625 18.9655L2.6258 19.7241C1.20521 19.7241 0 18.5982 0 17.1448V2.57931ZM13.7608 6.06897L10.5 2.49405V6.06897H13.7608ZM9.0001 16.6615C9.01502 14.589 10.6732 12.9117 12.7221 12.8967C14.7498 12.9117 16.3889 14.579 16.3889 16.6335C16.3889 18.6973 14.7348 20.3704 12.6944 20.3704C10.6633 20.3704 9.01498 18.7124 9.0001 16.6615Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8306 16.1014C14.8306 15.7525 14.5448 15.4698 14.1923 15.4698C13.8398 15.4698 13.554 15.7525 13.554 16.1014V18.2534C13.554 18.5065 13.7067 18.7352 13.942 18.8344L15.7072 19.5789C16.0315 19.7157 16.4064 19.5664 16.5446 19.2455C16.6829 18.9247 16.532 18.5537 16.2077 18.4169L14.8306 17.8361V16.1014Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.65263C0 1.16357 1.26317 0 2.76596 0H11.0638C11.242 0 11.4121 0.0737112 11.533 0.203306L18.1287 7.27699C18.2375 7.39364 18.2979 7.54654 18.2979 7.70526V14.2772C19.3489 15.3073 20 16.7363 20 18.3158C20 21.4551 17.428 24 14.2553 24C12.2636 24 10.5086 22.997 9.47801 21.4737H2.76629L2.76599 20.2105H8.83752C8.62583 19.6179 8.51064 18.9802 8.51064 18.3158L8.51072 18.2845L8.51064 18.2534C8.51064 15.1486 11.0544 12.6316 14.1923 12.6316L14.2237 12.6317L14.2553 12.6316C15.2581 12.6316 16.2008 12.8858 17.0213 13.3326V8H10.8511C10.4985 8 10.2128 7.71723 10.2128 7.36842V1.26316H2.76596C1.9186 1.26316 1.2766 1.9093 1.2766 2.65263V18.8211C1.2766 19.5644 1.91863 20.2105 2.76599 20.2105L2.76629 21.4737C1.2635 21.4737 0 20.3101 0 18.8211V2.65263ZM15.8881 6.73684L11.4894 2.01934V6.73684H15.8881ZM9.78734 18.2845C9.80428 15.8676 11.7811 13.9116 14.2237 13.8948C16.6421 13.9116 18.5973 15.8566 18.5973 18.2534C18.5973 20.6606 16.6251 22.6121 14.1923 22.6121C11.7699 22.6121 9.80423 20.6774 9.78734 18.2845Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5382 20.1267C18.5382 19.6907 18.181 19.3372 17.7403 19.3372C17.2997 19.3372 16.9425 19.6907 16.9425 20.1267V22.8168C16.9425 23.1331 17.1334 23.419 17.4275 23.543L19.634 24.4736C20.0393 24.6446 20.508 24.458 20.6808 24.0569C20.8536 23.6558 20.665 23.1921 20.2597 23.0211L18.5382 22.2951V20.1267Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.31579C0 1.45447 1.57896 0 3.45745 0H13.8298C14.0525 0 14.2652 0.092139 14.4162 0.254133L22.6609 9.09624C22.7968 9.24205 22.8723 9.43318 22.8723 9.63158V17.8465C24.1862 19.1341 25 20.9204 25 22.8947C25 26.8189 21.785 30 17.8191 30C15.3295 30 13.1357 28.7463 11.8475 26.8421H3.45786L3.45749 25.2632H11.0469C10.7823 24.5224 10.6383 23.7252 10.6383 22.8947L10.6384 22.8556L10.6383 22.8168C10.6383 18.9357 13.818 15.7895 17.7403 15.7895L17.7796 15.7896L17.8191 15.7895C19.0726 15.7895 20.251 16.1072 21.2766 16.6658V10H13.5638C13.1232 10 12.766 9.64654 12.766 9.21053V1.57895H3.45745C2.39825 1.57895 1.59574 2.38662 1.59574 3.31579V23.5263C1.59574 24.4555 2.39829 25.2632 3.45749 25.2632L3.45786 26.8421C1.57937 26.8421 0 25.3876 0 23.5263V3.31579ZM19.8601 8.42105L14.3617 2.52418V8.42105H19.8601ZM12.2342 22.8556C12.2553 19.8346 14.7264 17.3895 17.7796 17.3686C20.8026 17.3894 23.2467 19.8207 23.2467 22.8168C23.2467 25.8258 20.7814 28.2651 17.7403 28.2651C14.7124 28.2651 12.2553 25.8468 12.2342 22.8556Z"
        fill={color}
      />
    </svg>
  )
};
