import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 1H2.25C1.91848 1 1.60054 1.12643 1.36612 1.35147C1.1317 1.57652 1 1.88174 1 2.2V11.8C1 12.1183 1.1317 12.4235 1.36612 12.6485C1.60054 12.8736 1.91848 13 2.25 13H9.75C10.0815 13 10.3995 12.8736 10.6339 12.6485C10.8683 12.4235 11 12.1183 11 11.8V4.6M7.25 1L11 4.6M7.25 1V4.6H11M8.5 7.6H3.5M8.5 10H3.5M4.75 5.2H3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 1H2.5C2.10218 1 1.72064 1.1475 1.43934 1.41005C1.15804 1.6726 1 2.0287 1 2.4V13.6C1 13.9713 1.15804 14.3274 1.43934 14.5899C1.72064 14.8525 2.10218 15 2.5 15H11.5C11.8978 15 12.2794 14.8525 12.5607 14.5899C12.842 14.3274 13 13.9713 13 13.6V5.2M8.5 1L13 5.2M8.5 1V5.2H13M10 8.7H4M10 11.5H4M5.5 5.9H4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 1H3.125C2.69402 1 2.2807 1.16857 1.97595 1.46863C1.6712 1.76869 1.5 2.17565 1.5 2.6V15.4C1.5 15.8243 1.6712 16.2313 1.97595 16.5314C2.2807 16.8314 2.69402 17 3.125 17H12.875C13.306 17 13.7193 16.8314 14.024 16.5314C14.3288 16.2313 14.5 15.8243 14.5 15.4V5.8M9.625 1L14.5 5.8M9.625 1V5.8H14.5M11.25 9.8H4.75M11.25 13H4.75M6.375 6.6H4.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 1H3.25C2.65326 1 2.08097 1.23178 1.65901 1.64436C1.23705 2.05695 1 2.61652 1 3.2V20.8C1 21.3835 1.23705 21.9431 1.65901 22.3556C2.08097 22.7682 2.65326 23 3.25 23H16.75C17.3467 23 17.919 22.7682 18.341 22.3556C18.7629 21.9431 19 21.3835 19 20.8V7.6M12.25 1L19 7.6M12.25 1V7.6H19M14.5 13.1H5.5M14.5 17.5H5.5M7.75 8.7H5.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 1H3.5C2.83696 1 2.20107 1.25286 1.73223 1.70294C1.26339 2.15303 1 2.76348 1 3.4V22.6C1 23.2365 1.26339 23.847 1.73223 24.2971C2.20107 24.7471 2.83696 25 3.5 25H18.5C19.163 25 19.7989 24.7471 20.2678 24.2971C20.7366 23.847 21 23.2365 21 22.6V8.2M13.5 1L21 8.2M13.5 1V8.2H21M16 14.2H6M16 19H6M8.5 9.4H6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4V28C1 28.7956 1.31607 29.5587 1.87868 30.1213C2.44129 30.6839 3.20435 31 4 31H22C22.7956 31 23.5587 30.6839 24.1213 30.1213C24.6839 29.5587 25 28.7956 25 28V10M16 1L25 10M16 1V10H25M19 17.5H7M19 23.5H7M10 11.5H7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
