import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13V11.6667C13 10.9594 12.6839 10.2811 12.1213 9.78105C11.5587 9.28095 10.7956 9 10 9H4C3.20435 9 2.44129 9.28095 1.87868 9.78105C1.31607 10.2811 1 10.9594 1 11.6667V13M10 3.66667C10 5.13943 8.65685 6.33333 7 6.33333C5.34315 6.33333 4 5.13943 4 3.66667C4 2.19391 5.34315 1 7 1C8.65685 1 10 2.19391 10 3.66667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15V13.4444C15 12.6193 14.6313 11.828 13.9749 11.2446C13.3185 10.6611 12.4283 10.3333 11.5 10.3333H4.5C3.57174 10.3333 2.6815 10.6611 2.02513 11.2446C1.36875 11.828 1 12.6193 1 13.4444V15M11.5 4.11111C11.5 5.82933 9.933 7.22222 8 7.22222C6.067 7.22222 4.5 5.82933 4.5 4.11111C4.5 2.39289 6.067 1 8 1C9.933 1 11.5 2.39289 11.5 4.11111Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17V15.2222C17 14.2792 16.5786 13.3749 15.8284 12.7081C15.0783 12.0413 14.0609 11.6667 13 11.6667H5C3.93913 11.6667 2.92172 12.0413 2.17157 12.7081C1.42143 13.3749 1 14.2792 1 15.2222V17M13 4.55556C13 6.51923 11.2091 8.11111 9 8.11111C6.79086 8.11111 5 6.51923 5 4.55556C5 2.59188 6.79086 1 9 1C11.2091 1 13 2.59188 13 4.55556Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 23V20.5556C23 19.2589 22.4205 18.0154 21.3891 17.0986C20.3576 16.1817 18.9587 15.6667 17.5 15.6667H6.5C5.04131 15.6667 3.64236 16.1817 2.61091 17.0986C1.57946 18.0154 1 19.2589 1 20.5556V23M17.5 5.88889C17.5 8.58895 15.0376 10.7778 12 10.7778C8.96243 10.7778 6.5 8.58895 6.5 5.88889C6.5 3.18883 8.96243 1 12 1C15.0376 1 17.5 3.18883 17.5 5.88889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 25V22.3333C25 20.9188 24.3679 19.5623 23.2426 18.5621C22.1174 17.5619 20.5913 17 19 17H7C5.4087 17 3.88258 17.5619 2.75736 18.5621C1.63214 19.5623 1 20.9188 1 22.3333V25M19 6.33333C19 9.27885 16.3137 11.6667 13 11.6667C9.68629 11.6667 7 9.27885 7 6.33333C7 3.38781 9.68629 1 13 1C16.3137 1 19 3.38781 19 6.33333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 31V27.6667C31 25.8986 30.2098 24.2029 28.8033 22.9526C27.3968 21.7024 25.4891 21 23.5 21H8.5C6.51088 21 4.60322 21.7024 3.1967 22.9526C1.79018 24.2029 1 25.8986 1 27.6667V31M23.5 7.66667C23.5 11.3486 20.1421 14.3333 16 14.3333C11.8579 14.3333 8.5 11.3486 8.5 7.66667C8.5 3.98477 11.8579 1 16 1C20.1421 1 23.5 3.98477 23.5 7.66667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
