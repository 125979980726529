import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8.17022H1.2766V12H0.638298C0.285776 12 0 11.7153 0 11.3611V8.17022Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.82983 10.7234L3.82983 12H0.63891C0.286075 12 4.63128e-05 11.7135 4.63128e-05 11.3617V10.7234H3.82983Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 8.17022H10.7234V12H11.3617C11.7142 12 12 11.7153 12 11.3611V8.17022Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17017 10.7234V12H11.3611C11.7139 12 12 11.7135 12 11.3617V10.7234H8.17017Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3.82978H1.2766V-7.09295e-06H0.638298C0.285776 -7.09295e-06 0 0.284721 0 0.638857V3.82978Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.82983 1.2766L3.82983 1.78814e-07L0.638911 1.78814e-07C0.286076 1.78814e-07 4.63128e-05 0.286493 4.63128e-05 0.638298V1.2766L3.82983 1.2766Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3.82978H10.7234V-7.09295e-06H11.3617C11.7142 -7.09295e-06 12 0.284721 12 0.638857V3.82978Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.17017 1.2766V1.78814e-07L11.3611 1.78814e-07C11.7139 1.78814e-07 12 0.286493 12 0.638298V1.2766L8.17017 1.2766Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9.53193H1.48936V14H0.744681C0.333405 14 0 13.6678 0 13.2547V9.53193Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46814 12.5106V14H0.745395C0.333754 14 5.41806e-05 13.6658 5.41806e-05 13.2553V12.5106H4.46814Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 9.53193H12.5106V14H13.2553C13.6666 14 14 13.6678 14 13.2547V9.53193Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53186 12.5106V14H13.2546C13.6662 14 13.9999 13.6658 13.9999 13.2553V12.5106H9.53186Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 4.46807H1.48936V-1.06692e-05H0.744681C0.333405 -1.06692e-05 0 0.332172 0 0.745331V4.46807Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46814 1.48936V-4.82798e-06L0.745396 -4.82798e-06C0.333755 -4.82798e-06 5.41806e-05 0.334237 5.41806e-05 0.744676V1.48936L4.46814 1.48936Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 4.46807H12.5106V-1.06692e-05H13.2553C13.6666 -1.06692e-05 14 0.332172 14 0.745331V4.46807Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53186 1.48936V-4.82798e-06L13.2546 -4.82798e-06C13.6662 -4.82798e-06 13.9999 0.334237 13.9999 0.744676V1.48936L9.53186 1.48936Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.8936H1.70213V16H0.851064C0.381034 16 0 15.6204 0 15.1482V10.8936Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.10645 14.2979V16H0.85188C0.381434 16 6.20484e-05 15.618 6.20484e-05 15.1489V14.2979L5.10645 14.2979Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 10.8936H14.2979V16H15.1489C15.619 16 16 15.6204 16 15.1482V10.8936Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8936 14.2979V16H15.1481C15.6186 16 15.9999 15.618 15.9999 15.1489V14.2979L10.8936 14.2979Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5.10637H1.70213V-1.04308e-05H0.851064C0.381034 -1.04308e-05 0 0.379627 0 0.851809V5.10637Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.10645 1.70213V-2.20537e-06L0.851881 -2.20537e-06C0.381434 -2.20537e-06 6.20484e-05 0.381988 6.20484e-05 0.851062V1.70213L5.10645 1.70213Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 5.10637H14.2979V-1.04308e-05H15.1489C15.619 -1.04308e-05 16 0.379627 16 0.851809V5.10637Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8936 1.70213V-2.20537e-06L15.1481 -2.20537e-06C15.6186 -2.20537e-06 15.9999 0.381988 15.9999 0.851062V1.70213L10.8936 1.70213Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 14.9787H2.34043V22H1.17021C0.523922 22 0 21.478 0 20.8288V14.9787Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.02136 19.6596V22H1.17134C0.524471 22 8.52048e-05 21.4748 8.52048e-05 20.8298V19.6596H7.02136Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 14.9787H19.6596V22H20.8298C21.4761 22 22 21.478 22 20.8288V14.9787Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9786 19.6596V22H20.8287C21.4755 22 21.9999 21.4748 21.9999 20.8298V19.6596H14.9786Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7.02126H2.34043V-1.39773e-05H1.17021C0.523922 -1.39773e-05 0 0.521987 0 1.17124V7.02126Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.02136 2.34042V-2.11596e-06L1.17134 -2.11596e-06C0.524472 -2.11596e-06 8.52048e-05 0.525235 8.52048e-05 1.17021V2.34042L7.02136 2.34042Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 7.02126H19.6596V-1.39773e-05H20.8298C21.4761 -1.39773e-05 22 0.521987 22 1.17124V7.02126Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9786 2.34042V-2.11596e-06L20.8287 -2.11596e-06C21.4755 -2.11596e-06 21.9999 0.525235 21.9999 1.17021V2.34042L14.9786 2.34042Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16.3404H2.55319V24H1.2766C0.571551 24 0 23.4306 0 22.7223V16.3404Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.65967 21.4468L7.65967 24H1.27782C0.57215 24 9.26256e-05 23.427 9.26256e-05 22.7234V21.4468H7.65967Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 16.3404H21.4468V24H22.7234C23.4284 24 24 23.4306 24 22.7223V16.3404Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3403 21.4468V24H22.7222C23.4278 24 23.9999 23.427 23.9999 22.7234V21.4468H16.3403Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7.65956H2.55319V-1.80006e-05H1.2766C0.571551 -1.80006e-05 0 0.569438 0 1.27771V7.65956Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.65967 2.55318L7.65967 -7.27177e-06L1.27782 -7.27177e-06C0.572151 -7.27177e-06 9.26256e-05 0.572979 9.26256e-05 1.27659V2.55318L7.65967 2.55318Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 7.65956H21.4468V-1.80006e-05H22.7234C23.4284 -1.80006e-05 24 0.569438 24 1.27771V7.65956Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3403 2.55318V-7.27177e-06L22.7222 -7.27177e-06C23.4278 -7.27177e-06 23.9999 0.572979 23.9999 1.27659V2.55318L16.3403 2.55318Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 21.7873H3.40426V32H1.70213C0.762069 32 0 31.2408 0 30.2964V21.7873Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2129 28.5958V32H1.70376C0.762868 32 0.000124097 31.236 0.000124097 30.2979V28.5958L10.2129 28.5958Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 21.7873H28.5957V32H30.2979C31.2379 32 32 31.2408 32 30.2964V21.7873Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7871 28.5958V32H30.2962C31.2371 32 31.9999 31.236 31.9999 30.2979V28.5958L21.7871 28.5958Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.2127H3.40426V-2.2769e-05H1.70213C0.762069 -2.2769e-05 0 0.759251 0 1.70362V10.2127Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2129 3.40425V-6.31809e-06L1.70376 -6.31809e-06C0.762869 -6.31809e-06 0.000124097 0.763975 0.000124097 1.70212V3.40425L10.2129 3.40425Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32 10.2127H28.5957V-2.2769e-05H30.2979C31.2379 -2.2769e-05 32 0.759251 32 1.70362V10.2127Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.7871 3.40425V-6.31809e-06L30.2962 -6.31809e-06C31.2371 -6.31809e-06 31.9999 0.763975 31.9999 1.70212V3.40425L21.7871 3.40425Z"
        fill={color}
      />
    </svg>
  )
};
