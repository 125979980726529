import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8 8.8H2.2C1.88174 8.8 1.57652 8.67357 1.35147 8.44853C1.12643 8.22348 1 7.91826 1 7.6V2.2C1 1.88174 1.12643 1.57652 1.35147 1.35147C1.57652 1.12643 1.88174 1 2.2 1H7.6C7.91826 1 8.22348 1.12643 8.44853 1.35147C8.67357 1.57652 8.8 1.88174 8.8 2.2V2.8M6.4 5.2H11.8C12.4627 5.2 13 5.73726 13 6.4V11.8C13 12.4627 12.4627 13 11.8 13H6.4C5.73726 13 5.2 12.4627 5.2 11.8V6.4C5.2 5.73726 5.73726 5.2 6.4 5.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1 10.1H2.4C2.0287 10.1 1.6726 9.9525 1.41005 9.68995C1.1475 9.4274 1 9.0713 1 8.7V2.4C1 2.0287 1.1475 1.6726 1.41005 1.41005C1.6726 1.1475 2.0287 1 2.4 1H8.7C9.0713 1 9.4274 1.1475 9.68995 1.41005C9.9525 1.6726 10.1 2.0287 10.1 2.4V3.1M7.3 5.9H13.6C14.3732 5.9 15 6.5268 15 7.3V13.6C15 14.3732 14.3732 15 13.6 15H7.3C6.5268 15 5.9 14.3732 5.9 13.6V7.3C5.9 6.5268 6.5268 5.9 7.3 5.9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4 11.4H2.6C2.17565 11.4 1.76869 11.2314 1.46863 10.9314C1.16857 10.6313 1 10.2243 1 9.8V2.6C1 2.17565 1.16857 1.76869 1.46863 1.46863C1.76869 1.16857 2.17565 1 2.6 1H9.8C10.2243 1 10.6313 1.16857 10.9314 1.46863C11.2314 1.76869 11.4 2.17565 11.4 2.6V3.4M8.2 6.6H15.4C16.2837 6.6 17 7.31634 17 8.2V15.4C17 16.2837 16.2837 17 15.4 17H8.2C7.31634 17 6.6 16.2837 6.6 15.4V8.2C6.6 7.31634 7.31634 6.6 8.2 6.6Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3 15.3H3.2C2.61652 15.3 2.05695 15.0682 1.64436 14.6556C1.23178 14.2431 1 13.6835 1 13.1V3.2C1 2.61652 1.23178 2.05695 1.64436 1.64436C2.05695 1.23178 2.61652 1 3.2 1H13.1C13.6835 1 14.2431 1.23178 14.6556 1.64436C15.0682 2.05695 15.3 2.61652 15.3 3.2V4.3M10.9 8.7H20.8C22.015 8.7 23 9.68497 23 10.9V20.8C23 22.015 22.015 23 20.8 23H10.9C9.68497 23 8.7 22.015 8.7 20.8V10.9C8.7 9.68497 9.68497 8.7 10.9 8.7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6 16.6H3.4C2.76348 16.6 2.15303 16.3471 1.70294 15.8971C1.25286 15.447 1 14.8365 1 14.2V3.4C1 2.76348 1.25286 2.15303 1.70294 1.70294C2.15303 1.25286 2.76348 1 3.4 1H14.2C14.8365 1 15.447 1.25286 15.8971 1.70294C16.3471 2.15303 16.6 2.76348 16.6 3.4V4.6M11.8 9.4H22.6C23.9255 9.4 25 10.4745 25 11.8V22.6C25 23.9255 23.9255 25 22.6 25H11.8C10.4745 25 9.4 23.9255 9.4 22.6V11.8C9.4 10.4745 10.4745 9.4 11.8 9.4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 20.5H4C3.20435 20.5 2.44129 20.1839 1.87868 19.6213C1.31607 19.0587 1 18.2956 1 17.5V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H17.5C18.2956 1 19.0587 1.31607 19.6213 1.87868C20.1839 2.44129 20.5 3.20435 20.5 4V5.5M14.5 11.5H28C29.6569 11.5 31 12.8431 31 14.5V28C31 29.6569 29.6569 31 28 31H14.5C12.8431 31 11.5 29.6569 11.5 28V14.5C11.5 12.8431 12.8431 11.5 14.5 11.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
