import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 7V11.8C1.5 12.1183 1.61853 12.4235 1.8295 12.6485C2.04048 12.8736 2.32663 13 2.625 13H9.375C9.67337 13 9.95952 12.8736 10.1705 12.6485C10.3815 12.4235 10.5 12.1183 10.5 11.8V7M8.25 3.4L6 1M6 1L3.75 3.4M6 1V8.8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8V13.6C1 13.9713 1.14487 14.3274 1.40273 14.5899C1.66059 14.8525 2.01033 15 2.375 15H10.625C10.9897 15 11.3394 14.8525 11.5973 14.5899C11.8551 14.3274 12 13.9713 12 13.6V8M9.25 3.8L6.5 1M6.5 1L3.75 3.8M6.5 1V10.1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 9V15.4C1.5 15.8243 1.6712 16.2313 1.97595 16.5314C2.2807 16.8314 2.69402 17 3.125 17H12.875C13.306 17 13.7193 16.8314 14.024 16.5314C14.3288 16.2313 14.5 15.8243 14.5 15.4V9M11.25 4.2L8 1M8 1L4.75 4.2M8 1V11.4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12V20.8C1 21.3835 1.23705 21.9431 1.65901 22.3556C2.08097 22.7682 2.65326 23 3.25 23H16.75C17.3467 23 17.919 22.7682 18.341 22.3556C18.7629 21.9431 19 21.3835 19 20.8V12M14.5 5.4L10 1M10 1L5.5 5.4M10 1V15.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 13V22.6C1.5 23.2365 1.75022 23.847 2.19562 24.2971C2.64102 24.7471 3.24511 25 3.875 25H18.125C18.7549 25 19.359 24.7471 19.8044 24.2971C20.2498 23.847 20.5 23.2365 20.5 22.6V13M15.75 5.8L11 1M11 1L6.25 5.8M11 1V16.6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16V28C1 28.7956 1.31607 29.5587 1.87868 30.1213C2.44129 30.6839 3.20435 31 4 31H22C22.7956 31 23.5587 30.6839 24.1213 30.1213C24.6839 29.5587 25 28.7956 25 28V16M19 7L13 1M13 1L7 7M13 1V20.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
