import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2728 7.63414H12.1819C12.5455 7.63414 13.1637 7.52505 12.7273 7.08868L8.6364 1.90686C8.45458 1.63414 8.0364 1.25232 7.81822 1.90686L7.54549 3.54324L6.86367 4.08869M4.81822 5.72505L2.09095 5.99778C1.8565 5.99778 1.47372 6.10686 1.81822 6.54323L3.86367 9.13414M3.86367 9.13414L5.90913 11.725C6.09095 11.9978 6.50913 12.3796 6.72731 11.725L7.54549 9.81595L8.56822 8.99777M3.86367 9.13414L1 11.4523M1 1L13 13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8182 8.73983H14.0455C14.4697 8.73983 15.191 8.61255 14.6819 8.10346L9.90913 2.05801C9.69701 1.73982 9.20913 1.29437 8.95459 2.05801L8.63641 3.96711L7.84095 4.60347M5.45459 6.51256L2.27277 6.83074C1.99924 6.83074 1.55267 6.95801 1.95459 7.4671L4.34095 10.4898M4.34095 10.4898L6.72731 13.5126C6.93944 13.8307 7.42732 14.2762 7.68186 13.5126L8.63641 11.2853L9.82959 10.3307M4.34095 10.4898L1 13.1944M1 1L15 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09096 7.30007L2.45459 7.66371C2.14199 7.6637 1.63163 7.80915 2.09096 8.39097L4.81823 11.8455M4.81823 11.8455L7.5455 15.3001C7.78793 15.6637 8.3455 16.1728 8.63641 15.3001L9.72732 12.7546L11.091 11.6637M4.81823 11.8455L1 14.9364M12.9091 10.2092L13.3637 9.84551H15.9091C16.394 9.84551 17.2182 9.70006 16.6364 9.11824L11.1819 2.20915C10.9394 1.84551 10.3819 1.33642 10.091 2.20915L9.72732 4.39098L8.81823 5.11825M1 1L17 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00007 9.6626L3.00007 10.1626C2.57024 10.1626 1.86849 10.3626 2.50007 11.1626L6.25007 15.9126M6.25007 15.9126L10.0001 20.6626C10.3334 21.1626 11.1001 21.8626 11.5001 20.6626L13.0001 17.1626L14.8751 15.6626M6.25007 15.9126L1 20.1626M17.3751 13.6626L18.0001 13.1626H21.5001C22.1667 13.1626 23.3001 12.9626 22.5001 12.1626L15.0001 2.66258C14.6667 2.16258 13.9001 1.46258 13.5001 2.66258L13.0001 5.6626L11.7501 6.6626M1 1L23 23"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63644 10.4501L3.18189 10.9956C2.71299 10.9956 1.94744 11.2137 2.63644 12.0865L6.72735 17.2683M6.72735 17.2683L10.8183 22.4501C11.1819 22.9955 12.0183 23.7592 12.4546 22.4501L14.091 18.6319L16.1364 16.9955M6.72735 17.2683L1 21.9047M18.8637 14.8137L19.5455 14.2683H23.3637C24.091 14.2683 25.3273 14.0501 24.4546 13.1774L16.2728 2.81373C15.9092 2.26827 15.0728 1.50463 14.6364 2.81373L14.091 6.08647L12.7273 7.17738M1 1L25 25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5455 12.8126L3.72736 13.4945C3.14124 13.4944 2.1843 13.7672 3.04555 14.8581L8.15918 21.3353M8.15918 21.3353L13.2728 27.8126C13.7274 28.4944 14.7728 29.449 15.3183 27.8126L17.3637 23.0399L19.9205 20.9944M8.15918 21.3353L1 27.1308M23.3296 18.2672L24.1819 17.5853H28.9546C29.8637 17.5853 31.4092 17.3126 30.3183 16.2217L20.091 3.26716C19.6365 2.58534 18.591 1.63079 18.0455 3.26716L17.3637 7.35809L15.6592 8.72172M1 1L31 31"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
