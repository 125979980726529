import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.02515 7.65C4.37032 7.65 4.65015 7.37018 4.65015 7.025C4.65015 6.67982 4.37032 6.4 4.02515 6.4C3.67997 6.4 3.40015 6.67982 3.40015 7.025C3.40015 7.37018 3.67997 7.65 4.02515 7.65Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02515 7.64999C7.37032 7.64999 7.65015 7.37017 7.65015 7.02499C7.65015 6.67982 7.37032 6.39999 7.02515 6.39999C6.67997 6.39999 6.40015 6.67982 6.40015 7.02499C6.40015 7.37017 6.67997 7.64999 7.02515 7.64999Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0251 7.65C10.3703 7.65 10.6501 7.37018 10.6501 7.025C10.6501 6.67982 10.3703 6.4 10.0251 6.4C9.67997 6.4 9.40015 6.67982 9.40015 7.025C9.40015 7.37018 9.67997 7.65 10.0251 7.65Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.04993 8.80002C4.46414 8.80002 4.79993 8.46423 4.79993 8.05002C4.79993 7.6358 4.46414 7.30002 4.04993 7.30002C3.63571 7.30002 3.29993 7.6358 3.29993 8.05002C3.29993 8.46423 3.63571 8.80002 4.04993 8.80002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.94995 8.80001C8.36416 8.80001 8.69995 8.46422 8.69995 8.05001C8.69995 7.6358 8.36416 7.30001 7.94995 7.30001C7.53574 7.30001 7.19995 7.6358 7.19995 8.05001C7.19995 8.46422 7.53574 8.80001 7.94995 8.80001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.85 8.80002C12.2642 8.80002 12.6 8.46423 12.6 8.05002C12.6 7.6358 12.2642 7.30002 11.85 7.30002C11.4358 7.30002 11.1 7.6358 11.1 8.05002C11.1 8.46423 11.4358 8.80002 11.85 8.80002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5498 9.8C4.96402 9.8 5.2998 9.46421 5.2998 9.05C5.2998 8.63578 4.96402 8.3 4.5498 8.3C4.13559 8.3 3.7998 8.63578 3.7998 9.05C3.7998 9.46421 4.13559 9.8 4.5498 9.8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0498 9.8C9.46402 9.8 9.7998 9.46421 9.7998 9.05C9.7998 8.63578 9.46402 8.3 9.0498 8.3C8.63559 8.3 8.2998 8.63578 8.2998 9.05C8.2998 9.46421 8.63559 9.8 9.0498 9.8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5498 9.8C13.964 9.8 14.2998 9.46421 14.2998 9.05C14.2998 8.63578 13.964 8.3 13.5498 8.3C13.1356 8.3 12.7998 8.63578 12.7998 9.05C12.7998 9.46421 13.1356 9.8 13.5498 9.8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12C17 12.5523 17.4477 13 18 13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 14C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12C12.4477 12 12 12.4477 12 13C12 13.5523 12.4477 14 13 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14C20.5523 14 21 13.5523 21 13C21 12.4477 20.5523 12 20 12C19.4477 12 19 12.4477 19 13C19 13.5523 19.4477 14 20 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 17.5C16.9404 17.5 17.5 16.9404 17.5 16.25C17.5 15.5596 16.9404 15 16.25 15C15.5596 15 15 15.5596 15 16.25C15 16.9404 15.5596 17.5 16.25 17.5Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 17.5C25.9404 17.5 26.5 16.9404 26.5 16.25C26.5 15.5596 25.9404 15 25.25 15C24.5596 15 24 15.5596 24 16.25C24 16.9404 24.5596 17.5 25.25 17.5Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
