import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96111 12.402C6.86344 12.5839 6.72325 12.7349 6.55457 12.8398C6.38589 12.9448 6.19466 13 6 13C5.80535 13 5.61411 12.9448 5.44543 12.8398C5.27675 12.7349 5.13656 12.5839 5.03889 12.402M9.33333 4.60064C9.33333 3.64569 8.98214 2.72985 8.35702 2.0546C7.7319 1.37935 6.88406 1 6 1C5.11595 1 4.2681 1.37935 3.64298 2.0546C3.01786 2.72985 2.66667 3.64569 2.66667 4.60064C2.66667 8.80138 1 10.0016 1 10.0016H11C11 10.0016 9.33333 8.80138 9.33333 4.60064Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.15333 14.3024C8.03613 14.5145 7.8679 14.6907 7.66548 14.8131C7.46307 14.9356 7.23359 15 7 15C6.76641 15 6.53693 14.9356 6.33452 14.8131C6.13211 14.6907 5.96387 14.5145 5.84667 14.3024M11 5.20074C11 4.08664 10.5786 3.01816 9.82843 2.23037C9.07828 1.44258 8.06087 1 7 1C5.93913 1 4.92172 1.44258 4.17157 2.23037C3.42143 3.01816 3 4.08664 3 5.20074C3 10.1016 1 11.5019 1 11.5019H13C13 11.5019 11 10.1016 11 5.20074Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34556 16.2027C9.20881 16.4452 9.01254 16.6465 8.7764 16.7864C8.54025 16.9263 8.27252 17 8 17C7.72748 17 7.45975 16.9263 7.2236 16.7864C6.98746 16.6465 6.79119 16.4452 6.65444 16.2027M12.6667 5.80085C12.6667 4.52759 12.175 3.30647 11.2998 2.40614C10.4247 1.5058 9.23768 1 8 1C6.76232 1 5.57534 1.5058 4.70017 2.40614C3.825 3.30647 3.33333 4.52759 3.33333 5.80085C3.33333 11.4018 1 13.0021 1 13.0021H15C15 13.0021 12.6667 11.4018 12.6667 5.80085Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9222 21.9037C12.7269 22.2371 12.4465 22.5139 12.1091 22.7063C11.7718 22.8987 11.3893 23 11 23C10.6107 23 10.2282 22.8987 9.89086 22.7063C9.55351 22.5139 9.27312 22.2371 9.07778 21.9037M17.6667 7.60117C17.6667 5.85043 16.9643 4.1714 15.714 2.93344C14.4638 1.69548 12.7681 1 11 1C9.23189 1 7.5362 1.69548 6.28596 2.93344C5.03571 4.1714 4.33333 5.85043 4.33333 7.60117C4.33333 15.3025 1 17.5029 1 17.5029H21C21 17.5029 17.6667 15.3025 17.6667 7.60117Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0183 23.804C13.8132 24.1678 13.5188 24.4697 13.1646 24.6796C12.8104 24.8895 12.4088 25 12 25C11.5912 25 11.1896 24.8895 10.8354 24.6796C10.4812 24.4697 10.1868 24.1678 9.98167 23.804M19 8.20128C19 6.29138 18.2625 4.45971 16.9497 3.1092C15.637 1.7587 13.8565 1 12 1C10.1435 1 8.36301 1.7587 7.05025 3.1092C5.7375 4.45971 5 6.29138 5 8.20128C5 16.6028 1.5 19.0032 1.5 19.0032H22.5C22.5 19.0032 19 16.6028 19 8.20128Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.095 29.5051C16.8313 29.9597 16.4528 30.3372 15.9973 30.5995C15.5419 30.8619 15.0256 31 14.5 31C13.9744 31 13.4581 30.8619 13.0027 30.5995C12.5472 30.3372 12.1687 29.9597 11.905 29.5051M23.5 10.0016C23.5 7.61422 22.5518 5.32463 20.864 3.63651C19.1761 1.94838 16.8869 1 14.5 1C12.1131 1 9.82387 1.94838 8.13604 3.63651C6.44821 5.32463 5.5 7.61422 5.5 10.0016C5.5 20.5035 1 23.504 1 23.504H28C28 23.504 23.5 20.5035 23.5 10.0016Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
