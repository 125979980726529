import { IIconOption } from './_NiagaraIconProps';

const DefaultColor = '#303030';
const DefaultSize = 14;

interface IIconSwitch {
  color?: string;
  size?: number;
  icon: { [key: string]: IIconOption };
}

export function IconSwitch({ color, size, icon }: IIconSwitch) {
  const Icon =
    icon[
      getIconSize(
        size ?? DefaultSize,
        Object.keys(icon).map((x) => parseInt(x))
      )
    ];
  return Icon({ color: color ?? DefaultColor });
}

function getIconSize(size: number, sizeOptions: number[]) {
  const result =
    sizeOptions.sort().find((sizeOption) => sizeOption >= size) ??
    sizeOptions[sizeOptions.length - 1];
  return result;
}
