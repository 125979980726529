import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="6.78125"
        fill="white"
        stroke="#909090"
        strokeWidth="0.4375"
      ></circle>
      <path
        d="M9.625 9.625V9.04167C9.625 8.73225 9.48672 8.4355 9.24058 8.21671C8.99444 7.99792 8.6606 7.875 8.3125 7.875H5.6875C5.3394 7.875 5.00556 7.99792 4.75942 8.21671C4.51328 8.4355 4.375 8.73225 4.375 9.04167V9.625M8.3125 5.54167C8.3125 6.186 7.72487 6.70833 7 6.70833C6.27513 6.70833 5.6875 6.186 5.6875 5.54167C5.6875 4.89733 6.27513 4.375 7 4.375C7.72487 4.375 8.3125 4.89733 8.3125 5.54167Z"
        stroke={color}
        strokeWidth="0.65625"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.75"
        fill="white"
        stroke="#909090"
        strokeWidth="0.5"
      ></circle>
      <path
        d="M11 11V10.3333C11 9.97971 10.842 9.64057 10.5607 9.39052C10.2794 9.14048 9.89782 9 9.5 9H6.5C6.10218 9 5.72064 9.14048 5.43934 9.39052C5.15804 9.64057 5 9.97971 5 10.3333V11M9.5 6.33333C9.5 7.06971 8.82843 7.66667 8 7.66667C7.17157 7.66667 6.5 7.06971 6.5 6.33333C6.5 5.59695 7.17157 5 8 5C8.82843 5 9.5 5.59695 9.5 6.33333Z"
        stroke={color}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="9"
        r="8.71875"
        fill="white"
        stroke="#909090"
        strokeWidth="0.5625"
      ></circle>
      <path
        d="M12.375 12.375V11.625C12.375 11.2272 12.1972 10.8456 11.8807 10.5643C11.5643 10.283 11.1351 10.125 10.6875 10.125H7.3125C6.86495 10.125 6.43572 10.283 6.11926 10.5643C5.80279 10.8456 5.625 11.2272 5.625 11.625V12.375M10.6875 7.125C10.6875 7.95343 9.93198 8.625 9 8.625C8.06802 8.625 7.3125 7.95343 7.3125 7.125C7.3125 6.29657 8.06802 5.625 9 5.625C9.93198 5.625 10.6875 6.29657 10.6875 7.125Z"
        stroke={color}
        strokeWidth="0.84375"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.625"
        fill="white"
        stroke="#909090"
        strokeWidth="0.75"
      ></circle>
      <path
        d="M16.5 16.5V15.5C16.5 14.9696 16.2629 14.4609 15.841 14.0858C15.419 13.7107 14.8467 13.5 14.25 13.5H9.75C9.15326 13.5 8.58097 13.7107 8.15901 14.0858C7.73705 14.4609 7.5 14.9696 7.5 15.5V16.5M14.25 9.5C14.25 10.6046 13.2426 11.5 12 11.5C10.7574 11.5 9.75 10.6046 9.75 9.5C9.75 8.39543 10.7574 7.5 12 7.5C13.2426 7.5 14.25 8.39543 14.25 9.5Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="12.5938"
        fill="white"
        stroke="#909090"
        strokeWidth="0.8125"
      ></circle>
      <path
        d="M17.875 17.875V16.7917C17.875 16.217 17.6182 15.6659 17.1611 15.2596C16.704 14.8533 16.084 14.625 15.4375 14.625H10.5625C9.91603 14.625 9.29605 14.8533 8.83893 15.2596C8.38181 15.6659 8.125 16.217 8.125 16.7917V17.875M15.4375 10.2917C15.4375 11.4883 14.3462 12.4583 13 12.4583C11.6538 12.4583 10.5625 11.4883 10.5625 10.2917C10.5625 9.09505 11.6538 8.125 13 8.125C14.3462 8.125 15.4375 9.09505 15.4375 10.2917Z"
        stroke={color}
        strokeWidth="1.21875"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#909090"></circle>
      <path
        d="M22 22V20.6667C22 19.9594 21.6839 19.2811 21.1213 18.781C20.5587 18.281 19.7956 18 19 18H13C12.2044 18 11.4413 18.281 10.8787 18.781C10.3161 19.2811 10 19.9594 10 20.6667V22M19 12.6667C19 14.1394 17.6569 15.3333 16 15.3333C14.3431 15.3333 13 14.1394 13 12.6667C13 11.1939 14.3431 10 16 10C17.6569 10 19 11.1939 19 12.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
};
