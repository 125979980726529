import React from 'react';

export default {
  32: ({ color }: { color: string }) => (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.46883 0.5C4.7688 0.5 4.19196 0.992859 4.19196 1.59085V1.95445H1.42571C1.19059 1.95458 1.00015 2.1173 1 2.31819V3.77264C1.00015 3.97354 1.19059 4.13625 1.42571 4.13638H4.19196V4.68174H2.27686C2.04189 4.68186 1.8513 4.84458 1.8513 5.04547V6.49993C1.8513 6.70082 2.0419 6.86353 2.27686 6.86366H4.19196V9.03406C2.34868 9.50624 1 10.9582 1 12.6818C1 14.7862 3.00581 16.5 5.46875 16.5C7.93169 16.5 9.9375 14.7863 9.9375 12.6818C9.9375 10.9581 8.58882 9.50617 6.74554 9.03406V1.59085C6.74554 0.992859 6.16885 0.5 5.46883 0.5ZM5.46883 1.22721C5.71196 1.22721 5.89438 1.38308 5.89438 1.59082V9.30124C5.89334 9.47423 6.03525 9.62401 6.23356 9.65913C7.86545 9.95692 9.08634 11.1932 9.08634 12.6819C9.08634 14.3933 7.47181 15.7728 5.46882 15.7728C3.46582 15.7728 1.8513 14.3933 1.8513 12.6819C1.8513 11.1932 3.07219 9.95692 4.70408 9.65913C4.90239 9.624 5.04429 9.47421 5.04325 9.30124V6.50002C5.04325 6.29926 4.85266 6.13642 4.6177 6.13642H2.70244V5.40921H4.6177C4.85267 5.40921 5.04325 5.24636 5.04325 5.0456V3.77277C5.04325 3.57201 4.85266 3.40917 4.6177 3.40917H1.85129V2.68196H4.6177C4.85267 2.68196 5.04325 2.51911 5.04325 2.31835V1.59101C5.04325 1.38327 5.2257 1.22721 5.46883 1.22721ZM5.46883 12.5C4.65123 12.5 3.97928 13.0741 3.97928 13.7727C3.97928 14.4714 4.65127 15.0455 5.46883 15.0455C6.28639 15.0455 6.95837 14.4714 6.95837 13.7727C6.95837 13.0741 6.28639 12.5 5.46883 12.5ZM5.46883 13.2273C5.82641 13.2273 6.10726 13.4672 6.10726 13.7727C6.10726 14.0783 5.82642 14.3182 5.46883 14.3182C5.11124 14.3182 4.8304 14.0783 4.8304 13.7727C4.8304 13.4672 5.11124 13.2273 5.46883 13.2273Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  ),
  44: ({ color }: { color: string }) => (
    <svg
      width="20"
      height="42"
      viewBox="0 0 20 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91217 0C8.35946 0 7.08 1.27946 7.08 2.83183V3.77575H0.944253C0.422735 3.77608 0.000337143 4.19849 0 4.72V8.49575C0.00032922 9.01726 0.422744 9.43966 0.944253 9.44H7.08V10.8557H2.83217C2.31098 10.8561 1.88825 11.2785 1.88825 11.8V15.5757C1.88825 16.0973 2.311 16.5197 2.83217 16.52H7.08V22.1543C2.99147 23.3801 0 27.1493 0 31.6238C0 37.0869 4.44902 41.5358 9.912 41.5358C15.375 41.5358 19.824 37.0871 19.824 31.6238C19.824 27.1491 16.8325 23.3799 12.744 22.1543V2.83183C12.744 1.27946 11.4645 0 9.91183 0H9.91217ZM9.91217 1.88783C10.4515 1.88783 10.8561 2.29247 10.8561 2.83175V22.8479C10.8538 23.297 11.1685 23.6858 11.6084 23.777C15.2281 24.5501 17.9361 27.7595 17.9361 31.6242C17.9361 36.067 14.3549 39.6481 9.91215 39.6481C5.46937 39.6481 1.88824 36.067 1.88824 31.6242C1.88824 27.7595 4.59625 24.5501 8.2159 23.777C8.65577 23.6858 8.97052 23.297 8.96822 22.8479V15.576C8.96822 15.0548 8.54548 14.6321 8.0243 14.6321H3.77613V12.7443H8.0243C8.54549 12.7443 8.96822 12.3215 8.96822 11.8003V8.49608C8.96822 7.97489 8.54548 7.55217 8.0243 7.55217H1.88822V5.66434H8.0243C8.54549 5.66434 8.96822 5.24159 8.96822 4.72042V2.83225C8.96822 2.29296 9.37286 1.88834 9.91214 1.88834L9.91217 1.88783ZM9.91217 31.1518C8.09868 31.1518 6.60825 32.6423 6.60825 34.4557C6.60825 36.2696 8.09876 37.76 9.91217 37.76C11.7256 37.76 13.2161 36.2695 13.2161 34.4557C13.2161 32.6423 11.7256 31.1518 9.91217 31.1518ZM9.91217 33.04C10.7053 33.04 11.3283 33.6626 11.3283 34.4557C11.3283 35.2492 10.7053 35.8718 9.91217 35.8718C9.11901 35.8718 8.49609 35.2492 8.49609 34.4557C8.49609 33.6626 9.11901 33.04 9.91217 33.04Z"
        fill={color}
      />
    </svg>
  )
};

<></>;
