import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.66667V11.6667C11 12.0203 10.8595 12.3594 10.6095 12.6095C10.3594 12.8595 10.0203 13 9.66667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V4.33333C1 3.97971 1.14048 3.64057 1.39052 3.39052C1.64057 3.14048 1.97971 3 2.33333 3H6.33333M9 1H13M13 1V5M13 1L5.66667 8.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 8.77778V13.4444C12.6667 13.857 12.5028 14.2527 12.2111 14.5444C11.9193 14.8361 11.5237 15 11.1111 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V4.88889C1 4.47633 1.16389 4.08067 1.45561 3.78894C1.74733 3.49722 2.143 3.33333 2.55556 3.33333H7.22222M10.3333 1H15M15 1V5.66667M15 1L6.44444 9.55556"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3333 9.88889V15.2222C14.3333 15.6937 14.146 16.1459 13.8126 16.4793C13.4792 16.8127 13.0271 17 12.5556 17H2.77778C2.30628 17 1.8541 16.8127 1.5207 16.4793C1.1873 16.1459 1 15.6937 1 15.2222V5.44444C1 4.97295 1.1873 4.52076 1.5207 4.18737C1.8541 3.85397 2.30628 3.66667 2.77778 3.66667H8.11111M11.6667 1H17M17 1V6.33333M17 1L7.22222 10.7778"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3333 13.2222V20.5556C19.3333 21.2039 19.0758 21.8256 18.6174 22.284C18.1589 22.7425 17.5372 23 16.8889 23H3.44444C2.79614 23 2.17438 22.7425 1.71596 22.284C1.25754 21.8256 1 21.2039 1 20.5556V7.11111C1 6.4628 1.25754 5.84105 1.71596 5.38263C2.17438 4.92421 2.79614 4.66667 3.44444 4.66667H10.7778M15.6667 1H23M23 1V8.33333M23 1L9.55556 14.4444"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 14.3333V22.3333C21 23.0406 20.719 23.7189 20.219 24.219C19.7189 24.719 19.0406 25 18.3333 25H3.66667C2.95942 25 2.28115 24.719 1.78105 24.219C1.28095 23.7189 1 23.0406 1 22.3333V7.66667C1 6.95942 1.28095 6.28115 1.78105 5.78105C2.28115 5.28095 2.95942 5 3.66667 5H11.6667M17 1H25M25 1V9M25 1L10.3333 15.6667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 17.6667V27.6667C26 28.5507 25.6488 29.3986 25.0237 30.0237C24.3986 30.6488 23.5507 31 22.6667 31H4.33333C3.44928 31 2.60143 30.6488 1.97631 30.0237C1.35119 29.3986 1 28.5507 1 27.6667V9.33333C1 8.44928 1.35119 7.60143 1.97631 6.97631C2.60143 6.35119 3.44928 6 4.33333 6H14.3333M21 1H31M31 1V11M31 1L12.6667 19.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
