import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.4H2.11111M2.11111 3.4H11M2.11111 3.4V11.8C2.11111 12.1183 2.22817 12.4235 2.43655 12.6485C2.64492 12.8736 2.92754 13 3.22222 13H8.77778C9.07246 13 9.35508 12.8736 9.56345 12.6485C9.77183 12.4235 9.88889 12.1183 9.88889 11.8V3.4H2.11111ZM3.77778 3.4V2.2C3.77778 1.88174 3.89484 1.57652 4.10322 1.35147C4.31159 1.12643 4.5942 1 4.88889 1H7.11111C7.4058 1 7.68841 1.12643 7.89679 1.35147C8.10516 1.57652 8.22222 1.88174 8.22222 2.2V3.4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.8H2.33333M2.33333 3.8H13M2.33333 3.8V13.6C2.33333 13.9713 2.47381 14.3274 2.72386 14.5899C2.97391 14.8525 3.31304 15 3.66667 15H10.3333C10.687 15 11.0261 14.8525 11.2761 14.5899C11.5262 14.3274 11.6667 13.9713 11.6667 13.6V3.8H2.33333ZM4.33333 3.8V2.4C4.33333 2.0287 4.47381 1.6726 4.72386 1.41005C4.97391 1.1475 5.31304 1 5.66667 1H8.33333C8.68696 1 9.02609 1.1475 9.27614 1.41005C9.52619 1.6726 9.66667 2.0287 9.66667 2.4V3.8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.2H2.55556M2.55556 4.2H15M2.55556 4.2V15.4C2.55556 15.8243 2.71944 16.2313 3.01117 16.5314C3.30289 16.8314 3.69855 17 4.11111 17H11.8889C12.3014 17 12.6971 16.8314 12.9888 16.5314C13.2806 16.2313 13.4444 15.8243 13.4444 15.4V4.2H2.55556ZM4.88889 4.2V2.6C4.88889 2.17565 5.05278 1.76869 5.3445 1.46863C5.63622 1.16857 6.03189 1 6.44444 1H9.55556C9.96811 1 10.3638 1.16857 10.6555 1.46863C10.9472 1.76869 11.1111 2.17565 11.1111 2.6V4.2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.4H3.22222M3.22222 5.4H21M3.22222 5.4V20.8C3.22222 21.3835 3.45635 21.9431 3.8731 22.3556C4.28984 22.7682 4.85507 23 5.44444 23H16.5556C17.1449 23 17.7102 22.7682 18.1269 22.3556C18.5437 21.9431 18.7778 21.3835 18.7778 20.8V5.4H3.22222ZM6.55556 5.4V3.2C6.55556 2.61652 6.78968 2.05695 7.20643 1.64436C7.62318 1.23178 8.18841 1 8.77778 1H13.2222C13.8116 1 14.3768 1.23178 14.7936 1.64436C15.2103 2.05695 15.4444 2.61652 15.4444 3.2V5.4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5.8H3.83333M3.83333 5.8H22.5M3.83333 5.8V22.6C3.83333 23.2365 4.07917 23.847 4.51675 24.2971C4.95434 24.7471 5.54783 25 6.16667 25H17.8333C18.4522 25 19.0457 24.7471 19.4832 24.2971C19.9208 23.847 20.1667 23.2365 20.1667 22.6V5.8H3.83333ZM7.33333 5.8V3.4C7.33333 2.76348 7.57917 2.15303 8.01675 1.70294C8.45434 1.25286 9.04783 1 9.66667 1H14.3333C14.9522 1 15.5457 1.25286 15.9832 1.70294C16.4208 2.15303 16.6667 2.76348 16.6667 3.4V5.8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7H4M4 7H28M4 7V28C4 28.7956 4.31607 29.5587 4.87868 30.1213C5.44129 30.6839 6.20435 31 7 31H22C22.7956 31 23.5587 30.6839 24.1213 30.1213C24.6839 29.5587 25 28.7956 25 28V7H4ZM8.5 7V4C8.5 3.20435 8.81607 2.44129 9.37868 1.87868C9.94129 1.31607 10.7043 1 11.5 1H17.5C18.2956 1 19.0587 1.31607 19.6213 1.87868C20.1839 2.44129 20.5 3.20435 20.5 4V7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
