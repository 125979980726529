import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.79318 7.60401C6.05232 7.95079 6.38293 8.23773 6.76259 8.44536C7.14224 8.65299 7.56207 8.77647 7.99359 8.8074C8.42512 8.83834 8.85824 8.77601 9.26358 8.62466C9.66892 8.4733 10.037 8.23645 10.3429 7.93017L12.1531 6.11814C12.7027 5.54855 13.0068 4.78567 12.9999 3.99382C12.993 3.20197 12.6757 2.4445 12.1163 1.88456C11.557 1.32461 10.8002 1.007 10.0092 1.00011C9.21813 0.993233 8.45602 1.29764 7.887 1.84777L6.84914 2.88063M8.20682 6.39599C7.94768 6.04921 7.61707 5.76227 7.23741 5.55464C6.85776 5.347 6.43793 5.22353 6.00641 5.1926C5.57488 5.16166 5.14176 5.22399 4.73642 5.37534C4.33108 5.5267 3.96299 5.76355 3.65713 6.06982L1.84692 7.88186C1.29734 8.45145 0.99324 9.21433 1.00011 10.0062C1.00699 10.798 1.32429 11.5555 1.88367 12.1154C2.44305 12.6754 3.19976 12.993 3.99081 12.9999C4.78187 13.0068 5.54398 12.7024 6.113 12.1522L7.14482 11.1194"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.59205 8.70468C6.89437 9.10925 7.28008 9.44402 7.72302 9.68625C8.16595 9.92849 8.65575 10.0725 9.15919 10.1086C9.66263 10.1447 10.1679 10.072 10.6408 9.89543C11.1137 9.71885 11.5432 9.44253 11.9 9.0852L14.0119 6.97116C14.6531 6.30664 15.0079 5.41662 14.9999 4.49279C14.9918 3.56896 14.6217 2.68525 13.9691 2.03198C13.3164 1.37871 12.4336 1.00816 11.5107 1.00013C10.5878 0.992105 9.69869 1.34725 9.03484 1.98906L7.824 3.19407M9.40795 7.29532C9.10563 6.89075 8.71992 6.55598 8.27698 6.31374C7.83405 6.07151 7.34425 5.92746 6.84081 5.89136C6.33736 5.85527 5.83206 5.92798 5.35916 6.10457C4.88626 6.28115 4.45682 6.55747 4.09999 6.9148L1.98807 9.02884C1.3469 9.69336 0.992113 10.5834 1.00013 11.5072C1.00815 12.431 1.37833 13.3147 2.03095 13.968C2.68356 14.6213 3.56639 14.9918 4.48928 14.9999C5.41218 15.0079 6.30131 14.6528 6.96516 14.0109L8.16896 12.8059"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39091 9.80534C7.73642 10.2677 8.17723 10.6503 8.68345 10.9271C9.18966 11.204 9.74943 11.3686 10.3248 11.4099C10.9002 11.4511 11.4776 11.368 12.0181 11.1662C12.5586 10.9644 13.0493 10.6486 13.4572 10.2402L15.8708 7.82418C16.6035 7.06473 17.009 6.04756 16.9998 4.99176C16.9907 3.93596 16.5676 2.926 15.8218 2.17941C15.0759 1.43282 14.067 1.00933 13.0122 1.00015C11.9575 0.990978 10.9414 1.39685 10.1827 2.13036L8.79886 3.50751M10.6091 8.19466C10.2636 7.73228 9.82276 7.3497 9.31655 7.07285C8.81034 6.79601 8.25057 6.63138 7.67521 6.59013C7.09985 6.54888 6.52235 6.63198 5.98189 6.83379C5.44144 7.0356 4.95066 7.3514 4.54285 7.75977L2.12922 10.1758C1.39645 10.9353 0.990987 11.9524 1.00015 13.0082C1.00932 14.064 1.43238 15.074 2.17822 15.8206C2.92407 16.5672 3.93301 16.9907 4.98775 16.9998C6.04249 17.009 7.05864 16.6031 7.81733 15.8696L9.1931 14.4925"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7875 13.1073C10.2626 13.7431 10.8687 14.2692 11.5647 14.6498C12.2608 15.0305 13.0305 15.2569 13.8216 15.3136C14.6127 15.3703 15.4068 15.256 16.1499 14.9785C16.893 14.7011 17.5678 14.2668 18.1286 13.7053L21.4473 10.3833C22.4549 9.339 23.0124 7.9404 22.9998 6.48867C22.9872 5.03694 22.4055 3.64825 21.3799 2.62169C20.3544 1.59512 18.9671 1.01282 17.5168 1.00021C16.0666 0.987594 14.6694 1.54567 13.6262 2.55424L11.7234 4.44782M14.2125 10.8927C13.7374 10.2569 13.1313 9.73083 12.4353 9.35017C11.7392 8.96951 10.9695 8.74314 10.1784 8.68643C9.38729 8.62971 8.59323 8.74397 7.8501 9.02146C7.10697 9.29895 6.43215 9.73317 5.87141 10.2947L2.55268 13.6167C1.54512 14.661 0.987607 16.0596 1.00021 17.5113C1.01281 18.9631 1.59452 20.3518 2.62006 21.3783C3.64559 22.4049 5.03289 22.9872 6.48316 22.9998C7.93343 23.0124 9.33063 22.4543 10.3738 21.4458L12.2655 19.5522"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5864 14.208C11.1046 14.9016 11.7659 15.4755 12.5252 15.8907C13.2845 16.306 14.1241 16.5529 14.9872 16.6148C15.8502 16.6767 16.7165 16.552 17.5272 16.2493C18.3378 15.9466 19.074 15.4729 19.6857 14.8603L23.3062 11.2363C24.4053 10.0971 25.0135 8.57134 24.9998 6.98764C24.986 5.40394 24.3514 3.889 23.2327 2.76911C22.1139 1.64922 20.6005 1.01399 19.0184 1.00023C17.4363 0.986466 15.912 1.59528 14.774 2.69553L12.6983 4.76126M15.4136 11.792C14.8954 11.0984 14.2341 10.5245 13.4748 10.1093C12.7155 9.69401 11.8759 9.44707 11.0128 9.38519C10.1498 9.32332 9.28353 9.44797 8.47284 9.75069C7.66215 10.0534 6.92599 10.5271 6.31427 11.1396L2.69383 14.7637C1.59468 15.9029 0.98648 17.4287 1.00023 19.0124C1.01398 20.5961 1.64857 22.111 2.76734 23.2309C3.8861 24.3508 5.39952 24.986 6.98163 24.9998C8.56374 25.0135 10.088 24.4047 11.226 23.3045L13.2896 21.2387"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.983 17.51C13.6308 18.377 14.4573 19.0943 15.4065 19.6134C16.3556 20.1325 17.4052 20.4412 18.484 20.5185C19.5628 20.5958 20.6456 20.44 21.6589 20.0616C22.6723 19.6833 23.5925 19.0911 24.3572 18.3254L28.8827 13.7953C30.2567 12.3714 31.0169 10.4642 30.9997 8.48455C30.9825 6.50492 30.1893 4.61125 28.7908 3.21139C27.3924 1.81153 25.5006 1.01749 23.523 1.00029C21.5453 0.983083 19.6401 1.7441 18.2175 3.11942L15.6229 5.70157M19.017 14.49C18.3692 13.623 17.5427 12.9057 16.5935 12.3866C15.6444 11.8675 14.5948 11.5588 13.516 11.4815C12.4372 11.4042 11.3544 11.56 10.341 11.9384C9.32769 12.3167 8.40748 12.9089 7.64284 13.6746L3.11729 18.2047C1.74335 19.6286 0.9831 21.5358 1.00029 23.5154C1.01747 25.4951 1.81072 27.3887 3.20917 28.7886C4.60762 30.1885 6.4994 30.9825 8.47704 30.9997C10.4547 31.0169 12.3599 30.2559 13.7825 28.8806L16.3621 26.2984"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};
