import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="4"
      viewBox="0 0 12 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.749789 0C0.335692 0 0 0.224237 0 0.500848C0 0.777458 0.335692 1.0017 0.749789 1.0017H11.2502C11.6643 1.0017 12 0.777458 12 0.500848C12 0.224237 11.6643 0 11.2502 0H0.749789Z"
        fill={color}
      />
      <path
        d="M0.749789 2.9983C0.335692 2.9983 0 3.22254 0 3.49915C0 3.77576 0.335692 4 0.749789 4H11.2502C11.6643 4 12 3.77576 12 3.49915C12 3.22254 11.6643 2.9983 11.2502 2.9983H0.749789Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="5"
      viewBox="0 0 14 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.874754 0C0.391641 0 0 0.280296 0 0.626059C0 0.971823 0.391641 1.25212 0.874754 1.25212H13.1252C13.6084 1.25212 14 0.971823 14 0.626059C14 0.280296 13.6084 0 13.1252 0H0.874754Z"
        fill={color}
      />
      <path
        d="M0.874754 3.74788C0.391641 3.74788 0 4.02818 0 4.37394C0 4.7197 0.391641 5 0.874754 5H13.1252C13.6084 5 14 4.7197 14 4.37394C14 4.02818 13.6084 3.74788 13.1252 3.74788H0.874754Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="6"
      viewBox="0 0 16 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.600266 0.5C0.268914 0.5 0.000299771 0.764132 0.000299771 1.08996C0.000299771 1.41578 0.268914 1.67991 0.600266 1.67991H15.4C15.7314 1.67991 16 1.41578 16 1.08996C16 0.764132 15.7314 0.5 15.4 0.5H0.600266Z"
        fill={color}
      />
      <path
        d="M0.599966 4.32009C0.268614 4.32009 0 4.58422 0 4.91004C0 5.23587 0.268614 5.5 0.599966 5.5H15.3997C15.7311 5.5 15.9997 5.23587 15.9997 4.91004C15.9997 4.58422 15.7311 4.32009 15.3997 4.32009H0.599966Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="7"
      viewBox="0 0 22 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.825366 0C0.369757 0 0.000412185 0.369785 0.000412185 0.825938C0.000412185 1.28209 0.369757 1.65188 0.825366 1.65188H21.175C21.6307 1.65188 22 1.28209 22 0.825938C22 0.369785 21.6307 0 21.175 0H0.825366Z"
        fill={color}
      />
      <path
        d="M0.824954 5.34812C0.369344 5.34812 0 5.71791 0 6.17406C0 6.63022 0.369344 7 0.824954 7H21.1746C21.6302 7 21.9996 6.63022 21.9996 6.17406C21.9996 5.71791 21.6302 5.34812 21.1746 5.34812H0.824954Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="8"
      viewBox="0 0 24 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.856991 0C0.383688 0 0 0.397969 0 0.888889C0 1.37981 0.383688 1.77778 0.856991 1.77778H23.143C23.6163 1.77778 24 1.37981 24 0.888889C24 0.397969 23.6163 0 23.143 0H0.856991Z"
        fill={color}
      />
      <path
        d="M0.856991 6.22222C0.383688 6.22222 0 6.62019 0 7.11111C0 7.60203 0.383688 8 0.856991 8H23.143C23.6163 8 24 7.60203 24 7.11111C24 6.62019 23.6163 6.22222 23.143 6.22222H0.856991Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="10"
      viewBox="0 0 30 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.07124 0C0.47961 0 0 0.497461 0 1.11111C0 1.72476 0.47961 2.22222 1.07124 2.22222H28.9288C29.5204 2.22222 30 1.72476 30 1.11111C30 0.497461 29.5204 0 28.9288 0H1.07124Z"
        fill={color}
      />
      <path
        d="M1.07124 7.77778C0.47961 7.77778 0 8.27524 0 8.88889C0 9.50254 0.47961 10 1.07124 10H28.9288C29.5204 10 30 9.50254 30 8.88889C30 8.27524 29.5204 7.77778 28.9288 7.77778H1.07124Z"
        fill={color}
      />
    </svg>
  )
};
