import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39322 3.45894C6.2876 2.20997 7.50257 0.526495 7.50257 0.526495C7.50257 0.526495 7.85996 0 8.32343 0C8.42787 0 8.53528 0.0348561 8.64567 0.104568C8.78861 0.222988 9.07142 0.526495 9.07142 0.526495L10.8889 2.34752L11.8184 3.28536C11.8184 3.28536 11.9581 3.42549 11.9893 3.58309C12.0205 3.74068 11.978 3.91698 11.9317 4.00302C11.8854 4.08905 11.7762 4.26515 11.6343 4.36804C11.4923 4.47094 10.249 5.30516 10.249 5.30516L8.50226 6.48812C8.50226 6.48812 8.66597 7.08067 8.6129 7.79536C8.58165 8.21623 8.48645 8.64088 8.32397 9.04468C8.31696 9.0621 2.87238 3.6366 2.87238 3.6366C3.47856 3.39167 4.3587 3.21478 5.39322 3.45894Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.299437 3.58367C0.453694 3.43075 0.703868 3.43075 0.858114 3.58369L8.48913 11.1497C8.64326 11.3026 8.64326 11.5503 8.48911 11.7031L8.30529 11.8853C8.15103 12.0382 7.90086 12.0382 7.74661 11.8853L0.115598 4.31923C-0.0385395 4.16641 -0.0385316 3.91872 0.115615 3.7659L0.299437 3.58367Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.97807 7.9744L1.09655 11.0375C1.09655 11.0375 1.02961 11.1108 0.881796 11.0781C0.801249 11.0496 0.765319 10.9661 0.765319 10.9001C0.765319 10.8089 0.83316 10.7425 0.860877 10.7053C0.928287 10.6149 1.5433 9.8427 1.5433 9.8427L3.86978 6.91366L4.97807 7.9744Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29209 4.03543C7.33553 2.57829 8.753 0.614244 8.753 0.614244C8.753 0.614244 9.16995 0 9.71067 0C9.83251 0 9.95783 0.0406655 10.0866 0.121996C10.2534 0.260152 10.5833 0.614244 10.5833 0.614244L12.7038 2.73878L13.7882 3.83292C13.7882 3.83292 13.9512 3.99641 13.9875 4.18027C14.0239 4.36413 13.9743 4.56981 13.9203 4.67018C13.8664 4.77056 13.7389 4.97601 13.5733 5.09605C13.4076 5.2161 11.9571 6.18935 11.9571 6.18935L9.9193 7.56948C9.9193 7.56948 10.1103 8.26079 10.0484 9.09458C10.0119 9.5856 9.90086 10.081 9.7113 10.5521C9.70312 10.5724 3.35111 4.2427 3.35111 4.2427C4.05831 3.95695 5.08515 3.75058 6.29209 4.03543Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.349344 4.18095C0.52931 4.00254 0.821179 4.00255 1.00113 4.18097L9.90398 13.008C10.0838 13.1863 10.0838 13.4753 9.90396 13.6536L9.6895 13.8662C9.50954 14.0446 9.21767 14.0446 9.03771 13.8662L0.134864 5.03911C-0.0449627 4.86081 -0.0449536 4.57184 0.134885 4.39356L0.349344 4.18095Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80774 9.30346L1.27931 12.8771C1.27931 12.8771 1.20121 12.9626 1.02876 12.9244C0.934791 12.8912 0.892873 12.7937 0.892873 12.7168C0.892873 12.6103 0.97202 12.5329 1.00436 12.4896C1.083 12.3841 1.80052 11.4832 1.80052 11.4832L4.51475 8.06593L5.80774 9.30346Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19097 4.61192C8.38347 2.94662 10.0034 0.701993 10.0034 0.701993C10.0034 0.701993 10.4799 0 11.0979 0C11.2372 0 11.3804 0.0464748 11.5276 0.139425C11.7181 0.297317 12.0952 0.701993 12.0952 0.701993L14.5186 3.13003L15.7579 4.38048C15.7579 4.38048 15.9442 4.56733 15.9858 4.77745C16.0273 4.98758 15.9706 5.22264 15.9089 5.33735C15.8473 5.45207 15.7017 5.68686 15.5123 5.82406C15.323 5.96126 13.6653 7.07354 13.6653 7.07354L11.3363 8.65083C11.3363 8.65083 11.5546 9.4409 11.4839 10.3938C11.4422 10.955 11.3153 11.5212 11.0986 12.0596C11.0893 12.0828 3.82984 4.8488 3.82984 4.8488C4.63807 4.52223 5.8116 4.28637 7.19097 4.61192Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.39925 4.77823C0.604925 4.57433 0.93849 4.57434 1.14415 4.77825L11.3188 14.8663C11.5244 15.0701 11.5243 15.4004 11.3188 15.6041L11.0737 15.8471C10.868 16.051 10.5345 16.051 10.3288 15.8471L0.15413 5.75898C-0.0513859 5.55521 -0.0513755 5.22496 0.154154 5.02121L0.39925 4.77823Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63742 10.6325L1.46207 14.7167C1.46207 14.7167 1.37281 14.8144 1.17573 14.7708C1.06833 14.7328 1.02043 14.6214 1.02043 14.5335C1.02043 14.4118 1.11088 14.3234 1.14784 14.2738C1.23772 14.1532 2.05773 13.1236 2.05773 13.1236L5.15971 9.21821L6.63742 10.6325Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88758 6.34139C11.5273 4.05161 13.7547 0.96524 13.7547 0.96524C13.7547 0.96524 14.4099 0 15.2596 0C15.4511 0 15.648 0.0639029 15.8504 0.191709C16.1124 0.408811 16.6309 0.96524 16.6309 0.96524L19.963 4.30379L21.6671 6.02316C21.6671 6.02316 21.9232 6.28007 21.9804 6.56899C22.0376 6.85792 21.9596 7.18113 21.8748 7.33886C21.79 7.4966 21.5898 7.81944 21.3295 8.00808C21.0691 8.19673 18.7898 9.72612 18.7898 9.72612L15.5875 11.8949C15.5875 11.8949 15.8876 12.9812 15.7903 14.2915C15.733 15.0631 15.5585 15.8416 15.2606 16.5819C15.2478 16.6138 5.26603 6.66711 5.26603 6.66711C6.37735 6.21807 7.99096 5.89376 9.88758 6.34139Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.548968 6.57006C0.831772 6.2897 1.29042 6.28972 1.57321 6.57009L15.5634 20.4412C15.846 20.7214 15.846 21.1755 15.5634 21.4556L15.2264 21.7897C14.9436 22.0701 14.4849 22.0701 14.2021 21.7897L0.211929 7.9186C-0.0706557 7.63842 -0.0706413 7.18432 0.211962 6.90416L0.548968 6.57006Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12646 14.6197L2.01035 20.2354C2.01035 20.2354 1.88762 20.3698 1.61663 20.3098C1.46896 20.2575 1.40309 20.1044 1.40309 19.9836C1.40309 19.8162 1.52746 19.6946 1.57828 19.6265C1.70186 19.4607 2.82938 18.045 2.82938 18.045L7.0946 12.675L9.12646 14.6197Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7864 6.91788C12.5752 4.41993 15.0051 1.05299 15.0051 1.05299C15.0051 1.05299 15.7199 0 16.6469 0C16.8557 0 17.0706 0.0697123 17.2913 0.209137C17.5772 0.445975 18.1428 1.05299 18.1428 1.05299L21.7779 4.69505L23.6369 6.57072C23.6369 6.57072 23.9163 6.85099 23.9786 7.16618C24.041 7.48137 23.9559 7.83396 23.8634 8.00603C23.7709 8.17811 23.5525 8.5303 23.2685 8.73609C22.9845 8.94188 20.498 10.6103 20.498 10.6103L17.0045 12.9762C17.0045 12.9762 17.3319 14.1614 17.2258 15.5907C17.1633 16.4325 16.9729 17.2818 16.6479 18.0894C16.6339 18.1242 5.74476 7.27321 5.74476 7.27321C6.95711 6.78335 8.71741 6.42956 10.7864 6.91788Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.598875 7.16734C0.907388 6.86149 1.40774 6.86151 1.71623 7.16738L16.9783 22.2995C17.2865 22.6051 17.2865 23.1005 16.9782 23.4062L16.6106 23.7706C16.3021 24.0765 15.8017 24.0765 15.4932 23.7706L0.231196 8.63847C-0.0770789 8.33282 -0.0770633 7.83744 0.231231 7.53181L0.598875 7.16734Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95613 15.9488L2.1931 22.075C2.1931 22.075 2.05922 22.2216 1.76359 22.1562C1.6025 22.0991 1.53064 21.9321 1.53064 21.8003C1.53064 21.6177 1.66632 21.485 1.72175 21.4107C1.85657 21.2298 3.0866 19.6854 3.0866 19.6854L7.73956 13.8273L9.95613 15.9488Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4831 8.64735C15.719 5.52492 18.7564 1.31624 18.7564 1.31624C18.7564 1.31624 19.6499 0 20.8086 0C21.0697 0 21.3382 0.0871403 21.6142 0.261421C21.9715 0.557469 22.6786 1.31624 22.6786 1.31624L27.2223 5.86881L29.5461 8.2134C29.5461 8.2134 29.8953 8.56374 29.9733 8.95772C30.0513 9.35171 29.9449 9.79244 29.8293 10.0075C29.7136 10.2226 29.4406 10.6629 29.0856 10.9201C28.7306 11.1774 25.6224 13.2629 25.6224 13.2629L21.2557 16.2203C21.2557 16.2203 21.6649 17.7017 21.5322 19.4884C21.4541 20.5406 21.2161 21.6022 20.8099 22.6117C20.7924 22.6552 7.18095 9.09151 7.18095 9.09151C8.69639 8.47918 10.8968 8.03695 13.4831 8.64735Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.748593 8.95918C1.13423 8.57687 1.75967 8.57689 2.14529 8.95922L21.2228 27.8744C21.6082 28.2564 21.6081 28.8757 21.2228 29.2577L20.7632 29.7133C20.3776 30.0956 19.7521 30.0956 19.3665 29.7132L0.288995 10.7981C-0.0963487 10.416 -0.0963291 9.7968 0.289039 9.41476L0.748593 8.95918Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4452 19.936L2.74138 27.5937C2.74138 27.5937 2.57402 27.777 2.20449 27.6952C2.00312 27.6239 1.9133 27.4152 1.9133 27.2504C1.9133 27.0221 2.0829 26.8563 2.15219 26.7633C2.32072 26.5373 3.85825 24.6068 3.85825 24.6068L9.67445 17.2841L12.4452 19.936Z"
        fill={color}
      />
    </svg>
  )
};
