import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12V2C3 1.44772 3.44772 1 4 1H6.17943C6.50153 1 6.80392 1.15515 6.99176 1.41681L10.5813 6.41681C10.8315 6.76535 10.8315 7.23465 10.5813 7.58319L6.99176 12.5832C6.80392 12.8448 6.50153 13 6.17943 13H4C3.44772 13 3 12.5523 3 12Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13.5V2.5C4 1.67157 4.67157 1 5.5 1H7.36814C7.86037 1 8.32128 1.2415 8.60145 1.64622L12.4089 7.14621C12.7645 7.65987 12.7645 8.34013 12.4089 8.85379L8.60145 14.3538C8.32128 14.7585 7.86037 15 7.36814 15H5.5C4.67157 15 4 14.3284 4 13.5Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 15.5V2.5C4 1.67157 4.67157 1 5.5 1H7.81713C8.31632 1 8.7828 1.24834 9.06153 1.66247L13.4363 8.16247C13.7771 8.66882 13.7771 9.33118 13.4363 9.83753L9.06153 16.3375C8.7828 16.7517 8.31632 17 7.81713 17H5.5C4.67157 17 4 16.3284 4 15.5Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 21V3C5 1.89543 5.89543 1 7 1H9.90244C10.5863 1 11.2227 1.34937 11.5898 1.92629L17.3168 10.9263C17.7336 11.5814 17.7336 12.4186 17.3168 13.0737L11.5898 22.0737C11.2227 22.6506 10.5863 23 9.90244 23H7C5.89543 23 5 22.1046 5 21Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 23V3C6 1.89543 6.89543 1 8 1H11.3564C12.0444 1 12.684 1.35359 13.05 1.93614L19.3317 11.9361C19.7403 12.5865 19.7403 13.4135 19.3317 14.0639L13.05 24.0639C12.684 24.6464 12.0444 25 11.3564 25H8C6.89543 25 6 24.1046 6 23Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 29V3C7 1.89543 7.89543 1 9 1H13.7241C14.4213 1 15.0682 1.36307 15.4313 1.9582L23.3643 14.9582C23.7546 15.5979 23.7546 16.4021 23.3643 17.0418L15.4313 30.0418C15.0682 30.6369 14.4213 31 13.7241 31H9C7.89543 31 7 30.1046 7 29Z"
        fill={color}
      />
    </svg>
  )
};
