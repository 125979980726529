import React from 'react';
import * as NiagaraIcons from './_NiagaraIcons';
import { IconProps, IIconOption, NiagaraIconName } from './_NiagaraIconProps';
import { IconSwitch } from './_IconSwitch';

interface INiagaraIcon extends IconProps {
  name: NiagaraIconName;
}

export function NiagaraIcon({ name, color, size }: INiagaraIcon) {
  const Icon: {
    [key: string]: IIconOption;
  } = NiagaraIcons[name] ?? NiagaraIcons.Placeholder;
  return <IconSwitch color={color} size={size} icon={Icon} />;
}
